import React from 'react';
import styled from 'styled-components';
import Label from '~/shared/components/Ui/Label';
import { getChainConfigExtension, useCurrentChainId } from '~/utils/chains';
import StorageNodeItem from './StorageNodeItem';
function UnstyledStorageNodeList({ className, disabled = false }) {
    const chainId = useCurrentChainId();
    const storageNodes = getChainConfigExtension(chainId).storageNodes;
    return (React.createElement("div", { className: className },
        React.createElement(Label, null, disabled ? 'Storage nodes' : 'Choose storage nodes (one or more)'),
        React.createElement("ul", { "data-test-hook": "Storage nodes" }, storageNodes.map(({ address, name, thirdPartyUrl }) => (React.createElement("li", { key: address },
            React.createElement(StorageNodeItem, { address: address.toLowerCase(), disabled: disabled, thirdPartyUrl: thirdPartyUrl }, name)))))));
}
const StorageNodes = styled(UnstyledStorageNodeList).withConfig({ displayName: "StorageNodes", componentId: "sc-1t7yonk" }) `
    margin-bottom: 40px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    ul > li + li {
        margin-top: 6px;
    }
`;
export default StorageNodes;
