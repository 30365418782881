import React from 'react';
import styled from 'styled-components';
import { Buttons } from '~/components/Buttons';
import PngIcon from '~/shared/components/PngIcon';
import { getChainConfig } from '~/utils/chains';
import { RejectionReason } from '~/utils/exceptions';
import { Footer } from './BaseModal';
import Modal from './Modal';
function getChainName(chainId) {
    try {
        return getChainConfig(chainId).name;
    }
    catch (_) {
        return `#${chainId}`;
    }
}
export default function SwitchNetworkModal({ expectedNetwork, actualNetwork, onReject, onResolve, ...props }) {
    return (React.createElement(Modal, { ...props, onReject: onReject, title: "Switch network", onBeforeAbort: (reason) => {
            /**
             * Clicking on the backdrop should not accidentally discard the modal
             * in this case. Enforce intentional cancellation via the Cancel button
             * or the Escape key.
             */
            return reason !== RejectionReason.Backdrop;
        } },
        React.createElement(Content, null,
            React.createElement(IconWrap, null,
                React.createElement(PngIcon, { name: "wallet", alt: "Switch network" })),
            React.createElement(P, null,
                "Please switch to the ",
                React.createElement("em", null, getChainName(expectedNetwork)),
                " network in your Ethereum wallet. It's currently in",
                ' ',
                React.createElement("em", null, getChainName(actualNetwork)),
                "\u00A0network.")),
        React.createElement(Footer, null,
            React.createElement(Buttons, { actions: {
                    cancel: {
                        title: 'Cancel',
                        onClick: () => void onReject?.(RejectionReason.CancelButton),
                        kind: 'link',
                    },
                    add: {
                        title: 'Switch',
                        kind: 'primary',
                        onClick: () => void onResolve?.(),
                    },
                } }))));
}
const P = styled.p.withConfig({ displayName: "P", componentId: "sc-1iyco9e" }) `
    margin: 0;
    text-align: center;
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-lsnwdx" }) `
    width: 98px;
    height: 67px;
    position: relative;
    margin: 0 auto 24px;

    img {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%) translateY(-6%);
    }
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1odxf0n" }) `
    padding: 64px 48px 40px;

    em {
        font-style: normal;
        font-weight: 500;
    }
`;
