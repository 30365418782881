module.exports    = {
	"development": {
		"availableChains": [
			"dev2",
			"polygon",
			"amoy"
		],
		"platformOriginUrl": "",
		"streamrUrl": ""
	},
	"amoy": {
		"availableChains": [
			"amoy",
			"polygon"
		],
		"platformOriginUrl": "https://staging.streamr.network",
		"streamrUrl": "https://staging.streamr.network"
	},
	"production": {
		"availableChains": [
			"polygon",
			"amoy"
		]
	}
}