import React, { forwardRef, } from 'react';
import { compose } from '~/shared/utils/compose';
import FlushHistoryDecorator from './FlushHistoryDecorator';
import OnCommitDecorator from './OnCommitDecorator';
import RevertOnEscapeDecorator from './RevertOnEscapeDecorator';
import SelectAllOnFocusDecorator from './SelectAllOnFocusDecorator';
import StyledInput from './StyledInput';
const Text = ({ tag: Tag = 'input', unstyled, ...props }, ref) => unstyled ? (React.createElement(Tag, { ...props, ref: ref })) : (React.createElement(StyledInput, { ...props, as: Tag, ref: ref }));
const EnhancedText = compose(FlushHistoryDecorator, OnCommitDecorator, SelectAllOnFocusDecorator, RevertOnEscapeDecorator)(forwardRef(Text));
EnhancedText.displayName = 'Text';
export default EnhancedText;
