import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { isAddress } from 'web3-validator';
import { Alert as PrestyledAlert } from '~/components/Alert';
import FormModal, { FieldWrap, Section, TextInput, } from '~/modals/FormModal';
import Label from '~/shared/components/Ui/Label';
export default function AddNodeAddressModal({ title = 'Add node address', onResolve, onSubmit, submitLabel = 'Add node address', warning, ...props }) {
    const [address, setAddress] = useState('');
    const isValidAddress = useMemo(() => (address.length > 0 ? isAddress(address) : true), [address]);
    return (React.createElement(FormModal, { ...props, title: title, submitLabel: submitLabel, canSubmit: isValidAddress && address.length > 0, onSubmit: () => {
            onSubmit(address);
            onResolve?.(address);
        } },
        React.createElement(Section, null,
            React.createElement(Label, null, "Address"),
            React.createElement(FieldWrap, { "$invalid": !isValidAddress },
                React.createElement(TextInput, { name: "amount", autoFocus: true, onChange: ({ target }) => void setAddress(target.value), placeholder: "0x795...f109A", value: address }))),
        warning != null && (React.createElement(Alert, { type: "error", title: "" }, warning))));
}
const Alert = styled(PrestyledAlert).withConfig({ displayName: "Alert", componentId: "sc-1xjsxnn" }) `
    margin-top: 10px;
`;
