import { getERC20TokenContract } from '~/getters';
import { getPublicProvider } from '~/utils/providers';
export async function getBalance({ chainId, tokenAddress, walletAddress, }) {
    const provider = await getPublicProvider(chainId);
    if (tokenAddress === 'native') {
        return provider.getBalance(walletAddress);
    }
    const contract = getERC20TokenContract({
        tokenAddress,
        provider,
    });
    return contract.balanceOf(walletAddress);
}
