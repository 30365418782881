import React, { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SponsorshipActionBar } from '~/components/ActionBars/SponsorshipActionBar';
import { BehindBlockErrorDisplay } from '~/components/BehindBlockErrorDisplay';
import { ChartPeriodTabs } from '~/components/ChartPeriodTabs';
import { Decimals, SponsorshipDecimals } from '~/components/Decimals';
import { NetworkHelmet } from '~/components/Helmet';
import Layout, { LayoutColumn } from '~/components/Layout';
import NetworkChartDisplay from '~/components/NetworkChartDisplay';
import NetworkPageSegment, { Pad, SegmentGrid, TitleBar, } from '~/components/NetworkPageSegment';
import Spinner from '~/components/Spinner';
import { OperatorIdCell } from '~/components/Table';
import { useInitialBehindIndexError, useLatestBehindBlockError, useRefetchQueryBehindIndexEffect, } from '~/hooks';
import { useSponsorshipByIdQuery, useSponsorshipDailyBucketsQuery, useSponsorshipTokenInfo, } from '~/hooks/sponsorships';
import { useSponsorshipFundingHistoryQuery } from '~/hooks/useSponsorshipFundingHistoryQuery';
import { SponsorshipSummary } from '~/pages/SponsorshipPage/SponsorshipSummary';
import LoadingIndicator from '~/shared/components/LoadingIndicator';
import { NoData } from '~/shared/components/NoData';
import { ScrollTable } from '~/shared/components/ScrollTable/ScrollTable';
import { NoDataWrap } from '~/shared/components/ScrollTable/ScrollTable.styles';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { NetworkChart } from '~/shared/components/TimeSeriesGraph';
import { formatLongDate, formatShortDate, } from '~/shared/components/TimeSeriesGraph/chartUtils';
import { COLORS, LAPTOP, TABLET } from '~/shared/utils/styled';
import { truncate } from '~/shared/utils/text';
import { ChartPeriod } from '~/types';
import { abbr } from '~/utils';
import { useCurrentChainFullName, useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
const EmptyArray = [];
export const SingleSponsorshipPage = () => {
    const sponsorshipId = useParams().id || '';
    const sponsorshipQuery = useSponsorshipByIdQuery(sponsorshipId);
    const initialBehindBlockError = useInitialBehindIndexError(sponsorshipQuery, [
        sponsorshipId,
    ]);
    useRefetchQueryBehindIndexEffect(sponsorshipQuery);
    const behindBlockError = useLatestBehindBlockError(sponsorshipQuery);
    const isFetching = sponsorshipQuery.isFetching || sponsorshipQuery.isLoading || !!behindBlockError;
    const sponsorship = sponsorshipQuery.data || null;
    const { symbol: tokenSymbol = 'DATA', decimals = 18n } = useSponsorshipTokenInfo() || {};
    const [selectedDataSource, setSelectedDataSource] = useState('amountStaked');
    const [selectedPeriod, setSelectedPeriod] = useState(ChartPeriod.ThreeMonths);
    const chartQuery = useSponsorshipDailyBucketsQuery({
        sponsorshipId,
        period: selectedPeriod,
        dataSource: selectedDataSource,
    });
    const { data: chartData = [] } = chartQuery;
    const tooltipPrefix = useMemo(() => {
        switch (selectedDataSource) {
            case 'amountStaked':
                return 'Amount Staked';
            case 'numberOfOperators':
                return 'Number of Operators';
            case 'apy':
                return 'APY';
            default:
                return '';
        }
    }, [selectedDataSource]);
    const formatTooltipValue = useCallback((value) => {
        switch (selectedDataSource) {
            case 'amountStaked':
                return `${abbr(value)} ${tokenSymbol}`;
            case 'numberOfOperators':
                return value.toString();
            case 'apy':
                return `${value.toFixed(2)}%`;
            default:
                return '';
        }
    }, [selectedDataSource, tokenSymbol]);
    const formatYAxisValue = useCallback((value) => {
        switch (selectedDataSource) {
            case 'amountStaked':
                return abbr(value);
            case 'numberOfOperators':
                return value.toString();
            case 'apy':
                return `${value.toFixed(2)}%`;
            default:
                return '';
        }
    }, [selectedDataSource]);
    const fundingEventsQuery = useSponsorshipFundingHistoryQuery(sponsorshipId);
    const { operatorCount = 0, minOperators } = sponsorship || {};
    const operational = minOperators == null || operatorCount >= minOperators;
    const fullChainName = useCurrentChainFullName();
    const placeholder = behindBlockError ? (React.createElement(BehindBlockErrorDisplay, { latest: behindBlockError, initial: initialBehindBlockError || undefined })) : !isFetching ? (React.createElement(NoData, { firstLine: `Sponsorship not found on the ${fullChainName} chain.` })) : null;
    const chainName = useCurrentChainSymbolicName();
    const rawFundingEvents = fundingEventsQuery.data?.pages || EmptyArray;
    const fundingEvents = useMemo(() => rawFundingEvents.map((page) => page.events).flat() || EmptyArray, [rawFundingEvents]);
    return (React.createElement(Layout, null,
        React.createElement(NetworkHelmet, { title: "Sponsorship" }),
        React.createElement(LoadingIndicator, { loading: isFetching }),
        !!sponsorship && (React.createElement(React.Fragment, null,
            React.createElement(SponsorshipActionBar, { sponsorship: sponsorship }),
            React.createElement(SponsorshipSummary, { sponsorship: sponsorship }))),
        React.createElement(LayoutColumn, null, sponsorship == null ? (placeholder) : (React.createElement(SegmentGrid, null,
            React.createElement(ChartGrid, null,
                React.createElement(NetworkPageSegment, { title: "Overview charts" },
                    React.createElement(Pad, null,
                        React.createElement(NetworkChartDisplay, { periodTabs: React.createElement(ChartPeriodTabs, { value: selectedPeriod, onChange: setSelectedPeriod }), sourceTabs: React.createElement(Tabs, { selection: selectedDataSource, onSelectionChange: (dataSource) => {
                                    if (dataSource !== 'amountStaked' &&
                                        dataSource !==
                                            'numberOfOperators' &&
                                        dataSource !== 'apy') {
                                        return;
                                    }
                                    setSelectedDataSource(dataSource);
                                } },
                                React.createElement(Tab, { id: "amountStaked" }, "Amount Staked"),
                                React.createElement(Tab, { id: "numberOfOperators" }, "Number of Operators"),
                                React.createElement(Tab, { id: "apy" }, "APY")) },
                            React.createElement(NetworkChart, { isLoading: chartQuery.isLoading ||
                                    chartQuery.isFetching, tooltipValuePrefix: tooltipPrefix, graphData: chartData, xAxisDisplayFormatter: formatShortDate, yAxisAxisDisplayFormatter: formatYAxisValue, tooltipLabelFormatter: formatLongDate, tooltipValueFormatter: formatTooltipValue })))),
                React.createElement(NetworkPageSegment, { title: React.createElement(TitleBar, { aux: operational ? undefined : (React.createElement(MinOperatorCountNotReached, null,
                            sponsorship.operatorCount,
                            "/",
                            sponsorship.minOperators,
                            React.createElement(OperatorSpinner, { color: "green", strokeWidth: 3, size: 20, fixed: true, coverage: Math.max(0.01, sponsorship.operatorCount /
                                    sponsorship.minOperators) }))), label: operational
                            ? sponsorship.operatorCount
                            : undefined }, "Operators"), foot: true },
                    React.createElement(OperatorListWrap, null,
                        React.createElement(OperatorList, null,
                            React.createElement(OperatorListHeader, null,
                                React.createElement("div", null,
                                    React.createElement("strong", null, "Operator")),
                                React.createElement("div", null,
                                    React.createElement("strong", null, "Staked"))),
                            sponsorship.stakes.map((stake) => (React.createElement(OperatorListItem, { key: stake.operatorId },
                                React.createElement(Link, { to: R.operator(stake.operatorId, routeOptions(chainName)) },
                                    React.createElement("div", null,
                                        React.createElement(OperatorIdCell, { truncate: true, operatorId: stake.operatorId, imageUrl: stake.metadata.imageUrl, operatorName: stake.metadata.name })),
                                    React.createElement("strong", null,
                                        React.createElement(Decimals, { abbr: true, amount: stake.amountWei, decimals: decimals })))))),
                            !sponsorship.stakes.length && (React.createElement("li", null,
                                React.createElement(NoDataWrap, null,
                                    React.createElement(NoData, { firstLine: "No operators", compact: true })))))))),
            React.createElement(NetworkPageSegment, { foot: true, title: "Funding history" },
                React.createElement(ScrollTable, { hasMoreResults: fundingEventsQuery.hasNextPage, onLoadMore: () => fundingEventsQuery.fetchNextPage(), elements: fundingEvents, isLoading: fundingEventsQuery.isLoading ||
                        fundingEventsQuery.isFetching, columns: [
                        {
                            displayName: 'Date',
                            valueMapper: (element) => element.date,
                            align: 'start',
                            isSticky: true,
                            key: 'date',
                        },
                        {
                            displayName: 'Amount',
                            valueMapper: (element) => (React.createElement(SponsorshipDecimals, { abbr: true, amount: element.amount })),
                            align: 'start',
                            isSticky: false,
                            key: 'amount',
                        },
                        {
                            displayName: 'Sponsor',
                            valueMapper: (element) => truncate(element.sponsor),
                            align: 'start',
                            isSticky: false,
                            key: 'sponsor',
                        },
                    ] })))))));
};
const ChartGrid = styled(SegmentGrid).withConfig({ displayName: "ChartGrid", componentId: "sc-11f4yk8" }) `
    grid-template-columns: minmax(0, 1fr);

    @media ${LAPTOP} {
        grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    }
`;
const OperatorList = styled.ul.withConfig({ displayName: "OperatorList", componentId: "sc-1mr4tie" }) `
    font-size: 14px;
    list-style: none;
    line-height: 1.5em;
    margin: 0;
    padding: 0;

    li {
        background: #ffffff;
    }

    li + li {
        border-top: 1px solid ${COLORS.Border};
    }
`;
const OperatorListHeader = styled.li.withConfig({ displayName: "OperatorListHeader", componentId: "sc-o9bkuz" }) `
    background: rgba(255, 255, 255, 0.9) !important;
    backdrop-filter: blur(8px);
    box-shadow: 0 1px 0 ${COLORS.Border};
    display: flex;
    padding: 16px 24px;
    position: sticky;
    top: 0;
    z-index: 1;

    > div:first-child {
        flex-grow: 1;
    }

    @media ${TABLET} {
        padding: 24px 40px;
    }
`;
const OperatorListItem = styled.li.withConfig({ displayName: "OperatorListItem", componentId: "sc-15i7gtu" }) `
    > a {
        align-items: center;
        color: inherit !important;
        display: flex;
        padding: 16px 24px;
    }

    > a:hover {
        background-color: ${COLORS.secondaryLight};
    }

    > a > div:first-child {
        flex-grow: 1;
        margin-right: 12px;
        min-width: 0;
    }

    @media ${TABLET} {
        > a {
            padding: 24px 40px;
        }
    }
`;
const OperatorListWrap = styled.div.withConfig({ displayName: "OperatorListWrap", componentId: "sc-qge36z" }) `
    max-height: 538px;
    overflow: auto;
`;
const MinOperatorCountNotReached = styled.div.withConfig({ displayName: "MinOperatorCountNotReached", componentId: "sc-j5mbb5" }) `
    align-items: center;
    display: flex;
    color: #525252;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.14px;
    justify-self: right;
`;
const OperatorSpinner = styled(Spinner).withConfig({ displayName: "OperatorSpinner", componentId: "sc-2kksne" }) `
    margin-left: 8px;
`;
