import { StreamPermission } from '@streamr/sdk';
import { useClient } from 'streamr-client-react';
import { useEffect } from 'react';
import { produce } from 'immer';
import { create } from 'zustand';
import StreamNotFoundError from '~/shared/errors/StreamNotFoundError';
import { isMessagedObject } from '~/utils/exceptions';
import { address0 } from '~/consts';
import { useWalletAccount } from './wallet';
function accountKey(streamId, account) {
    return JSON.stringify([streamId, account.toLowerCase()]);
}
function permissionKey(streamId, account, permission) {
    return JSON.stringify([streamId, account.toLowerCase(), permission]);
}
const useStreamAbilitiesStore = create((set, get) => {
    function toggleFetching(streamId, account, permission, newValue) {
        set((store) => produce(store, (draft) => {
            if (!newValue) {
                return void delete draft.fetching[permissionKey(streamId, account, permission)];
            }
            draft.fetching[permissionKey(streamId, account, permission)] = true;
        }));
    }
    return {
        permissions: {},
        fetching: {},
        async fetchPermission(streamId, account, permission, streamrClient) {
            const pkey = permissionKey(streamId, account, permission);
            if (get().fetching[pkey]) {
                // Already fetching, skip.
                return;
            }
            let result = false;
            try {
                toggleFetching(streamId, account, permission, true);
                let stream;
                try {
                    stream = await streamrClient.getStream(streamId);
                }
                catch (e) {
                    if (isMessagedObject(e) && /not_found/i.test(e.message)) {
                        throw new StreamNotFoundError(streamId);
                    }
                    throw e;
                }
                result = await stream.hasPermission(account === address0
                    ? {
                        permission,
                        public: true,
                    }
                    : {
                        user: account,
                        permission,
                        allowPublic: true,
                    });
            }
            finally {
                set((state) => produce(state, (draft) => {
                    const key = accountKey(streamId, account);
                    const group = draft.permissions[key] || {};
                    const { cache } = group[permission] || {};
                    group[permission] = {
                        cache,
                        value: result,
                    };
                    draft.permissions[key] = group;
                }));
                toggleFetching(streamId, account, permission, false);
            }
        },
        invalidate(streamId, account) {
            set((store) => produce(store, (draft) => {
                const permissions = draft.permissions[accountKey(streamId, account)] || {};
                const permissionKeys = Object.keys(permissions);
                permissionKeys.forEach((permission) => {
                    const { value, cache = 0 } = permissions[permission] || {};
                    permissions[permission] = {
                        value,
                        cache: cache + 1,
                    };
                });
            }));
        },
    };
});
export function useStreamAbility(streamId, account, permission) {
    const client = useClient();
    const { fetchPermission } = useStreamAbilitiesStore();
    const { value, cache = 0 } = useStreamAbilitiesStore(({ permissions }) => streamId
        ? permissions[accountKey(streamId, account || address0)]?.[permission]
        : undefined) || {};
    useEffect(() => {
        async function fetch() {
            if (!client || !streamId) {
                return;
            }
            try {
                await fetchPermission(streamId, account || address0, permission, client);
            }
            catch (e) {
                console.warn(e);
            }
        }
        fetch();
    }, [cache, fetchPermission, streamId, account, permission, client]);
    return value;
}
export function useCurrentStreamAbility(streamId, permission) {
    const account = useWalletAccount();
    const hasPermission = useStreamAbility(streamId, account, permission);
    if (!streamId) {
        return (permission === StreamPermission.EDIT || permission === StreamPermission.GRANT);
    }
    return hasPermission;
}
export function useInvalidateStreamAbilities() {
    return useStreamAbilitiesStore(({ invalidate }) => invalidate);
}
