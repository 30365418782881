import { createBrowserHistory } from 'history';
import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';
export const history = createBrowserHistory({ window });
/**
 * Custom browser Router that allows us to access `history`.
 */
export function HubRouter({ history: historyProp = history, ...props }) {
    const [state, setState] = useState({
        action: historyProp.action,
        location: historyProp.location,
    });
    useLayoutEffect(() => {
        return historyProp.listen(setState);
    }, [historyProp]);
    return (React.createElement(Router, { ...props, location: state.location, navigationType: state.action, navigator: historyProp }));
}
export const MaxSearchPhraseLength = 250;
export const address0 = '0x0000000000000000000000000000000000000000';
export const StreamGptApiUrl = process.env.STREAM_GPT_API_URL;
/**
 * A minute in milliseconds.
 */
export const Minute = 60000;
export const DayInSeconds = 86400;
