import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { ERROR } from '~/shared/components/Ui/StateColors';
export const MarketplaceTheme = {
    color: '#ff5c00',
};
const DefaultTheme = {
    color: ERROR,
};
const UnstyledErrors = ({ children, theme: _theme, overlap: _overlap, ...props }) => React.createElement("div", { ...props }, children || React.createElement(Fragment, null, "\u200C"));
const Errors = styled(UnstyledErrors).withConfig({ displayName: "Errors", componentId: "sc-fklijh" }) `
    color: ${({ theme }) => theme.color};
    font-size: 12px;
    line-height: 1.5em;
    margin-top: 6px;
    word-break: break-word;

    ${({ overlap }) => !!overlap &&
    css `
            position: absolute;
        `}
`;
Errors.defaultProps = {
    theme: DefaultTheme,
};
export default Errors;
