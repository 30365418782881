import BN from 'bignumber.js';
export { BN };
export function toBN(value) {
    return typeof value === 'bigint' ? toBN(value.toString()) : new BN(value);
}
export function toBigInt(value, decimals = 0n) {
    try {
        return BigInt(toBN(value)
            .multipliedBy(toBN(10n ** decimals))
            .toFixed(0));
    }
    catch (e) {
        console.warn('Failed to convert BigNumberish value to BigInt. Using 0n.', value);
    }
    return 0n;
}
export function toFloat(value, decimals) {
    return toBN(value).dividedBy(toBN(10n ** decimals));
}
