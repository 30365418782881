import React from 'react';
import styled from 'styled-components';
import { NetworkActionBarBackButtonIcon, NetworkActionBarBackLink, NetworkActionBarStatsTitle, NetworkActionBarTitle, } from '~/components/ActionBars/NetworkActionBar.styles';
import { Separator } from '~/components/Separator';
import { COLORS, LAPTOP, MAX_BODY_WIDTH, TABLET } from '~/shared/utils/styled';
import { goBack } from '~/utils';
export function AbstractActionBar({ fallbackBackButtonUrl, title, buttons = React.createElement(React.Fragment, null), ctas = React.createElement(React.Fragment, null), summaryTitle, summary, }) {
    return (React.createElement(Root, null,
        React.createElement(OuterWrap, null,
            React.createElement(InnerWrap, null,
                React.createElement(NetworkActionBarBackLink, { to: fallbackBackButtonUrl, onClick: (e) => {
                        goBack({
                            onBeforeNavigate() {
                                e.preventDefault();
                            },
                        });
                    } },
                    React.createElement(NetworkActionBarBackButtonIcon, { name: "backArrow" })),
                React.createElement(NetworkActionBarTitle, null, title)),
            React.createElement(ActionsOuterWrap, null,
                React.createElement(Buttons, null, buttons),
                React.createElement(Ctas, null, ctas)),
            summary && (React.createElement(React.Fragment, null,
                summaryTitle && (React.createElement(NetworkActionBarStatsTitle, null, summaryTitle)),
                React.createElement(Separator, null),
                summary)))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1ujadf1" }) `
    background: #fff;
    color: ${COLORS.primary};
    padding-top: 34px;

    @media ${TABLET} {
        padding-top: 60px;
    }

    @media ${LAPTOP} {
        padding-top: 108px;
    }
`;
const OuterWrap = styled.div.withConfig({ displayName: "OuterWrap", componentId: "sc-cp6gzo" }) `
    margin: 0 auto;
    max-width: ${MAX_BODY_WIDTH}px;
    padding: 0 24px 28px;
    width: 100%;

    @media (min-width: ${MAX_BODY_WIDTH + 48}px) {
        padding: 0;
    }
`;
const InnerWrap = styled.div.withConfig({ displayName: "InnerWrap", componentId: "sc-1j7p67u" }) `
    align-items: center;
    display: flex;
`;
const ActionsOuterWrap = styled(InnerWrap).withConfig({ displayName: "ActionsOuterWrap", componentId: "sc-1qlonh2" }) `
    gap: 40px;
    flex-wrap: wrap;
    margin-top: 10px;

    @media ${LAPTOP} {
        padding-left: 40px;
    }
`;
const Buttons = styled(InnerWrap).withConfig({ displayName: "Buttons", componentId: "sc-5lwlsm" }) `
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 8px;
`;
const Ctas = styled.div.withConfig({ displayName: "Ctas", componentId: "sc-1sneazf" }) `
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: 100%;
    max-width: 480px;

    button {
        width: 100%;
    }

    @media ${TABLET} {
        max-width: none;
        width: auto;
    }
`;
