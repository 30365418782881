export var ChartPeriod;
(function (ChartPeriod) {
    ChartPeriod["SevenDays"] = "7d";
    ChartPeriod["OneMonth"] = "1m";
    ChartPeriod["ThreeMonths"] = "3m";
    ChartPeriod["OneYear"] = "1y";
    ChartPeriod["YearToDate"] = "ytd";
    ChartPeriod["All"] = "all";
})(ChartPeriod || (ChartPeriod = {}));
export function isChartPeriod(value) {
    return Object.values(ChartPeriod).includes(value);
}
