import React from 'react';
import Markdown from 'react-markdown';
import styled from 'styled-components';
import { PropertyDisplay, PropertyDropdownList, PropertyIcon, } from '~/components/PropertyDropdown';
import { ProjectHeroContainer, ProjectHeroImage, ProjectHeroMetadataContainer, ProjectHeroTitle, } from '~/marketplace/containers/ProjectPage/Hero/ProjectHero2.styles';
export default function ProjectHero2({ name, description: descriptionProp, imageUrl, contact, }) {
    const description = descriptionProp
        // Remove empty paragraphs
        .replace(/\\n/, '')
        // Remove spacers (extra empty lines)
        .replace(/^\s*\\\s*$/gm, '');
    return (React.createElement(ProjectHeroContainer, null,
        React.createElement(ProjectHeroImage, { src: imageUrl, alt: name, noBorderRadius: true, key: imageUrl || '' }),
        React.createElement(ProjectHeroTitle, null, name),
        React.createElement(MarkdownWrap, null,
            React.createElement(Markdown, { components: {
                    li({ children }) {
                        return (React.createElement("li", null,
                            React.createElement("p", null, children)));
                    },
                } }, description)),
        React.createElement(ProjectHeroMetadataContainer, null,
            React.createElement(PropertyDropdownList, null,
                contact.url && (React.createElement(PropertyDisplay, { icon: React.createElement(PropertyIcon, { name: "web" }), displayValue: contact.url
                        .replace(/^https?:\/\//, '')
                        .replace(/\/+$/, ''), href: contact.url })),
                contact.email && (React.createElement(PropertyDisplay, { icon: React.createElement(PropertyIcon, { name: "email" }), displayValue: contact.email, href: `mailto:${contact.email}` })),
                contact.twitter && (React.createElement(PropertyDisplay, { icon: React.createElement(PropertyIcon, { name: "twitter", "$color": "#1da1f2" }), href: contact.twitter })),
                contact.telegram && (React.createElement(PropertyDisplay, { icon: React.createElement(PropertyIcon, { name: "telegram", "$color": "#2aabee" }), href: contact.telegram })),
                contact.reddit && (React.createElement(PropertyDisplay, { icon: React.createElement(PropertyIcon, { name: "reddit", "$color": "#ff5700" }), href: contact.reddit })),
                contact.linkedIn && (React.createElement(PropertyDisplay, { icon: React.createElement(PropertyIcon, { name: "linkedin", "$color": "#0077b5" }), href: contact.linkedIn }))))));
}
const MarkdownWrap = styled.div.withConfig({ displayName: "MarkdownWrap", componentId: "sc-1ela70o" }) `
    line-height: 1.75;
    padding-right: 50px;

    > * + * {
        margin-top: 1em;
    }

    ol,
    ul {
        padding-left: 1.1em;
    }

    code {
        border-radius: 4px;
        border: 1px solid rgb(232, 235, 237);
        background: rgb(244, 247, 250);
        padding: 3px 4px;
        font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier,
            monospace;
        font-size: 80%;
    }

    blockquote {
        border-left: 3px solid rgb(232, 235, 237);
        padding: 0.5em;
        padding-left: 1em;
        font-style: italic;
    }

    blockquote > * + * {
        margin-top: 1em;
    }
`;
