import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';
import { COLORS } from '~/shared/utils/styled';
import { useBoundingClientRect } from './Anchor';
export function SimpleDropdown({ children, detached = false, disabled = false, menu, menuWrapComponent: MenuWrap = SimpleDropdownMenu, onToggle, align = 'left', ...props }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = useCallback((value) => {
        if (disabled) {
            return;
        }
        setIsOpen(value);
    }, [disabled]);
    const rootRef = useRef(null);
    const menuRef = useRef(null);
    useEffect(() => {
        function onMouseDown({ target }) {
            if (!(target instanceof HTMLElement)) {
                return;
            }
            if (rootRef.current?.contains(target) || menuRef.current?.contains(target)) {
                return;
            }
            setIsOpen(false);
        }
        document.addEventListener('mousedown', onMouseDown);
        return () => {
            document.removeEventListener('mousedown', onMouseDown);
        };
    }, []);
    const onToggleRef = useRef(onToggle);
    if (onToggleRef.current !== onToggle) {
        onToggleRef.current = onToggle;
    }
    useEffect(() => {
        onToggleRef.current?.(isOpen);
    }, [isOpen]);
    useEffect(() => {
        if (!isOpen) {
            return;
        }
        function onKeyDown(e) {
            if (e.key === 'Escape') {
                e.stopPropagation();
                e.preventDefault();
                setIsOpen(false);
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [isOpen]);
    useEffect(() => {
        if (disabled) {
            setIsOpen(false);
        }
    }, [disabled]);
    useEffect(() => {
        if (!isOpen) {
            return;
        }
        function onFocus(e) {
            if (!rootRef.current?.contains(e.target) &&
                !menuRef.current?.contains(e.target)) {
                setIsOpen(false);
            }
        }
        document.body.addEventListener('focus', onFocus, true);
        return () => {
            document.body.removeEventListener('focus', onFocus, true);
        };
    }, [isOpen]);
    const posRef = useRef(null);
    const [x, y] = useBoundingClientRect(posRef, (r) => [
        r?.x || 0,
        (r?.y || 0) + window.scrollY,
    ]);
    const [dx, maxWidth] = useBoundingClientRect(menuRef, (rect) => {
        const { clientWidth } = document.documentElement;
        const maxWidth = clientWidth - 8;
        if (!rect) {
            return [0, maxWidth];
        }
        if (align === 'left') {
            return [Math.min(0, clientWidth - x - Math.round(rect.width) - 4), maxWidth];
        }
        const myWidth = rootRef.current?.clientWidth ?? 0;
        return [-Math.round(rect.width) + myWidth - 4, maxWidth];
    });
    return (React.createElement(SimpleDropdownRoot, { ref: rootRef, "$disabled": disabled, ...props },
        typeof children === 'function' ? children(toggle, isOpen) : children,
        React.createElement("div", { ref: posRef }),
        detached ? (createPortal(React.createElement(MenuWrap, { "$visible": isOpen, ref: menuRef, style: {
                transform: `translateX(${dx}px)`,
                maxWidth,
                top: `${y}px`,
                left: `${x}px`,
            } }, typeof menu === 'function' ? menu(setIsOpen, isOpen) : menu), document.getElementById('hub-anchors'))) : (React.createElement(MenuWrap, { "$visible": isOpen, ref: menuRef, style: {
                transform: `translateX(${dx}px)`,
                maxWidth,
            } }, typeof menu === 'function' ? menu(setIsOpen, isOpen) : menu))));
}
const SimpleDropdownRoot = styled.div.withConfig({ displayName: "SimpleDropdownRoot", componentId: "sc-1cqb11z" }) `
    position: relative;

    ${({ $disabled = false }) => $disabled &&
    css `
            opacity: 0.5;
        `}
`;
export const SimpleDropdownMenu = styled.div.withConfig({ displayName: "SimpleDropdownMenu", componentId: "sc-1xqpmwb" }) `
    min-height: 16px;
    min-width: 16px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: 250ms;
    transition-delay: 250ms, 0s;
    transition-property: visibility, opacity;
    visibility: hidden;
    z-index: 1000;

    ${({ $visible = false }) => $visible &&
    css `
            opacity: 1;
            pointer-events: auto;
            visibility: visible;
            transition-delay: 0s;
        `}
`;
export const SimpleListDropdownMenu = styled.div.withConfig({ displayName: "SimpleListDropdownMenu", componentId: "sc-kh317p" }) `
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.05),
        0 5px 15px rgba(0, 0, 0, 0.1);
    color: ${COLORS.primary};
    margin-top: 8px;
    min-height: 16px;
    padding: 8px 0;

    p {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
    }

    p + p {
        margin-top: 8px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;
export const DefaultSimpleDropdownMenu = styled(SimpleListDropdownMenu).withConfig({ displayName: "DefaultSimpleDropdownMenu", componentId: "sc-1wi3ul" }) `
    padding: 20px 16px;
    max-width: 100%;
    width: 460px;
`;
