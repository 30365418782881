import { Contract, parseEther } from 'ethers';
import { postImage } from '~/services/images';
import { getSigner } from '~/shared/stores/wallet';
import { toBN } from '~/utils/bn';
import { getContractAbi, getContractAddress } from '~/utils/contracts';
import networkPreflight from '~/utils/networkPreflight';
import { getPublicProvider } from '~/utils/providers';
import { toastedOperation, toastedOperations } from '~/utils/toastedOperation';
import { call } from '~/utils/tx';
export async function createOperator(chainId, params, { onReceipt } = {}) {
    const { cut, name, redundancyFactor, description, imageToUpload, url, email, twitter, x, telegram, reddit, linkedIn, } = params;
    await networkPreflight(chainId);
    const signer = await getSigner();
    const walletAddress = await signer.getAddress();
    const imageIpfsCid = imageToUpload
        ? await postImage(chainId, imageToUpload)
        : undefined;
    const factory = new Contract(getContractAddress('operatorFactory', chainId), getContractAbi('operatorFactory'), signer);
    const metadata = {
        name,
        description,
        redundancyFactor,
        imageIpfsCid,
        url: url || undefined,
        email: email || undefined,
        twitter: twitter || undefined,
        x: x || undefined,
        telegram: telegram || undefined,
        reddit: reddit || undefined,
        linkedIn: linkedIn || undefined,
    };
    const poolTokenName = `StreamrOperator-${walletAddress.slice(-5)}`;
    const operatorMetadata = JSON.stringify(metadata);
    const policies = [
        getContractAddress('operatorDefaultDelegationPolicy', chainId),
        getContractAddress('operatorDefaultExchangeRatePolicy', chainId),
        getContractAddress('operatorDefaultUndelegationPolicy', chainId),
    ];
    const operatorsCutFraction = parseEther(cut.toString()) / 100n;
    const policyParams = [0, 0, 0];
    await toastedOperation('Operator deployment', async () => {
        await call(factory, 'deployOperator', {
            args: [
                operatorsCutFraction,
                poolTokenName,
                operatorMetadata,
                policies,
                policyParams,
            ],
            onReceipt,
        });
    });
}
export async function updateOperator(chainId, operator, mods, { onReceipt } = {}) {
    const { name, redundancyFactor, description = '', imageToUpload, cut, url, email, twitter, x, telegram, reddit, linkedIn, } = mods;
    const { metadata } = operator;
    const operations = [];
    const hasUpdateCutOperation = !operator.stakes.length && operator.operatorsCut !== cut;
    const hasUpdateMetadataOperation = !!imageToUpload ||
        name !== metadata.name ||
        description !== metadata.description ||
        redundancyFactor !== metadata.redundancyFactor ||
        url !== metadata.url ||
        email !== metadata.email ||
        twitter !== metadata.twitter ||
        x !== metadata.x ||
        telegram !== metadata.telegram ||
        reddit !== metadata.reddit ||
        linkedIn !== metadata.linkedIn;
    if (hasUpdateCutOperation) {
        operations.push({
            id: 'updateCutOperation',
            label: "Update the owner's cut value",
        });
    }
    if (hasUpdateMetadataOperation) {
        operations.push({
            id: 'updateMetadataOperation',
            label: "Update the operator's metadata",
        });
    }
    if (!operations.length) {
        return;
    }
    const blockNumbers = [];
    await toastedOperations(operations, async (next) => {
        if (hasUpdateCutOperation) {
            await networkPreflight(chainId);
            const operatorContract = new Contract(operator.id, getContractAbi('operator'), await getSigner());
            await call(operatorContract, 'updateOperatorsCutFraction', {
                args: [parseEther(toBN(cut).toString()) / 100n],
                onReceipt: async (receipt) => {
                    blockNumbers.push(receipt.blockNumber);
                    if (!hasUpdateMetadataOperation) {
                        await onReceipt?.(receipt);
                    }
                },
            });
            next();
        }
        if (hasUpdateMetadataOperation) {
            await networkPreflight(chainId);
            const operatorContract = new Contract(operator.id, getContractAbi('operator'), await getSigner());
            const imageIpfsCid = imageToUpload
                ? await postImage(chainId, imageToUpload)
                : operator.metadata.imageIpfsCid;
            await call(operatorContract, 'updateMetadata', {
                args: [
                    JSON.stringify({
                        name: name || undefined,
                        description: description || undefined,
                        redundancyFactor,
                        imageIpfsCid,
                        url: url || undefined,
                        email: email || undefined,
                        twitter: twitter || undefined,
                        x: x || undefined,
                        telegram: telegram || undefined,
                        reddit: reddit || undefined,
                        linkedIn: linkedIn || undefined,
                    }),
                ],
                onReceipt: async (receipt) => {
                    blockNumbers.push(receipt.blockNumber);
                    await onReceipt?.(receipt);
                },
            });
        }
    });
}
export async function delegateToOperator(chainId, operatorId, amount, { onReceipt } = {}) {
    await networkPreflight(chainId);
    const signer = await getSigner();
    const contract = new Contract(getContractAddress('data', chainId), getContractAbi('erc677'), signer);
    await toastedOperation('Delegate to operator', async () => {
        await call(contract, 'transferAndCall', {
            args: [operatorId, amount, '0x'],
            onReceipt,
        });
    });
}
export async function undelegateFromOperator(chainId, operatorId, amount, { onReceipt } = {}) {
    await networkPreflight(chainId);
    const signer = await getSigner();
    const operatorContract = new Contract(operatorId, getContractAbi('operator'), signer);
    await toastedOperation('Undelegate from operator', async () => {
        await call(operatorContract, 'undelegate', {
            args: [amount],
            onReceipt,
        });
    });
}
export async function getOperatorDelegationAmount(chainId, operatorId, address) {
    const provider = await getPublicProvider(chainId);
    const operatorContract = new Contract(operatorId, getContractAbi('operator'), provider);
    return operatorContract.balanceInData(address);
}
export async function setOperatorNodeAddresses(chainId, operatorId, addresses, { onReceipt } = {}) {
    await networkPreflight(chainId);
    const signer = await getSigner();
    const operatorContract = new Contract(operatorId, getContractAbi('operator'), signer);
    await toastedOperation('Save node addresses', async () => {
        await call(operatorContract, 'setNodeAddresses', {
            args: [addresses],
            onReceipt,
        });
    });
}
export async function addOperatorControllerAddress(chainId, operatorId, address, { onReceipt } = {}) {
    await networkPreflight(chainId);
    const signer = await getSigner();
    const operatorContract = new Contract(operatorId, getContractAbi('operator'), signer);
    await toastedOperation('Authorise staking agent', async () => {
        await call(operatorContract, 'grantRole', {
            args: [await operatorContract.CONTROLLER_ROLE(), address],
            onReceipt,
        });
    });
}
export async function removeOperatorControllerAddress(chainId, operatorId, address, { onReceipt } = {}) {
    await networkPreflight(chainId);
    const signer = await getSigner();
    const operatorContract = new Contract(operatorId, getContractAbi('operator'), signer);
    await toastedOperation('Revoke staking agent', async () => {
        await call(operatorContract, 'revokeRole', {
            args: [await operatorContract.CONTROLLER_ROLE(), address],
            onReceipt,
        });
    });
}
export async function processOperatorUndelegationQueue(chainId, operatorId, { onReceipt } = {}) {
    await networkPreflight(chainId);
    const signer = await getSigner();
    const operatorContract = new Contract(operatorId, getContractAbi('operator'), signer);
    await toastedOperation('Process undelegation queue', async () => {
        await call(operatorContract, 'payOutQueue', {
            args: [0],
            onReceipt,
        });
    });
}
