import { getStreamrClientInstance } from '~/getters/getStreamrClient';
const providers = {};
export async function getPublicProvider(chainId) {
    const provider = providers[chainId];
    if (provider) {
        return provider;
    }
    const client = await getStreamrClientInstance(chainId);
    const { provider: newProvider } = await client.getSigner();
    providers[chainId] = newProvider;
    return newProvider;
}
