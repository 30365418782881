import styled from 'styled-components';
export const IconButton = styled.button.withConfig({ displayName: "IconButton", componentId: "sc-1h5n5qd" }) `
    appearance: none;
    background: none;
    border: 0;
    display: block;
    padding: 0;

    :active {
        transform: translateY(1px);
    }
`;
