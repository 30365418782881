import { useEffect } from 'react';
import { produce } from 'immer';
import { create } from 'zustand';
import { getProjectPermissions } from '~/getters';
import { ProjectDraft } from '~/stores/projectDraft';
import { address0 } from '~/consts';
import { useCurrentChainId } from '~/utils/chains';
import { useWalletAccount } from './wallet';
export var ProjectPermission;
(function (ProjectPermission) {
    ProjectPermission[ProjectPermission["Buy"] = 0] = "Buy";
    ProjectPermission[ProjectPermission["Delete"] = 1] = "Delete";
    ProjectPermission[ProjectPermission["Edit"] = 2] = "Edit";
    ProjectPermission[ProjectPermission["Grant"] = 3] = "Grant";
})(ProjectPermission || (ProjectPermission = {}));
function key(chainId, projectId, account) {
    return JSON.stringify([chainId, projectId, account.toLowerCase()]);
}
const useProjectAbilitiesStore = create((set) => {
    const fetching = {};
    return {
        permissions: {},
        async fetchPermissions(chainId, projectId, account) {
            const pkey = key(chainId, projectId, account);
            const { [pkey]: isFetching = false } = fetching;
            if (isFetching) {
                return;
            }
            try {
                fetching[pkey] = true;
                const permissions = await getProjectPermissions(chainId, projectId, account);
                set((current) => produce(current, (next) => {
                    const { cache } = next.permissions[pkey] || {};
                    next.permissions[pkey] = {
                        cache,
                        value: permissions,
                    };
                }));
            }
            finally {
                delete fetching[pkey];
            }
        },
        invalidate(chainId, projectId, account) {
            set((current) => produce(current, (next) => {
                const pkay = key(chainId, projectId, account);
                const { cache = 0 } = next.permissions[pkay] || {};
                next.permissions[pkay] = {
                    cache: cache + 1,
                };
            }));
        },
    };
});
function useProjectAbility(chainId, projectId, account, permission) {
    const { fetchPermissions, permissions } = useProjectAbilitiesStore();
    const address = account || address0;
    const { value, cache = 0 } = (projectId ? permissions[key(chainId, projectId, address)] : undefined) || {};
    useEffect(() => {
        async function fn() {
            if (!projectId) {
                return;
            }
            try {
                await fetchPermissions(chainId, projectId, address);
            }
            catch (e) {
                console.warn('Could not fetch permissions', chainId, projectId, address, e);
            }
        }
        fn();
    }, [fetchPermissions, chainId, projectId, address, cache]);
    switch (permission) {
        case ProjectPermission.Buy:
            return value?.canBuy;
        case ProjectPermission.Delete:
            return value?.canDelete;
        case ProjectPermission.Edit:
            return value?.canEdit;
        case ProjectPermission.Grant:
            return value?.canGrant;
        default:
            throw new Error('Invalid permission');
    }
}
export function useCurrentProjectAbility(permission) {
    const chainId = useCurrentChainId();
    return useProjectAbility(chainId, ProjectDraft.useEntity()?.id, useWalletAccount(), permission);
}
