import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EmptyState } from '~/components/EmptyState';
import Layout from '~/components/Layout';
import appCrashedImage from '~/shared/assets/images/app_crashed.png';
import appCrashedImage2x from '~/shared/assets/images/app_crashed@2x.png';
import { Button } from '~/components/Button';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCurrentChainSymbolicName } from '~/utils/chains';
export default function GenericErrorPage() {
    return (React.createElement(Layout, { rootBackgroundColor: "#EFEFEF" },
        React.createElement(GenericErrorPageContent, null)));
}
export function GenericErrorPageContent() {
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(Root, null,
        React.createElement(EmptyState, { image: React.createElement("img", { src: appCrashedImage, srcSet: `${appCrashedImage2x} 2x`, alt: "App crashed" }), link: React.createElement(Button, { kind: "special", as: Link, to: R.projects(routeOptions(chainName)), className: "d-none d-md-flex" }, "Projects"), linkOnMobile: true },
            React.createElement("p", null,
                "Oops. Something has broken down here.",
                React.createElement("br", null),
                "Please try one of the links below",
                React.createElement("br", null),
                "to get things back on track."))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-l2snkl" }) `
    text-align: center;
    width: 100%;

    img {
        margin-bottom: -0.75em;
        max-width: 180px;
    }

    @media (min-width: 376px) {
        img {
            max-width: 200px;
        }
    }

    @media (min-width: 745px) {
        img {
            max-width: 40%;
        }
    }

    @media (min-width: 1441px) {
        img {
            max-width: 50%;
        }
    }
`;
