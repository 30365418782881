import React, { useCallback, forwardRef } from 'react';
import useIsMounted from '~/shared/hooks/useIsMounted';
const SelectAllOnFocusDecorator = (WrappedComponent) => {
    const SelectAllOnFocusDecoratorWrapper = ({ onFocus: onFocusProp, ...props }, ref) => {
        const isMounted = useIsMounted();
        const onFocus = useCallback((e) => {
            e.persist();
            setTimeout(() => {
                if (isMounted() &&
                    (e.target instanceof HTMLInputElement ||
                        e.target instanceof HTMLTextAreaElement)) {
                    e.target.select();
                }
            }, 0);
            if (onFocusProp) {
                onFocusProp(e);
            }
        }, [onFocusProp, isMounted]);
        return React.createElement(WrappedComponent, { ...props, onFocus: onFocus, ref: ref });
    };
    const SelectAllOnFocusDecoratorWrapperFR = forwardRef(SelectAllOnFocusDecoratorWrapper);
    const OptInSelectAllOnFocusDecoratorWrapper = ({ selectAllOnFocus = false, ...props }, ref) => selectAllOnFocus ? (React.createElement(SelectAllOnFocusDecoratorWrapperFR, { ...props, ref: ref })) : (React.createElement(WrappedComponent, { ...props, ref: ref }));
    return forwardRef(OptInSelectAllOnFocusDecoratorWrapper);
};
export default SelectAllOnFocusDecorator;
