export default class TimeoutError extends Error {
    constructor() {
        super('Timeout');
        this.name = 'TimeoutError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TimeoutError);
        }
        Object.setPrototypeOf(this, TimeoutError.prototype);
    }
}
