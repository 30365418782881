import React from 'react';
import styled from 'styled-components';
import UnstyledRichMarkdownEditor from 'rich-markdown-editor';
import light from 'rich-markdown-editor/dist/styles/theme';
import { COLORS } from '~/shared/utils/styled';
const RichMarkdownEditor = styled(UnstyledRichMarkdownEditor).withConfig({ displayName: "RichMarkdownEditor", componentId: "sc-1kx45ou" }) `
    justify-content: flex-start;

    .block-menu-trigger {
        align-items: center;
        display: flex;
        justify-content: center;
    }
`;
const defaultTheme = {
    ...light,
    toolbarBackground: COLORS.primaryContrast,
    toolbarHoverBackground: COLORS.primaryDisabled,
    toolbarItem: COLORS.primaryLight,
    placeholder: COLORS.primaryDisabled,
    link: COLORS.link,
};
const defaultDisabledExtensions = [
    'table',
    'td',
    'th',
    'tr',
    'emoji',
    'container_notice',
    'image',
    'hr',
    'embed',
    'checkbox_item',
    'checkbox_list',
    'heading',
    'placeholder',
    'highlight',
];
export default function RichTextEditor({ theme = defaultTheme, disableExtensions = defaultDisabledExtensions, onChange, ...props }) {
    return (React.createElement(RichMarkdownEditor, { ...props, disableExtensions: disableExtensions, onChange: (getValue) => {
            onChange?.(getValue().replace(/\\$/gm, '').trim());
        }, theme: theme }));
}
