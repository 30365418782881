import CopyIcon from '@atlaskit/icon/glyph/copy';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import { Alert } from '~/components/Alert';
import { SponsorshipDecimals } from '~/components/Decimals';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { getSelfDelegationFraction } from '~/getters';
import { useConfigValueFromChain, useMediaQuery } from '~/hooks';
import { useAllOperatorsForWalletQuery } from '~/hooks/operators';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import { useInterceptHeartbeats } from '~/hooks/useInterceptHeartbeats';
import useOperatorLiveNodes from '~/hooks/useOperatorLiveNodes';
import { SelectField2 } from '~/marketplace/components/SelectField2';
import FormModal, { CopyButtonWrapAppendix, ErrorLabel, FieldWrap, MaxButton, Prop, PropList, PropValue, Section, SectionHeadline, TextAppendix, TextInput, WingedLabelWrap, } from '~/modals/FormModal';
import { stakeOnSponsorship } from '~/services/sponsorships';
import SvgIcon from '~/shared/components/SvgIcon';
import Label from '~/shared/components/Ui/Label';
import useCopy from '~/shared/hooks/useCopy';
import { useWalletAccount } from '~/shared/stores/wallet';
import Toast from '~/shared/toasts/Toast';
import { COLORS } from '~/shared/utils/styled';
import { truncate } from '~/shared/utils/text';
import { humanize } from '~/shared/utils/time';
import { waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { toBN, toBigInt, toFloat } from '~/utils/bn';
import { RejectionReason, isMessagedObject } from '~/utils/exceptions';
import { Route as R } from '~/utils/routes';
import { errorToast } from '~/utils/toast';
const limitErrorToaster = toaster(Toast, Layer.Toast);
function JoinSponsorshipModal({ chainId, onResolve, preselectedOperator, sponsorship, ...props }) {
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    const wallet = useWalletAccount();
    const { data: operatorChoices = null } = useAllOperatorsForWalletQuery(wallet);
    const [operator, setSelectedOperator] = useState(preselectedOperator);
    const { id: operatorId, dataTokenBalanceWei: operatorBalance, metadata } = operator;
    const hasUndelegationQueue = operator.queueEntries.length > 0;
    const { streamId } = sponsorship;
    const [busy, setBusy] = useState(false);
    const [rawAmount, setRawAmount] = useState('');
    const amount = ((a) => (a > 0n ? a : 0n))(toBigInt(rawAmount || 0, decimals));
    const heartbeats = useInterceptHeartbeats(operatorId);
    const { count: liveNodesCount, isLoading: liveNodesCountLoading } = useOperatorLiveNodes(heartbeats);
    const liveNodesOk = (!liveNodesCountLoading && liveNodesCount > 0) ||
        /**
         * Relax live node checking for broken operators (v1) as they cannot join the recovery
         * sponsorship otherwise.
         */
        operator.contractVersion === 1;
    const insufficientFunds = amount > operatorBalance;
    const minimumSelfDelegationFraction = useConfigValueFromChain('minimumSelfDelegationFraction', (value) => toFloat(value, decimals)) || toBN(0);
    const minimumSelfDelegationAmount = toBigInt(toBN(operator.valueWithoutEarnings).multipliedBy(minimumSelfDelegationFraction));
    const earlyLeaverPenaltyWei = useConfigValueFromChain('earlyLeaverPenaltyWei') || 0n;
    const ownerDelegationPercentage = useMemo(() => getSelfDelegationFraction(operator), [operator]);
    const currentSelfDelegationAmount = toBigInt(toBN(operator.valueWithoutEarnings).multipliedBy(ownerDelegationPercentage));
    const isBelowSelfFundingLimit = ownerDelegationPercentage.isLessThan(minimumSelfDelegationFraction);
    const minimumStakeWei = useConfigValueFromChain('minimumStakeWei');
    const isAboveMinimumStake = minimumStakeWei != null && amount >= minimumStakeWei;
    const canSubmit = amount > 0n &&
        !insufficientFunds &&
        liveNodesOk &&
        isAboveMinimumStake &&
        !hasUndelegationQueue &&
        !isBelowSelfFundingLimit;
    const { copy } = useCopy();
    const limitedSpace = useMediaQuery('screen and (max-width: 460px)');
    const clean = amount === 0n;
    return (React.createElement(FormModal, { ...props, title: "Join Sponsorship as Operator", canSubmit: canSubmit && !busy, submitLabel: "Join", submitting: busy, onBeforeAbort: (reason) => !busy && (clean || reason !== RejectionReason.Backdrop), onSubmit: async () => {
            if (!canSubmit) {
                return;
            }
            setBusy(true);
            try {
                await stakeOnSponsorship(chainId, sponsorship.id, amount, operator.id, {
                    onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                });
                onResolve?.();
            }
            catch (e) {
                if (isMessagedObject(e) && /error_tooManyOperators/.test(e.message)) {
                    return void errorToast({
                        title: 'Limit reached',
                        desc: 'All operator slots are taken.',
                    }, limitErrorToaster);
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(SectionHeadline, null,
            "Please set the amount of ",
            React.createElement(SponsorshipPaymentTokenName, null),
            " to stake on the selected Sponsorship"),
        React.createElement(Section, null,
            React.createElement(Label, { "$wrap": true }, "Sponsorship Stream ID"),
            React.createElement(FieldWrap, { "$grayedOut": true },
                React.createElement(TextInput, { defaultValue: streamId, readOnly: true }),
                !!streamId && (React.createElement(CopyButtonWrapAppendix, null,
                    React.createElement("button", { type: "button", onClick: () => void copy(streamId) },
                        React.createElement(CopyIcon, { label: "Copy", size: "small" }))))),
            React.createElement(StyledLabelWrap, null,
                React.createElement(Label, { "$wrap": true }, "Amount to stake"),
                rawAmount !== '' && !isAboveMinimumStake && (React.createElement(ErrorLabel, null,
                    "Minimum value is",
                    ' ',
                    React.createElement(SponsorshipDecimals, { amount: minimumStakeWei || 0n })))),
            React.createElement(FieldWrap, { "$invalid": rawAmount !== '' && !isAboveMinimumStake },
                React.createElement(TextInput, { autoFocus: true, name: "amount", onChange: ({ target }) => void setRawAmount(target.value), placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawAmount }),
                React.createElement(MaxButton, { onClick: () => {
                        setRawAmount(toFloat(operatorBalance, decimals).toString());
                    } }),
                React.createElement(TextAppendix, null,
                    React.createElement(SponsorshipPaymentTokenName, null))),
            React.createElement(PropList, null,
                React.createElement("li", null,
                    React.createElement(Prop, null, "Minimum stake duration"),
                    React.createElement(PropValue, null, humanize(sponsorship.minimumStakingPeriodSeconds))),
                React.createElement("li", null,
                    React.createElement(Prop, { "$invalid": insufficientFunds }, insufficientFunds ? (React.createElement(React.Fragment, null, "Not enough balance in Operator")) : (React.createElement(React.Fragment, null, "Available balance in Operator"))),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: operatorBalance, tooltip: limitedSpace }))),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Operator"),
                    React.createElement(PropValue, null, operatorChoices?.length === 1 ? (React.createElement(React.Fragment, null, metadata.name != null
                        ? `${metadata.name} (${truncate(operatorId)})`
                        : operatorId)) : (React.createElement(React.Fragment, null,
                        React.createElement(SelectField2, { placeholder: "Operator", options: operatorChoices?.map((o) => ({
                                value: o.id,
                                label: `${o.metadata.name} (${truncate(o.id)})`,
                            })) ?? [], value: operator.id, onChange: (id) => {
                                const selectedOp = operatorChoices?.find((o) => o.id === id);
                                if (selectedOp) {
                                    setSelectedOperator(selectedOp);
                                }
                            }, whiteVariant: true, isClearable: false }))))))),
        isBelowSelfFundingLimit && (React.createElement(StyledAlert, { type: "error", title: "Low self-funding" },
            "You cannot stake on Sponsorships because your Operator is below the self-funding requirement of",
            ' ',
            minimumSelfDelegationFraction.multipliedBy(100).toFixed(0),
            "%. Increase your Operator stake by at least",
            ' ',
            React.createElement(SponsorshipDecimals, { amount: minimumSelfDelegationAmount - currentSelfDelegationAmount }),
            ' ',
            "to continue.")),
        hasUndelegationQueue && (React.createElement(StyledAlert, { type: "error", title: "Warning!" }, "Cannot stake on sponsorship while delegators are awaiting undelegation")),
        !isBelowSelfFundingLimit && !hasUndelegationQueue && (React.createElement(LiveNodesCheck, { liveNodesCountLoading: liveNodesCountLoading, liveNodesCount: liveNodesCount })),
        sponsorship.minimumStakingPeriodSeconds > 0 && (React.createElement(StyledAlert, { type: "error", title: React.createElement(React.Fragment, null,
                "This Sponsorship has a minimum staking period of",
                ' ',
                humanize(sponsorship.minimumStakingPeriodSeconds),
                ". If you unstake or get voted out during this period, you will lose",
                ' ',
                React.createElement(SponsorshipDecimals, { amount: earlyLeaverPenaltyWei }),
                " in addition to the normal slashing penalty.") }))));
}
function LiveNodesCheck({ liveNodesCountLoading, liveNodesCount }) {
    if (liveNodesCountLoading) {
        return (React.createElement(StyledAlert, { type: "loading", title: "Checking Streamr nodes" },
            React.createElement("span", null, "In order to continue, you need to have one or more Streamr nodes running and correctly configured. You will be slashed if you stake without your nodes contributing resources to the stream.")));
    }
    if (liveNodesCount > 0) {
        return (React.createElement(StyledAlert, { type: "success", title: "Streamr nodes detected" },
            React.createElement("span", null, "Once you stake, your nodes will start working on the stream. Please ensure your nodes have enough resources available to handle the traffic in the stream.")));
    }
    return (React.createElement(StyledAlert, { type: "error", title: "Streamr nodes not detected" },
        React.createElement("p", null, "In order to continue, you need to have one or more Streamr nodes running and correctly configured. You will be slashed if you stake without your nodes contributing resources to the stream."),
        React.createElement("a", { href: R.docs('/node-runners/run-a-node'), target: "_blank", rel: "noreferrer noopener" },
            "How to run a Streamr node ",
            React.createElement(LinkIcon, { name: "externalLink" }))));
}
const StyledAlert = styled(Alert).withConfig({ displayName: "StyledAlert", componentId: "sc-1b9a4rd" }) `
    margin-top: 16px;

    a {
        color: ${COLORS.link};
        display: flex;
        align-items: center;
    }
`;
const LinkIcon = styled(SvgIcon).withConfig({ displayName: "LinkIcon", componentId: "sc-i2k4b5" }) `
    width: 24px;
`;
const StyledLabelWrap = styled(WingedLabelWrap).withConfig({ displayName: "StyledLabelWrap", componentId: "sc-rsqbnf" }) `
    margin-top: 10px;
`;
export const joinSponsorshipModal = toaster(JoinSponsorshipModal, Layer.Modal);
