import React from 'react';
import styled from 'styled-components';
import { Button as PrestyledButton } from '~/components/Button';
import { LG, PHONE } from '~/shared/utils/styled';
import Selector from './Selector';
import Layout from './Layout';
const Button = styled(PrestyledButton).withConfig({ displayName: "Button", componentId: "sc-18ynh4" }) `
    && {
        font-size: 12px;
        height: 32px;
        min-width: 80px;
    }

    @media ${PHONE} {
        && {
            min-width: 125px;
        }
    }
`;
const SettingsButton = styled(Button).withConfig({ displayName: "SettingsButton", componentId: "sc-1bi2t9u" }) `
    @media (max-width: ${LG - 1}px) {
        && {
            display: none;
        }
    }
`;
const Lhs = styled.div.withConfig({ displayName: "Lhs", componentId: "sc-m6d4ss" }) `
    flex-grow: 1;
    display: grid;
    grid-template-columns: auto 1fr;
`;
const Rhs = styled.div.withConfig({ displayName: "Rhs", componentId: "sc-1aq3oxc" }) `
    display: flex;

    button + button {
        margin-left: 16px;
    }
`;
const Inner = styled.div.withConfig({ displayName: "Inner", componentId: "sc-1tz2ysx" }) `
    display: flex;
    min-width: 0;
    padding: 0 16px;

    > div:first-child {
        min-width: calc(var(--LiveDataMinLhsWidth) - var(--LiveDataMinMargin) - 16px);
        max-width: calc(var(--LiveDataTimestampColumnMaxWidth));
        width: calc(
            100vw - var(--LiveDataInspectorWidth) - var(--LiveDataMinMargin) - 16px
        );
    }
`;
const UnstyledToolbar = ({ className, onPartitionChange, onSettingsButtonClick, partition, partitions = [], streamId, }) => {
    return (React.createElement("div", { className: className },
        React.createElement(Lhs, null,
            React.createElement(Layout.Pusher, null),
            React.createElement(Inner, null,
                React.createElement(Selector, { title: "Stream partitions", options: partitions, active: partition, onChange: onPartitionChange }))),
        React.createElement(Rhs, null,
            React.createElement("div", null, typeof onSettingsButtonClick === 'function' && (React.createElement(SettingsButton, { kind: "secondary", type: "button", onClick: () => onSettingsButtonClick(streamId) }, "Stream Settings"))))));
};
const Toolbar = styled(UnstyledToolbar).withConfig({ displayName: "Toolbar", componentId: "sc-1wgr0z1" }) `
    align-items: center;
    display: flex;
    padding-top: ${(props) => {
    return props.partitions.length > 1 ? '30px ' : '0';
}};
    padding-bottom: ${(props) => {
    return props.partitions.length > 1 ? '16px ' : '0';
}};
`;
Object.assign(Toolbar, {
    Lhs,
    Rhs,
});
export default Toolbar;
