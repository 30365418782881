import React from 'react';
import styled from 'styled-components';
import { Root as TabsRoot, Item as TabsItem } from '~/shared/components/Tabs';
import { TABLET } from '~/shared/utils/styled';
export default function NetworkChartDisplay({ children, periodTabs, sourceTabs, }) {
    return (React.createElement(Root, null,
        React.createElement(Header, null,
            React.createElement(SetSelector, null, sourceTabs),
            React.createElement(ChartPeriodWrap, null, periodTabs)),
        React.createElement(Body, null, children),
        React.createElement(ChartPeriodWrap, null, periodTabs)));
}
const ChartPeriodWrap = styled.div.withConfig({ displayName: "ChartPeriodWrap", componentId: "sc-30lcq4" }) ``;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-7ndfkh" }) `
    ${TabsRoot} {
        width: 100%;
    }

    ${TabsItem} {
        flex: 1;
        padding: 0;
    }

    > ${ChartPeriodWrap} {
        display: block;
        margin-top: 24px;
    }

    @media ${TABLET} {
        ${TabsRoot} {
            width: max-content;
        }

        ${TabsItem} {
            flex: none;
            padding: 0 20px;
        }

        ${ChartPeriodWrap} ${TabsItem} {
            padding: 0 12px;
        }

        > ${ChartPeriodWrap} {
            display: none;
        }
    }
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1dlpv6e" }) `
    ${ChartPeriodWrap} {
        display: none;
    }

    @media ${TABLET} {
        align-items: center;
        display: flex;

        ${ChartPeriodWrap} {
            display: block;
        }
    }
`;
const Body = styled.div.withConfig({ displayName: "Body", componentId: "sc-yzhwl8" }) ``;
const SetSelector = styled.div.withConfig({ displayName: "SetSelector", componentId: "sc-vmupo5" }) `
    flex: 1;
    min-width: 0;
`;
