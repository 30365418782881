import React from 'react';
import { toaster } from 'toasterhea';
import Toast, { ToastType } from '~/shared/toasts/Toast';
import { Layer } from './Layer';
export default function toast(props, t) {
    setTimeout(async () => {
        try {
            await (t || toaster(Toast, Layer.Toast)).pop(props);
        }
        catch (_) {
            // Do nothing
        }
    });
}
export function successToast(props, t) {
    toast({
        type: ToastType.Success,
        ...props,
    }, t);
}
export function errorToast(props, t) {
    toast({
        type: ToastType.Warning,
        ...props,
    }, t);
}
const vet = toaster(Toast, Layer.Toast);
export function validationErrorToast({ title, error }) {
    toast({
        title,
        type: ToastType.Warning,
        desc: (React.createElement("ul", null, error.messages.map((message, index) => (React.createElement("li", { key: index }, message))))),
    }, vet);
}
