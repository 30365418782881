import { produce } from 'immer';
import { create } from 'zustand';
import { FlagBusy } from '~/utils/errors';
const Flag = {
    isDelegatingFunds: (operatorId, wallet = '') => [
        operatorId,
        wallet.toLowerCase(),
    ],
    isUndelegatingFunds: (operatorId, wallet = '') => [
        operatorId,
        wallet.toLowerCase(),
    ],
    isCreatingSponsorship: (wallet) => [wallet.toLowerCase()],
    isFundingSponsorship: (sponsorshipId, wallet) => [
        sponsorshipId,
        wallet.toLowerCase(),
    ],
    isJoiningSponsorshipAsOperator: (sponsorshipId, operatorId) => [
        sponsorshipId,
        operatorId,
    ],
    isEditingSponsorshipFunding: (sponsorshipId, operatorId) => [
        sponsorshipId,
        operatorId,
    ],
};
export function flagKey(key, ...params) {
    const fn = Flag[key];
    return JSON.stringify([key, ...fn(...params)]);
}
const useFlagStore = create((set, get) => {
    return {
        flags: {},
        async wrap(key, fn) {
            if (get().flags[key]) {
                throw FlagBusy;
            }
            set((store) => produce(store, ({ flags }) => {
                flags[key] = true;
            }));
            try {
                return await fn();
            }
            finally {
                set((store) => produce(store, ({ flags }) => {
                    delete flags[key];
                }));
            }
        },
    };
});
export function useIsFlagged(key) {
    const { flags } = useFlagStore();
    return typeof key === 'string' && !!flags[key];
}
export function useFlagger() {
    return useFlagStore().wrap;
}
