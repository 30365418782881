import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { toaster } from 'toasterhea';
import { isAddress } from 'web3-validator';
import { z } from 'zod';
import { Minute } from '~/consts';
import { Operator_OrderBy } from '~/generated/gql/network';
import { getAllOperators, getDelegatedAmountForWallet, getOperatorById, getOperatorsByDelegation, getOperatorsByDelegationAndId, getOperatorsByDelegationAndMetadata, getParsedOperators, getParsedOperatorsByOwnerOrControllerAddress, getSpotApy, searchOperatorsByMetadata, } from '~/getters';
import { confirm } from '~/getters/confirm';
import { getSponsorshipTokenInfo } from '~/getters/getSponsorshipTokenInfo';
import { useRequestedBlockNumber } from '~/hooks';
import { invalidateSponsorshipQueries } from '~/hooks/sponsorships';
import DelegateFundsModal from '~/modals/DelegateFundsModal';
import { forceUndelegateModal } from '~/modals/ForceUndelegateModal';
import { undelegateFundsModal } from '~/modals/UndelegateFundsModal';
import { parseOperator } from '~/parsers/OperatorParser';
import { getOperatorDelegationAmount, processOperatorUndelegationQueue, } from '~/services/operators';
import { collectEarnings } from '~/services/sponsorships';
import { flagKey, useFlagger, useIsFlagged } from '~/shared/stores/flags';
import { useUncollectedEarningsStore } from '~/shared/stores/uncollectedEarnings';
import { getSigner } from '~/shared/stores/wallet';
import { truncate } from '~/shared/utils/text';
import { getQueryClient, waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { getBalance } from '~/utils/balance';
import { useCurrentChainId } from '~/utils/chains';
import { getContractAddress } from '~/utils/contracts';
import { Break, FlagBusy } from '~/utils/errors';
import { isRejectionReason, isTransactionRejection } from '~/utils/exceptions';
import { errorToast, successToast } from '~/utils/toast';
export function useOperatorForWalletQuery(address = '') {
    const currentChainId = useCurrentChainId();
    return useQuery({
        queryKey: ['useOperatorForWalletQuery', currentChainId, address.toLowerCase()],
        queryFn: async () => {
            const allOperators = await getParsedOperatorsByOwnerOrControllerAddress(currentChainId, address, {
                force: true,
            });
            if (allOperators.length > 0) {
                return allOperators[0];
            }
            return null;
        },
    });
}
export function useAllOperatorsForWalletQuery(address = '') {
    const currentChainId = useCurrentChainId();
    return useQuery({
        queryKey: [
            'useAllOperatorsForWalletQuery',
            currentChainId,
            address.toLowerCase(),
        ],
        queryFn: () => getParsedOperatorsByOwnerOrControllerAddress(currentChainId, address, {
            force: true,
        }),
    });
}
export function useOperatorByIdQuery(operatorId = '') {
    const currentChainId = useCurrentChainId();
    const minBlockNumber = useRequestedBlockNumber();
    return useQuery({
        queryKey: ['operatorByIdQueryKey', currentChainId, operatorId, minBlockNumber],
        async queryFn() {
            if (!operatorId) {
                return null;
            }
            const operator = await getOperatorById(currentChainId, operatorId, {
                force: true,
                minBlockNumber,
            });
            if (operator) {
                try {
                    return parseOperator(operator, { chainId: currentChainId });
                }
                catch (e) {
                    if (!(e instanceof z.ZodError)) {
                        throw e;
                    }
                    console.warn('Failed to parse an operator', operator, e);
                }
            }
            return null;
        },
        placeholderData: keepPreviousData,
        retry: false,
        staleTime: Minute,
    });
}
export function invalidateActiveOperatorByIdQueries(chainId, operatorId) {
    if (operatorId) {
        return getQueryClient().invalidateQueries({
            queryKey: ['operatorByIdQueryKey', chainId, operatorId],
            exact: false,
            refetchType: 'active',
        });
    }
    return getQueryClient().invalidateQueries({
        queryKey: ['operatorByIdQueryKey', chainId],
        exact: false,
        refetchType: 'active',
    });
}
export function useOperatorStatsForWallet(address = '') {
    const { data: operator = null } = useOperatorForWalletQuery(address);
    if (!operator) {
        return operator;
    }
    const { delegatorCount: numOfDelegators, valueWithoutEarnings: value, stakes, } = operator;
    return {
        numOfDelegators,
        numOfSponsorships: stakes.length,
        value,
    };
}
function toDelegationForWallet(operator, wallet) {
    return {
        ...operator,
        apy: getSpotApy(operator),
        myShare: getDelegatedAmountForWallet(wallet, operator),
    };
}
/**
 * @todo Refactor using `useQuery`.
 */
export function useDelegationsStats(address = '') {
    const [stats, setStats] = useState();
    const addr = address.toLowerCase();
    const chainId = useCurrentChainId();
    useEffect(() => {
        /**
         * @todo Refactor using useQuery. #refactor
         */
        let mounted = true;
        if (!addr) {
            setStats(null);
            return () => { };
        }
        setStats(undefined);
        setTimeout(async () => {
            const operators = await getParsedOperators(() => getOperatorsByDelegation({
                chainId,
                first: 1000,
                address: addr,
            }), {
                chainId,
                mapper(operator) {
                    return toDelegationForWallet(operator, addr);
                },
                onBeforeComplete(total, parsed) {
                    if (total !== parsed) {
                        errorToast({
                            title: 'Warning',
                            desc: `Delegation stats are calculated using ${parsed} out of ${total} available operators due to parsing issues.`,
                        });
                    }
                },
            });
            if (!mounted) {
                return;
            }
            if (!operators.length) {
                return void setStats({
                    value: 0n,
                    minApy: 0,
                    maxApy: 0,
                    numOfOperators: 0,
                });
            }
            let minApy = Number.POSITIVE_INFINITY;
            let maxApy = Number.NEGATIVE_INFINITY;
            operators.forEach(({ apy }) => {
                minApy = Math.min(minApy, apy);
                maxApy = Math.max(maxApy, apy);
            });
            const value = operators.reduce((sum, { myShare }) => sum + myShare, 0n);
            setStats({
                value,
                minApy,
                maxApy,
                numOfOperators: operators.length,
            });
        });
        return () => {
            mounted = false;
        };
    }, [addr, chainId]);
    return stats;
}
export function invalidateDelegationsForWalletQueries(chainId) {
    getQueryClient().invalidateQueries({
        exact: false,
        queryKey: ['useDelegationsForWalletQuery', chainId],
        refetchType: 'active',
    });
}
export function useDelegationsForWalletQuery({ address: addressProp = '', pageSize = 10, searchQuery: searchQueryProp = '', orderBy, orderDirection, }) {
    const currentChainId = useCurrentChainId();
    const address = addressProp.toLowerCase();
    const searchQuery = searchQueryProp.toLowerCase();
    return useInfiniteQuery({
        queryKey: [
            'useDelegationsForWalletQuery',
            currentChainId,
            address,
            searchQuery,
            pageSize,
        ],
        async queryFn({ pageParam: skip }) {
            const elements = await getParsedOperators(() => {
                const params = {
                    chainId: currentChainId,
                    first: pageSize,
                    skip,
                    address,
                    orderBy: mapOperatorOrder(orderBy),
                    orderDirection: orderDirection,
                    force: true,
                };
                if (!searchQuery) {
                    /**
                     * Empty search = look for all operators.
                     */
                    return getOperatorsByDelegation(params);
                }
                if (isAddress(searchQuery)) {
                    /**
                     * Look for a delegation for a given operator id.
                     */
                    return getOperatorsByDelegationAndId({
                        ...params,
                        operatorId: searchQuery,
                    });
                }
                return getOperatorsByDelegationAndMetadata({
                    ...params,
                    searchQuery,
                });
            }, {
                chainId: currentChainId,
                mapper(operator) {
                    return toDelegationForWallet(operator, address);
                },
                onBeforeComplete(total, parsed) {
                    if (total !== parsed) {
                        errorToast({
                            title: 'Failed to parse',
                            desc: `${total - parsed} out of ${total} operators could not be parsed.`,
                        });
                    }
                },
            });
            return {
                skip,
                elements,
            };
        },
        initialPageParam: 0,
        getNextPageParam: ({ skip, elements }) => {
            return elements.length === pageSize ? skip + pageSize : null;
        },
        staleTime: Minute,
        placeholderData: keepPreviousData,
    });
}
export function invalidateAllOperatorsQueries(chainId) {
    getQueryClient().invalidateQueries({
        exact: false,
        queryKey: ['useAllOperatorsQuery', chainId],
        refetchType: 'active',
    });
}
export function useAllOperatorsQuery({ batchSize = 10, searchQuery: searchQueryProp = '', orderBy, orderDirection, }) {
    const searchQuery = searchQueryProp.toLowerCase();
    const currentChainId = useCurrentChainId();
    return useInfiniteQuery({
        queryKey: [
            'useAllOperatorsQuery',
            currentChainId,
            searchQuery,
            batchSize,
            orderBy,
            orderDirection,
        ],
        async queryFn({ pageParam: skip }) {
            const elements = await getParsedOperators(() => {
                const params = {
                    chainId: currentChainId,
                    first: batchSize,
                    skip,
                    orderBy: mapOperatorOrder(orderBy),
                    orderDirection: orderDirection,
                    force: true,
                };
                if (!searchQuery) {
                    return getAllOperators(params);
                }
                return searchOperatorsByMetadata({
                    ...params,
                    searchQuery,
                });
            }, {
                chainId: currentChainId,
                onBeforeComplete(total, parsed) {
                    if (total !== parsed) {
                        errorToast({
                            title: 'Failed to parse',
                            desc: `${total - parsed} out of ${total} operators could not be parsed.`,
                        });
                    }
                },
            });
            return {
                skip,
                elements,
            };
        },
        initialPageParam: 0,
        getNextPageParam: ({ skip, elements }) => {
            return elements.length === batchSize ? skip + batchSize : null;
        },
        staleTime: Minute,
        placeholderData: keepPreviousData,
    });
}
export function useIsDelegatingFundsToOperator(operatorId, wallet) {
    return useIsFlagged(flagKey('isDelegatingFunds', operatorId || '', wallet || ''));
}
const delegateFundsModal = toaster(DelegateFundsModal, Layer.Modal);
/**
 * Triggers funds delegation and raises an associated flag for the
 * duration of the process.
 */
export function useDelegateFunds() {
    const withFlag = useFlagger();
    return useCallback(({ chainId, onDone, operator, wallet, }) => {
        if (!wallet) {
            return;
        }
        void (async () => {
            try {
                try {
                    await withFlag(flagKey('isDelegatingFunds', operator.id, wallet), async () => {
                        const balance = await getBalance({
                            chainId,
                            tokenAddress: getContractAddress('sponsorshipPaymentToken', chainId),
                            walletAddress: wallet,
                        });
                        const delegatedTotal = await getOperatorDelegationAmount(chainId, operator.id, wallet);
                        await delegateFundsModal.pop({
                            operator,
                            balance,
                            delegatedTotal,
                            chainId,
                        });
                        invalidateActiveOperatorByIdQueries(chainId, operator.id);
                    });
                }
                catch (e) {
                    if (e === FlagBusy) {
                        return;
                    }
                    if (isRejectionReason(e)) {
                        return;
                    }
                    throw e;
                }
                onDone?.();
            }
            catch (e) {
                console.warn('Could not delegate funds', e);
            }
        })();
    }, [withFlag]);
}
export function useIsUndelegatingFundsToOperator(operatorId, wallet) {
    return useIsFlagged(flagKey('isUndelegatingFunds', operatorId || '', wallet || ''));
}
/**
 * Triggers funds undelegation and raises an associated flag for the
 * duration of the process.
 */
export function useUndelegateFunds() {
    const withFlag = useFlagger();
    return useCallback(({ chainId, onDone, operator, wallet, }) => {
        if (!wallet) {
            return;
        }
        void (async () => {
            try {
                try {
                    await withFlag(flagKey('isUndelegatingFunds', operator.id, wallet), async () => {
                        const balance = await getBalance({
                            chainId,
                            tokenAddress: getContractAddress('sponsorshipPaymentToken', chainId),
                            walletAddress: wallet,
                        });
                        const delegatedTotal = await getOperatorDelegationAmount(chainId, operator.id, wallet);
                        await undelegateFundsModal.pop({
                            chainId,
                            operator,
                            balance,
                            delegatedTotal,
                        });
                        invalidateActiveOperatorByIdQueries(chainId, operator.id);
                    });
                }
                catch (e) {
                    if (e === FlagBusy) {
                        return;
                    }
                    if (isRejectionReason(e)) {
                        return;
                    }
                    throw e;
                }
                onDone?.();
            }
            catch (e) {
                console.warn('Could not undelegate funds', e);
            }
        })();
    }, [withFlag]);
}
const mapOperatorOrder = (orderBy) => {
    switch (orderBy) {
        case 'totalValue':
            return Operator_OrderBy.ValueWithoutEarnings;
        case 'deployed':
            return Operator_OrderBy.TotalStakeInSponsorshipsWei;
        case 'operatorCut':
            return Operator_OrderBy.OperatorsCutFraction;
        default:
            return Operator_OrderBy.Id;
    }
};
/**
 * Returns a callback that takes the user through the process of collecting
 * earnings for given operator/sponsorship pair.
 */
export function useCollectEarnings() {
    const { fetch: fetchUncollectedEarnings } = useUncollectedEarningsStore();
    return useCallback((params) => {
        const { chainId, sponsorshipId, operatorId } = params;
        void (async () => {
            try {
                if (!(await confirm({
                    cancelLabel: 'Cancel',
                    proceedLabel: 'Proceed',
                    title: 'Confirm',
                    description: (React.createElement(React.Fragment, null,
                        "This action transfers uncollected earnings to the Operator contract (",
                        truncate(operatorId),
                        ").")),
                }))) {
                    return;
                }
                await collectEarnings(chainId, sponsorshipId, operatorId, {
                    onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                });
                await fetchUncollectedEarnings(chainId, operatorId);
                /**
                 * Let's refresh the operator page to incl. now-collected earnings
                 * in the overview section.
                 */
                invalidateActiveOperatorByIdQueries(chainId, operatorId);
                successToast({
                    title: 'Earnings collected!',
                    autoCloseAfter: 5,
                    desc: (React.createElement("p", null, "Earnings have been successfully collected and are now available in the Operator\u00A0balance.")),
                });
            }
            catch (e) {
                if (e === Break) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                console.error('Could not collect earnings', e);
            }
        })();
    }, [fetchUncollectedEarnings]);
}
/**
 * Returns a callback that takes the user through force-undelegation process.
 */
export function useForceUndelegate() {
    return useCallback((chainId, operator, amount) => {
        void (async () => {
            try {
                const wallet = await (await getSigner()).getAddress();
                await getSponsorshipTokenInfo(chainId);
                const sponsorshipId = await forceUndelegateModal.pop({
                    chainId,
                    operator,
                    amount,
                });
                invalidateSponsorshipQueries(chainId, wallet, sponsorshipId);
            }
            catch (e) {
                if (e === Break) {
                    return;
                }
                if (isRejectionReason(e)) {
                    return;
                }
                console.error('Could not force undelegate', e);
            }
        })();
    }, []);
}
/**
 * Returns a callback that takes the user through undelegation queue processing.
 */
export function useProcessUndelegationQueue() {
    return useCallback((chainId, operatorId) => {
        void (async () => {
            try {
                await processOperatorUndelegationQueue(chainId, operatorId, {
                    onReceipt: () => {
                        // Refresh operator to update queue entries
                        invalidateActiveOperatorByIdQueries(chainId, operatorId);
                    },
                });
            }
            catch (e) {
                if (e === Break) {
                    return;
                }
                if (isRejectionReason(e)) {
                    return;
                }
                console.error('Could not process undelegation queue', e);
            }
        })();
    }, []);
}
