import React from 'react';
import styled, { css } from 'styled-components';
import { useWalletAccount } from '~/shared/stores/wallet';
import NoWalletOverlay from './NoWalletOverlay';
export default function WalletPass({ children, resourceName, roundBorders = false, }) {
    const locked = !useWalletAccount();
    return (React.createElement(Root, { "$roundBorders": roundBorders },
        React.createElement(Content, { "$blur": locked }, children),
        locked ? React.createElement(NoWalletOverlay, { resourceName: resourceName }) : React.createElement(React.Fragment, null)));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-y62i8o" }) `
    position: relative;

    ${({ $roundBorders = false }) => $roundBorders &&
    css `
            border-radius: 16px;
            overflow: hidden;
        `}
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1jfhbzn" }) `
    transition: 350ms filter;

    ${({ $blur = false }) => $blur &&
    css `
            filter: blur(5px);
        `}
`;
