import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { toaster } from 'toasterhea';
import { randomHex } from 'web3-utils';
import { z } from 'zod';
import { Alert } from '~/components/Alert';
import { Button } from '~/components/Button';
import CropImageModal from '~/components/CropImageModal/CropImageModal';
import { Hint } from '~/components/Hint';
import { PropertyDropdown, PropertyDropdownList, PropertyIcon, } from '~/components/PropertyDropdown';
import { BehindIndexError } from '~/errors/BehindIndexError';
import { getParsedOperatorByOwnerAddress } from '~/getters';
import FormModal, { ErrorLabel, FieldWrap, Section, SectionHeadline, TextAppendix, TextInput, TextareaCounter, TextareaInput, WingedLabelWrap, } from '~/modals/FormModal';
import { createOperator, updateOperator } from '~/services/operators';
import AvatarImage from '~/shared/components/AvatarImage';
import SvgIcon from '~/shared/components/SvgIcon';
import Label from '~/shared/components/Ui/Label';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS, TABLET } from '~/shared/utils/styled';
import { sleep, waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { RejectionReason, isRejectionReason, isTransactionRejection, } from '~/utils/exceptions';
const cropModal = toaster(CropImageModal, Layer.Modal);
const DescriptionLengthLimit = 120;
const Validator = z.object({
    cut: z.coerce
        .number()
        .min(0, 'Value must be between 0 and 100')
        .max(100, 'Value must be between 0 and 100'),
    description: z.string().max(DescriptionLengthLimit, 'Description is too long'),
    imageToUpload: z.instanceof(File).optional(),
    name: z.string().trim().min(1, 'Name is required'),
    redundancyFactor: z.coerce.number().min(1, 'Value must be greater or equal to 1'),
    url: z.union([z.literal(''), z.string().url()]),
    email: z.union([z.literal(''), z.string().email()]),
    twitter: z.union([z.literal(''), z.string().url()]),
    x: z.union([z.literal(''), z.string().url()]),
    telegram: z.union([z.literal(''), z.string().url()]),
    reddit: z.union([z.literal(''), z.string().url()]),
    linkedIn: z.union([z.literal(''), z.string().url()]),
});
function OperatorModal({ onResolve, onReject, operator, chainId, ...props }) {
    const [title, submitLabel] = operator
        ? ['Edit Operator', 'Save']
        : ['Become an Operator', 'Become an Operator'];
    const [busy, setBusy] = useState(false);
    const currentData = useMemo(() => ({
        cut: operator?.operatorsCut.toString() || '',
        description: operator?.metadata.description || '',
        imageToUpload: undefined,
        name: operator?.metadata.name || '',
        redundancyFactor: operator?.metadata.redundancyFactor?.toString() || '2',
        url: operator?.metadata.url || '',
        email: operator?.metadata.email || '',
        twitter: operator?.metadata.twitter || '',
        x: operator?.metadata.x || '',
        telegram: operator?.metadata.telegram || '',
        reddit: operator?.metadata.reddit || '',
        linkedIn: operator?.metadata.linkedIn || '',
    }), [operator]);
    const [nextData, updateNextData] = useState(currentData);
    useEffect(() => {
        /**
         * Handle an update coming from the outside. It won't happen often
         * but it can happen.
         */
        updateNextData(currentData);
    }, [currentData]);
    const changelog = {
        imageToUpload: !!nextData.imageToUpload,
        name: currentData.name !== nextData.name,
        description: currentData.description !== nextData.description,
        redundancyFactor: Number(currentData.redundancyFactor) !== Number(nextData.redundancyFactor),
        cut: Number(currentData.cut) !== Number(nextData.cut),
        url: currentData.url !== nextData.url,
        email: currentData.email !== nextData.email,
        twitter: currentData.twitter !== nextData.twitter,
        x: currentData.x !== nextData.x,
        telegram: currentData.telegram !== nextData.telegram,
        reddit: currentData.reddit !== nextData.reddit,
        linkedIn: currentData.linkedIn !== nextData.linkedIn,
    };
    const dirty = Object.values(changelog).some(Boolean);
    const randomAddress = useMemo(() => randomHex(20), []);
    const fileInputRef = useRef(null);
    const imageBlob = (nextData.imageToUpload && URL.createObjectURL(nextData.imageToUpload)) ||
        operator?.metadata.imageUrl;
    const [errors, setErrors] = useState({});
    function resetError(key) {
        setErrors(({ [key]: _, ...c }) => c);
    }
    function validate(fn) {
        try {
            fn();
        }
        catch (e) {
            if (e instanceof z.ZodError) {
                const newErrors = {};
                e.issues.forEach(({ path, message }) => {
                    newErrors[path.join('.')] = message;
                });
                setErrors((existingErrors) => ({
                    ...existingErrors,
                    ...newErrors,
                }));
            }
            throw e;
        }
    }
    const finalData = useMemo(() => {
        try {
            return Validator.parse(nextData);
        }
        catch (_) {
            return null;
        }
    }, [nextData]);
    const walletAddress = useWalletAccount();
    const canSubmit = !busy && !!finalData && dirty && !!walletAddress;
    const readonlyCut = !!operator && operator.stakes.length > 0;
    return (React.createElement(FormModal, { ...props, title: title, canSubmit: canSubmit, submitLabel: submitLabel, submitting: busy, onBeforeAbort: (reason) => !busy && (reason !== RejectionReason.Backdrop || !dirty), onReject: onReject, onSubmit: async () => {
            if (!canSubmit) {
                return;
            }
            setBusy(true);
            try {
                let blockNumber = 0;
                if (!operator) {
                    await createOperator(chainId, finalData, {
                        onReceipt: ({ blockNumber: blockNo }) => {
                            blockNumber = blockNo;
                            return waitForIndexedBlock(chainId, blockNo);
                        },
                    });
                }
                else {
                    await updateOperator(chainId, operator, finalData, {
                        onReceipt: ({ blockNumber: blockNo }) => {
                            blockNumber = blockNo;
                            return waitForIndexedBlock(chainId, blockNo);
                        },
                    });
                }
                let operatorId = operator?.id;
                if (!operatorId) {
                    /**
                     * The following loop ensures that each thrown instance of `BehindIndexError`
                     * causes a proper retry.
                     */
                    for (;;) {
                        try {
                            /**
                             * If the following request hits the indexer too early, i.e. before block
                             * at `blockNumber` got ingested, it'll give us nothing (it does not know
                             * about the operator). The above loop helps maneuver through such edge case.
                             */
                            operatorId = (await getParsedOperatorByOwnerAddress(chainId, walletAddress, {
                                force: true,
                                minBlockNumber: blockNumber,
                            }))?.id;
                        }
                        catch (e) {
                            if (!(e instanceof BehindIndexError)) {
                                throw e;
                            }
                            /**
                             * If we're behind on the indexed blocks with the operator id request
                             * we have to retry until we're good to move forward. Let's wait 5s
                             * and retry.
                             */
                            await sleep(5000);
                            continue;
                        }
                        /**
                         * After all the trying the operator id can still be nullish. In such case we don't
                         * wanna continue. It is, after all, unexpected behaviour, especially for newly
                         * created operator.
                         */
                        if (!operatorId) {
                            throw new Error('Empty operator id');
                        }
                        break;
                    }
                }
                onResolve?.({ operatorId, blockNumber });
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(SectionHeadline, null,
            "Please choose the percentage for the Owner's cut",
            ' ',
            React.createElement(Hint, null,
                React.createElement("p", null, "The Owner's cut percentage is the earnings split between the Operator and its Delegators. This value can be updated as long as the Operator is not staked on any\u00A0Sponsorships."))),
        React.createElement(Section, null,
            React.createElement(WingedLabelWrap, null,
                React.createElement(Label, { "$wrap": true }, "Owner's cut percentage*"),
                errors?.cut && React.createElement(ErrorLabel, null, errors.cut)),
            React.createElement(FieldWrap, { "$invalid": !!errors?.cut, "$grayedOut": readonlyCut },
                React.createElement(TextInput, { name: "cut", autoFocus: !readonlyCut, onChange: ({ target: { value: cut } }) => {
                        updateNextData((c) => ({
                            ...c,
                            cut,
                        }));
                    }, placeholder: "0", readOnly: busy, type: "number", min: 0, max: 100, value: nextData.cut, disabled: readonlyCut }),
                React.createElement(TextAppendix, null, "%"))),
        readonlyCut && (React.createElement(AlertContainer, null,
            React.createElement(Alert, { type: "notice", title: "Owner's cut locked" },
                React.createElement("span", null, "The owner's cut can't be modified while staked. To change it, fully unstake from all sponsorships.")))),
        React.createElement(SectionHeadline, null,
            "Please input your node redundancy factor",
            ' ',
            React.createElement(Hint, null,
                React.createElement("p", null, "Sets the amount of duplicated work when running a fleet of multiple nodes. Doing redundant work protects against slashing in case some of your nodes experience failures. For example, setting this to 1 means that no duplication of work occurs (the feature is off), and setting it to 2 means that each stream assignment will be worked on by 2 nodes in your fleet."))),
        React.createElement(Section, null,
            React.createElement(WingedLabelWrap, null,
                React.createElement(Label, { "$wrap": true }, "Node redundancy factor*"),
                errors?.redundancyFactor && (React.createElement(ErrorLabel, null, errors.redundancyFactor))),
            React.createElement(FieldWrap, { "$invalid": !!errors?.redundancyFactor },
                React.createElement(TextInput, { name: "redundancyFactor", onChange: ({ target: { value: redundancyFactor } }) => {
                        updateNextData((c) => ({
                            ...c,
                            redundancyFactor,
                        }));
                    }, placeholder: "2", readOnly: busy, type: "number", min: 1, max: 100, step: 1, value: nextData.redundancyFactor }))),
        React.createElement(AboutOperator, null,
            React.createElement(SectionHeadline, null, "About Operator"),
            React.createElement(Section, null,
                React.createElement(WingedLabelWrap, null,
                    React.createElement(Label, { "$wrap": true }, "Display name*"),
                    errors?.name && React.createElement(ErrorLabel, null, errors.name)),
                React.createElement(FieldWrap, { "$invalid": !!errors?.name },
                    React.createElement(TextInput, { name: "name", onChange: ({ target: { value: name } }) => {
                            updateNextData((c) => ({
                                ...c,
                                name,
                            }));
                        }, readOnly: busy, type: "text", value: nextData.name, placeholder: "Name" })),
                React.createElement(AboutOperatorField, null,
                    React.createElement(WingedLabelWrap, null,
                        React.createElement(Label, { "$wrap": true }, "Description"),
                        errors?.description && (React.createElement(ErrorLabel, null, "Description is too long"))),
                    React.createElement(FieldWrap, { "$invalid": !!errors?.description },
                        React.createElement(TextareaInput, { name: "description", onChange: ({ target: { value: description } }) => {
                                updateNextData((c) => ({
                                    ...c,
                                    description,
                                }));
                            }, readOnly: busy, value: nextData.description, placeholder: "Description", "$minHeight": 110 }),
                        React.createElement(TextareaCounter, { "$invalid": !!errors?.description },
                            nextData.description.length,
                            "/",
                            DescriptionLengthLimit)),
                    React.createElement(PropertyDropdownList, null,
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.url, onChange: () => {
                                    resetError('url');
                                }, onDismiss: () => {
                                    resetError('url');
                                }, onSubmit: (url) => {
                                    resetError('url');
                                    validate(() => {
                                        Validator.pick({
                                            url: true,
                                        }).parse({ url });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        url,
                                    }));
                                }, placeholder: "https://siteinfo.com", submitLabel: "Add site URL", title: "Add a site URL", toggleIcon: React.createElement(PropertyIcon, { name: "web" }), value: nextData.url, valuePlaceholder: "Site URL" })),
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.email, onChange: () => {
                                    resetError('email');
                                }, onDismiss: () => {
                                    resetError('email');
                                }, onSubmit: (email) => {
                                    resetError('email');
                                    validate(() => {
                                        Validator.pick({
                                            email: true,
                                        }).parse({ email });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        email,
                                    }));
                                }, placeholder: "owner@example.com", submitLabel: "Add contact email", title: "Add a contact email", toggleIcon: React.createElement(PropertyIcon, { name: "email" }), value: nextData.email, valuePlaceholder: "Contact email" })),
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.twitter, onChange: () => {
                                    resetError('twitter');
                                }, onDismiss: () => {
                                    resetError('twitter');
                                }, onSubmit: (twitter) => {
                                    resetError('twitter');
                                    validate(() => {
                                        Validator.pick({
                                            twitter: true,
                                        }).parse({ twitter });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        twitter,
                                    }));
                                }, submitLabel: "Add Twitter link", title: "Add Twitter link", toggleIcon: React.createElement(PropertyIcon, { name: "twitter", "$color": nextData.twitter ? '#1da1f2' : undefined }), value: nextData.twitter })),
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.x, onChange: () => {
                                    resetError('x');
                                }, onDismiss: () => {
                                    resetError('x');
                                }, onSubmit: (x) => {
                                    resetError('x');
                                    validate(() => {
                                        Validator.pick({
                                            x: true,
                                        }).parse({ x });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        x,
                                    }));
                                }, submitLabel: "Add X link", title: "Add X link", toggleIcon: React.createElement(PropertyIcon, { name: "xCom" }), value: nextData.x })),
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.telegram, onChange: () => {
                                    resetError('telegram');
                                }, onDismiss: () => {
                                    resetError('telegram');
                                }, onSubmit: (telegram) => {
                                    resetError('telegram');
                                    validate(() => {
                                        Validator.pick({
                                            telegram: true,
                                        }).parse({ telegram });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        telegram,
                                    }));
                                }, submitLabel: "Add Telegram link", title: "Add Telegram link", toggleIcon: React.createElement(PropertyIcon, { name: "telegram", "$color": nextData.telegram ? '#2aabee' : undefined }), value: nextData.telegram })),
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.reddit, onChange: () => {
                                    resetError('reddit');
                                }, onDismiss: () => {
                                    resetError('reddit');
                                }, onSubmit: (reddit) => {
                                    resetError('reddit');
                                    validate(() => {
                                        Validator.pick({
                                            reddit: true,
                                        }).parse({ reddit });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        reddit,
                                    }));
                                }, submitLabel: "Add Reddit link", title: "Add Reddit link", toggleIcon: React.createElement(PropertyIcon, { name: "reddit", "$color": nextData.reddit ? '#ff5700' : undefined }), value: nextData.reddit })),
                        React.createElement("li", null,
                            React.createElement(PropertyDropdown, { error: errors?.linkedIn, onChange: () => {
                                    resetError('linkedIn');
                                }, onDismiss: () => {
                                    resetError('linkedIn');
                                }, onSubmit: (linkedIn) => {
                                    resetError('linkedIn');
                                    validate(() => {
                                        Validator.pick({
                                            linkedIn: true,
                                        }).parse({ linkedIn });
                                    });
                                    updateNextData((c) => ({
                                        ...c,
                                        linkedIn,
                                    }));
                                }, submitLabel: "Add LinkedIn link", title: "Add LinkedIn link", toggleIcon: React.createElement(PropertyIcon, { name: "linkedin", "$color": nextData.linkedIn ? '#0077b5' : undefined }), value: nextData.linkedIn })))),
                React.createElement(AboutOperatorField, null,
                    React.createElement(Label, { "$wrap": true }, "Operator Avatar"),
                    React.createElement(FieldWrap, null,
                        React.createElement(AvatarField, null,
                            React.createElement(AvatarDisplayContainer, null, imageBlob ? (React.createElement(OperatorAvatar, { src: imageBlob })) : (React.createElement(AvatarPlaceholder, { username: randomAddress }))),
                            React.createElement("div", null,
                                React.createElement("input", { ref: fileInputRef, type: "file", accept: ".jpg,.jpeg,.png", style: { display: 'none' }, onChange: (e) => {
                                        void (async () => {
                                            if (!e.target.files?.length) {
                                                return;
                                            }
                                            try {
                                                const imageToUpload = await cropModal.pop({
                                                    imageUrl: URL.createObjectURL(e.target.files[0]),
                                                    mask: 'round',
                                                });
                                                updateNextData((c) => ({
                                                    ...c,
                                                    imageToUpload,
                                                }));
                                            }
                                            catch (e) {
                                                if (isRejectionReason(e)) {
                                                    return;
                                                }
                                                throw e;
                                            }
                                        })();
                                    } }),
                                React.createElement(Button, { kind: "secondary", size: "normal", type: "button", onClick: () => {
                                        fileInputRef.current?.click();
                                    } },
                                    React.createElement(ButtonIcon, { name: "plus" }),
                                    "Upload Avatar"),
                                React.createElement(AvatarRequirements, null,
                                    React.createElement("li", null, "JPEG or PNG format only"))))))))));
}
export const operatorModal = toaster(OperatorModal, Layer.Modal);
const AboutOperator = styled.div.withConfig({ displayName: "AboutOperator", componentId: "sc-1lrdm3l" }) `
    margin-top: 40px;
`;
const AboutOperatorField = styled.div.withConfig({ displayName: "AboutOperatorField", componentId: "sc-1fe3ayk" }) `
    margin-top: 16px;

    ${PropertyDropdownList} {
        margin-top: 16px;
    }
`;
const AvatarField = styled.div.withConfig({ displayName: "AvatarField", componentId: "sc-1c4tt7q" }) `
    padding: 20px;
    width: 100%;

    button {
        width: 100%;
    }

    @media ${TABLET} {
        display: flex;

        button {
            width: auto;
        }
    }
`;
const AvatarDisplayContainer = styled.div.withConfig({ displayName: "AvatarDisplayContainer", componentId: "sc-1sra9ff" }) `
    margin: 0 auto 16px;
    width: max-content;

    @media ${TABLET} {
        margin: 0 32px 0 0;
    }
`;
const AvatarImageStyles = css `
    width: 80px;
    height: 80px;
    border: 1px solid ${COLORS.secondaryHover};
    border-radius: 100%;
`;
const AvatarPlaceholder = styled(AvatarImage).withConfig({ displayName: "AvatarPlaceholder", componentId: "sc-wwa7mj" }) `
    ${AvatarImageStyles}
`;
const OperatorAvatar = styled.img.withConfig({ displayName: "OperatorAvatar", componentId: "sc-cnyqrx" }) `
    ${AvatarImageStyles}
`;
const AvatarRequirements = styled.ul.withConfig({ displayName: "AvatarRequirements", componentId: "sc-1yxr1ts" }) `
    color: #555;
    font-size: 12px;
    list-style-position: inside;
    margin: 10px 0 0;
    padding: 0;
    text-align: center;
`;
const ButtonIcon = styled(SvgIcon).withConfig({ displayName: "ButtonIcon", componentId: "sc-1rkdjaf" }) `
    width: 12px;
    height: 12px;
    margin-right: 8px;
`;
const AlertContainer = styled.div.withConfig({ displayName: "AlertContainer", componentId: "sc-1qfe9m7" }) `
    margin-top: 10px;
`;
