import React, { useEffect, useReducer, useRef } from 'react';
import styled, { createGlobalStyle, css, keyframes } from 'styled-components';
import { useDiscardableEffect } from 'toasterhea';
import gsap from 'gsap';
import { TABLET } from '~/shared/utils/styled';
import { RejectionReason } from '~/utils/exceptions';
const bringIn = keyframes `
    from {
        opacity: 0;
        transform: translateY(1%) translateZ(0) scale(0.98);
    }
    to {
        opacity: 1;
        transform: translateZ(0) scale(1) translateY(0);
    }
`;
const bringOut = keyframes `
    from {
        transform: translateZ(0) scale(1) translateY(0);
    }
    to {
        transform: translateY(1%) translateZ(0) scale(0.98);
    }
`;
const fadeAway = keyframes `
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;
export default function BaseModal({ children, darkBackdrop, onBeforeAbort, onReject, onResolve: _, ...props }) {
    const [dismissed, dismiss] = useReducer(() => true, false);
    const rootRef = useRef(null);
    useDiscardableEffect((discard) => {
        const { current: root } = rootRef;
        root?.addEventListener('animationend', ({ animationName }) => {
            if (animationName === fadeAway.getName()) {
                discard();
            }
        });
        dismiss();
    });
    const wigglyRef = useRef(null);
    const tweenRef = useRef();
    function wiggle() {
        tweenRef.current?.kill();
        tweenRef.current = gsap.to({}, {
            duration: 0.75,
            onUpdate() {
                const { current: wiggly } = wigglyRef;
                if (!wiggly) {
                    return;
                }
                const p = this.progress();
                const intensity = (1 + Math.sin((p * 2 - 0.5) * Math.PI)) * 0.5;
                const wave = 5 * Math.sin(6 * p * Math.PI);
                wiggly.style.transform = `rotate(${intensity * wave}deg)`;
            },
        });
    }
    function close(reason) {
        const beforeAbort = onBeforeAbort?.(reason);
        if (beforeAbort === false) {
            wiggle();
        }
        if (beforeAbort === null || beforeAbort === false) {
            return;
        }
        onReject?.(reason);
    }
    const closeRef = useRef(close);
    closeRef.current = close;
    useEffect(() => {
        function onKeyDown({ key }) {
            if (key === 'Escape') {
                closeRef.current(RejectionReason.EscapeKey);
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);
    return (React.createElement(Root, { ref: rootRef, "$dismissed": dismissed, "$dark": darkBackdrop, ...props },
        React.createElement(NoScrollStyles, null),
        React.createElement(Backdrop, { onMouseDown: () => void close(RejectionReason.Backdrop) }),
        React.createElement(OuterWrap, null,
            React.createElement(InnerWrap, null,
                React.createElement(Pad, null,
                    React.createElement(Interactive, null,
                        React.createElement(AnimatedWrap, { "$dismissed": dismissed },
                            React.createElement(Wigglable, { ref: wigglyRef }, typeof children === 'function'
                                ? children(close)
                                : children))))))));
}
const NoScrollStyles = createGlobalStyle `
    body {
        overflow: hidden !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }
`;
const AnimatedWrap = styled.div.withConfig({ displayName: "AnimatedWrap", componentId: "sc-bpto49" }) `
    animation: 300ms ${({ $dismissed = false }) => ($dismissed ? bringOut : bringIn)}
        ease-in-out 1;
    animation-fill-mode: both;
    backface-visibility: hidden;
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-sdb95p" }) `
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.9);
    height: 100%;
    left: 0;
    line-height: 24px;
    overflow: auto;
    position: fixed;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 1;

    ${({ $dismissed = false }) => $dismissed &&
    css `
            animation: 300ms ${fadeAway} ease-in-out 1;
            animation-fill-mode: both;
        `}

    ${({ $dark = false }) => $dark &&
    css `
            background-color: rgba(50, 50, 50, 0.5);
        `}
`;
const Backdrop = styled.div.withConfig({ displayName: "Backdrop", componentId: "sc-jln15v" }) `
    position: fixed;
    width: 100%;
    height: 100%;
`;
const OuterWrap = styled.div.withConfig({ displayName: "OuterWrap", componentId: "sc-15zn9ew" }) `
    align-items: center;
    color: #323232;
    display: flex;
    height: 100%;
    justify-content: center;
    pointer-events: none;
    position: relative;
`;
const InnerWrap = styled.div.withConfig({ displayName: "InnerWrap", componentId: "sc-vt5was" }) `
    max-height: 100%;
    overflow: visible;
`;
const Pad = styled.div.withConfig({ displayName: "Pad", componentId: "sc-b72d9k" }) `
    padding-top: 40px;
    padding-bottom: 40px;
    padding-top: 64px;
    padding-bottom: 64px;
`;
const Interactive = styled.div.withConfig({ displayName: "Interactive", componentId: "sc-18bns08" }) `
    max-width: 90vw;
    min-width: 12rem;
    pointer-events: auto;
    width: max-content;
`;
const Wigglable = styled.div.withConfig({ displayName: "Wigglable", componentId: "sc-9iiygd" }) `
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.03), 0 1px 2px rgba(0, 0, 0, 0.05),
        0 5px 15px rgba(0, 0, 0, 0.1);
    min-height: 160px;
`;
export const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-1dy83dp" }) `
    align-items: center;
    display: flex;
    height: 80px;
    padding: 0 40px;
    width: 100%;

    ${({ $borderless = false }) => !$borderless &&
    css `
            border-top: 1px solid #f3f3f3;
        `}

    ${({ $spacious = false }) => $spacious &&
    css `
            @media ${TABLET} {
                height: 120px;
            }
        `}

    ${({ $autoHeight = false }) => $autoHeight &&
    css `
            height: auto !important;
        `}
`;
