import React, { useCallback, useState, forwardRef } from 'react';
const FlushHistoryDecorator = (WrappedComponent) => {
    const FlushHistoryDecoratorWrapper = ({ onBlur: onBlurProp, ...props }, ref) => {
        const [blurCount, setBlurCount] = useState(0);
        const onBlur = useCallback((e) => {
            // `blurCount` is used as `key` of the actual control. Changing it replaces the control
            // with a new instance thus the old instance along with its change history gets forgotten.
            setBlurCount((count) => count + 1);
            if (onBlurProp) {
                onBlurProp(e);
            }
        }, [onBlurProp]);
        return React.createElement(WrappedComponent, { ...props, ref: ref, key: blurCount, onBlur: onBlur });
    };
    const FlushHistoryDecoratorWrapperFR = forwardRef(FlushHistoryDecoratorWrapper);
    const OptInFlushHistoryDecorator = ({ flushHistoryOnBlur = false, ...props }, ref) => flushHistoryOnBlur ? (React.createElement(FlushHistoryDecoratorWrapperFR, { ...props, ref: ref })) : (React.createElement(WrappedComponent, { ...props, ref: ref }));
    return forwardRef(OptInFlushHistoryDecorator);
};
export default FlushHistoryDecorator;
