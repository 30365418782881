import { Tooltip } from '@streamr/streamr-layout';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import stringifyObject from 'stringify-object';
import styled, { css } from 'styled-components';
import { CopyButton } from '~/components/CopyButton';
import { SelectField2 } from '~/marketplace/components/SelectField2';
import { formatDateTime } from '~/marketplace/utils/time';
import useCopy from '~/shared/hooks/useCopy';
import { COLORS, MAX_BODY_WIDTH, MEDIUM, TABLET } from '~/shared/utils/styled';
import { truncateStreamName } from '~/shared/utils/text';
import Cell from './Cell';
import Layout from './Layout';
import { ModalStreamSelector } from './ModalStreamSelector';
import Toolbar from './Toolbar';
const formatValue = (data) => typeof data === 'object'
    ? stringifyObject(data, {
        inlineCharacterLimit: Number.POSITIVE_INFINITY,
    })
    : data.toString();
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-zhpb55" }) `
    display: grid;
    transition: 0.2s grid-template-columns;
    grid-template-columns: auto 248px 1fr auto;

    ${({ inspectorFocused }) => !!inspectorFocused &&
    css `
            grid-template-columns: auto 0px 1fr auto;
        `}

    @media ${TABLET} {
        grid-template-columns: auto 1fr 1fr auto;
    }

    @media (min-width: ${MAX_BODY_WIDTH}px) {
        grid-template-columns: auto ${MAX_BODY_WIDTH - 560}px 560px auto;
    }
`;
const LeftFiller = styled.div.withConfig({ displayName: "LeftFiller", componentId: "sc-r7rewq" }) `
    background: #ffffff;
`;
const RightFiller = styled.div.withConfig({ displayName: "RightFiller", componentId: "sc-nqvy0" }) `
    background: #fafafa;
`;
const Inner = styled.div.withConfig({ displayName: "Inner", componentId: "sc-10i1wvf" }) `
    display: grid;
    line-height: 28px;
    padding: 14px 16px;

    > div {
        min-width: 0;
    }
`;
const Row = styled.div.withConfig({ displayName: "Row", componentId: "sc-qgsfj9" }) ``;
const Viewport = styled.div.withConfig({ displayName: "Viewport", componentId: "sc-1ebgoir" }) `
    height: 100%;
    overflow: auto;

    ${Inner} {
        transition: 250ms background-color;
    }

    ${Inner}:hover {
        transition-duration: 25ms;
    }
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1gf9xs1" }) `
    height: 54px;
    width: 100%;
`;
const Side = styled.div.withConfig({ displayName: "Side", componentId: "sc-1fktf15" }) `
    height: 100%;
    overflow: hidden;
    &.no-overflow-desktop {
        @media (${TABLET}) {
            overflow: initial;
        }
    }
`;
const Lhs = styled(Side).withConfig({ displayName: "Lhs", componentId: "sc-mjh5np" }) `
    ${Row} {
        display: grid;
        grid-template-columns: auto 1fr;
        border-bottom: 1px solid ${COLORS.Border};
    }

    ${Inner} {
        grid-template-columns: 224px 1fr;
    }

    ${Viewport} ${Inner}:hover {
        background: #fafafa;
    }

    ${Inner} > div {
        min-width: 0;
    }
`;
const Rhs = styled(Side).withConfig({ displayName: "Rhs", componentId: "sc-1ay3rot" }) `
    background: #fafafa;
    border-left: 1px solid #efefef;

    ${Inner} {
        grid-template-columns: 128px 1fr;
        column-gap: 8px;
        margin: 0 24px;
    }

    ${Viewport} ${Inner}:hover {
        background: #f3f3f3;
    }

    ${Viewport} ${Inner} > div:first-child {
        color: #a3a3a3;
        text-transform: uppercase;
    }

    ${Viewport} ${Inner} {
        border-bottom: 1px solid #efefef;
    }

    @media (${TABLET}) {
        transition: none;

        ${Inner} {
            grid-template-columns: 164px 1fr;
        }
    }
`;
const ToolbarContainer = styled.div.withConfig({ displayName: "ToolbarContainer", componentId: "sc-zk8k14" }) `
    border-bottom: 1px solid ${COLORS.Border};
`;
const tz = moment.tz.guess();
const TooltipTheme = Object.assign({}, Tooltip.BottomTheme, {
    left: 0,
    top: 'auto',
    transform: 'none',
});
const StreamSelectorContainer = styled.div.withConfig({ displayName: "StreamSelectorContainer", componentId: "sc-1490p4j" }) `
    padding: 14px 16px;
    .select-stream-label {
        margin-bottom: 8px;
        font-weight: ${MEDIUM};
    }
`;
const StreamSelector = styled.div.withConfig({ displayName: "StreamSelector", componentId: "sc-1l8jy00" }) `
    align-items: center;
    display: flex;
    gap: 8px;
`;
const DesktopStreamSelector = styled.div.withConfig({ displayName: "DesktopStreamSelector", componentId: "sc-1h7m1f7" }) `
    display: none;
    @media (${TABLET}) {
        display: block;
    }
`;
const MobileStreamSelector = styled.div.withConfig({ displayName: "MobileStreamSelector", componentId: "sc-1027y5w" }) `
    background-color: ${COLORS.secondary};
    padding: 24px;
    @media (${TABLET}) {
        display: none;
    }
    .label {
        font-weight: ${MEDIUM};
        margin-bottom: 8px;
    }
    .selector {
        align-items: center;
        display: flex;
        gap: 8px;
        margin-bottom: 5px;
    }
`;
const DesktopToolbar = styled(Toolbar).withConfig({ displayName: "DesktopToolbar", componentId: "sc-eklfxy" }) `
    display: none;
    @media (${TABLET}) {
        display: block;
    }
`;
const UnstyledFeed = ({ className, errorComponent = null, inspectorFocused = false, streamData, streamLoaded, onPartitionChange, onSettingsButtonClick, onStreamChange, partition, partitions = [], streamId, streamIds = [streamId], }) => {
    const [datapoint, setDatapoint] = useState();
    useEffect(() => {
        setDatapoint(undefined);
    }, [streamId]);
    const { metadata, data } = datapoint || { metadata: null, data: null };
    const selectedMsgId = metadata && JSON.stringify(metadata.messageId);
    const selectedTimestamp = metadata && metadata.timestamp;
    const selection = Object.entries(data || {});
    const { copy } = useCopy();
    const rowRef = useRef(null);
    const copyText = 'ontouchstart' in window ? 'Tap to copy' : 'Copy';
    const streamOptions = useMemo(() => streamIds.map((id) => ({ value: id, label: truncateStreamName(id) })), [streamIds]);
    const onCopyClick = (value) => (e) => {
        const prevRow = rowRef.current;
        rowRef.current = e.currentTarget;
        // Using touch screen and "focused" row does not much current row? Do nothing.
        if ('ontouchstart' in window && prevRow !== e.currentTarget) {
            return;
        }
        // Value is not a string? Do nothing.
        if (typeof value !== 'string') {
            return;
        }
        copy(value, {
            toastMessage: 'Field data copied to clipboard',
        });
    };
    return (React.createElement("div", { className: className },
        streamOptions.length > 1 && (React.createElement(MobileStreamSelector, null,
            React.createElement("p", { className: 'label' }, "Select a Stream"),
            React.createElement("div", { className: 'selector' },
                React.createElement(ModalStreamSelector, { streamIds: streamIds, selectedStream: streamId, onChange: onStreamChange }),
                React.createElement(CopyButton, { value: streamId })))),
        React.createElement(Container, { inspectorFocused: inspectorFocused },
            React.createElement(LeftFiller, null),
            React.createElement(Lhs, { className: 'no-overflow-desktop' }, (streamOptions.length > 1 ||
                (partitions != null && partitions.length > 1)) && (React.createElement(ToolbarContainer, null,
                streamOptions.length > 1 && (React.createElement(DesktopStreamSelector, null,
                    React.createElement(StreamSelectorContainer, null,
                        React.createElement("p", { className: 'select-stream-label' }, "Select a stream"),
                        React.createElement(StreamSelector, null,
                            React.createElement(SelectField2, { placeholder: 'Select Stream', options: streamOptions, onChange: onStreamChange, isClearable: false, value: streamId }),
                            React.createElement(CopyButton, { value: streamId }))))),
                React.createElement(DesktopToolbar, { onPartitionChange: onPartitionChange, onSettingsButtonClick: onSettingsButtonClick, partition: partition, partitions: partitions || [], streamId: streamId })))),
            React.createElement(Rhs, null),
            React.createElement(RightFiller, null)),
        React.createElement(Container, { inspectorFocused: inspectorFocused },
            React.createElement(LeftFiller, null),
            React.createElement(Lhs, null,
                React.createElement(Header, null,
                    React.createElement(Row, null,
                        React.createElement(Layout.Pusher, null),
                        React.createElement(Inner, null,
                            React.createElement(Cell, { as: "strong" }, "Timestamp"),
                            React.createElement(Cell, { as: "strong" }, "Data")))),
                React.createElement(Viewport, null, !!streamLoaded &&
                    (streamData || []).map((d) => {
                        if (!d) {
                            return null;
                        }
                        const msgId = JSON.stringify(d.metadata.messageId);
                        const Tag = selectedMsgId === msgId ? 'strong' : 'span';
                        return (React.createElement(Row, { key: msgId, onClick: () => setDatapoint((current) => (d === current ? undefined : d)) },
                            React.createElement(Layout.Pusher, null),
                            React.createElement(Inner, null,
                                React.createElement(Cell, { as: Tag }, formatDateTime(d.metadata && d.metadata.timestamp, tz)),
                                React.createElement(Cell, { as: Tag }, JSON.stringify(d.data)))));
                    }))),
            React.createElement(Rhs, null,
                React.createElement(Header, null,
                    React.createElement(Row, null,
                        React.createElement(Inner, null,
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(Cell, null, "Inspector")))))),
                React.createElement(Viewport, null,
                    !!selectedTimestamp && (React.createElement(Row, null,
                        React.createElement(Inner, { as: Tooltip.Parent, onClick: onCopyClick(formatDateTime(selectedTimestamp, tz)) },
                            React.createElement("div", null, "Timestamp"),
                            React.createElement("div", null,
                                React.createElement(Tooltip, { value: copyText, placement: TooltipTheme },
                                    React.createElement(Cell, null, formatDateTime(selectedTimestamp, tz))))))),
                    selection.map(([k, v]) => {
                        const value = formatValue(v);
                        return (React.createElement(Row, { key: `${k}${value}` },
                            React.createElement(Inner, { as: Tooltip.Parent, onClick: onCopyClick(value) },
                                React.createElement("div", null,
                                    React.createElement(Cell, null, k)),
                                React.createElement("div", null,
                                    React.createElement(Tooltip, { value: copyText, placement: TooltipTheme },
                                        React.createElement(Cell, null, value))))));
                    }),
                    errorComponent)),
            React.createElement(RightFiller, null))));
};
const Feed = styled(UnstyledFeed).withConfig({ displayName: "Feed", componentId: "sc-11lqhzt" }) `
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    @media (${TABLET}) {
        padding-bottom: 0;
    }

    ${Tooltip.Root} {
        display: inline;
        line-height: inherit;
    }
`;
Object.assign(Feed, {
    Cell,
    Lhs,
    Rhs,
    Row,
});
export default Feed;
