import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import ModalDialog from '~/shared/components/ModalDialog';
import ModalPortal from '~/shared/components/ModalPortal';
import useModal from '~/shared/hooks/useModal';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import { truncateStreamName } from '~/shared/utils/text';
import SvgIcon from '../SvgIcon';
const ModalContainer = styled.div.withConfig({ displayName: "ModalContainer", componentId: "sc-1twcija" }) `
    background: white;
    color: ${COLORS.primary};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-1fyo11n" }) `
    padding: 44px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const HeaderTitle = styled.p.withConfig({ displayName: "HeaderTitle", componentId: "sc-17ycym8" }) `
    font-size: 24px;
    margin: 0;
`;
const StreamsList = styled.ul.withConfig({ displayName: "StreamsList", componentId: "sc-1szul2b" }) `
    width: 100%;
    list-style-type: none;
    padding: 0;

    li {
        font-size: 16px;
        padding: 8px 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid ${COLORS.Border};
        &:last-of-type {
            border-bottom: 1px solid ${COLORS.Border};
        }

        &.active {
            background-color: ${COLORS.primaryLight};
            color: white;
        }
    }
`;
const StreamName = styled.div.withConfig({ displayName: "StreamName", componentId: "sc-m1jfnf" }) `
    background-color: white;
    font-size: 16px;
    font-weight: ${MEDIUM};
    line-height: 40px;
    padding: 0 12px;
`;
const SelectorWrapper = styled.div.withConfig({ displayName: "SelectorWrapper", componentId: "sc-167vg3e" }) `
    display: flex;
    align-items: center;
`;
export const ModalStreamSelector = (props) => {
    const { api, isOpen } = useModal('streamSelectorModal');
    const openModal = useCallback(() => {
        api.open();
    }, [api]);
    return (React.createElement("div", null,
        React.createElement(SelectorWrapper, null,
            React.createElement(StreamName, { onClick: openModal }, props.selectedStream && truncateStreamName(props.selectedStream))),
        isOpen && React.createElement(StreamSelectorModal, { api: api, ...props })));
};
const StreamSelectorModal = ({ api, streamIds, selectedStream, onChange, }) => {
    const onClose = useCallback(() => {
        api.close();
    }, [api]);
    return (React.createElement(ModalPortal, null,
        React.createElement(ModalDialog, { onClose: onClose, fullpage: true, noScroll: false, closeOnEsc: true },
            React.createElement(ModalContainer, null,
                React.createElement(Header, null,
                    React.createElement(HeaderTitle, null, "Select a stream"),
                    React.createElement(Button, { onClick: onClose, kind: 'secondary' }, "Done")),
                React.createElement(StreamsList, null, streamIds.map((streamId, index) => {
                    return (React.createElement("li", { key: index, className: streamId === selectedStream ? 'active' : '', onClick: () => {
                            onChange(streamId);
                            onClose();
                        } },
                        React.createElement("span", null, truncateStreamName(streamId)),
                        streamId === selectedStream && (React.createElement(SvgIcon, { name: 'checkMarkMono' }))));
                }))))));
};
