import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Reason, useDiscardableEffect } from 'toasterhea';
import { COLORS } from '~/shared/utils/styled';
import SvgIcon from '~/shared/components/SvgIcon';
import { RejectionReason } from '~/utils/exceptions';
export function isAbandonment(e) {
    return (e === RejectionReason.BackButton ||
        e === RejectionReason.Backdrop ||
        e === RejectionReason.CancelButton ||
        e === RejectionReason.CloseButton ||
        e === RejectionReason.EscapeKey ||
        e === Reason.Host ||
        e === Reason.Unmount ||
        e === Reason.Update);
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1915xpt" }) `
    background: #f5f5f5;
    color: #323232;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
`;
const Column = styled.div.withConfig({ displayName: "Column", componentId: "sc-irdclj" }) `
    margin: 0 auto;
    width: 528px;
`;
const Nav = styled.div.withConfig({ displayName: "Nav", componentId: "sc-mli5zi" }) `
    box-sizing: content-box;
    height: 64px;
    padding: 48px 0;

    button {
        appearance: none;
        background: none;
        border: 0;
        padding: 0;
        width: 64px;
        height: 100%;
        display: flex;
        align-items: center;
        transform: translateX(-31%);
    }

    button svg {
        display: block;
        margin: 0 auto;
    }

    & + h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        margin: 0 0 20px;
    }
`;
const BackButtonIcon = styled(SvgIcon).withConfig({ displayName: "BackButtonIcon", componentId: "sc-pp1l1i" }) `
    color: ${COLORS.primaryLight};
`;
export const Actions = styled.div.withConfig({ displayName: "Actions", componentId: "sc-1whwy6k" }) `
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: flex-end;
    margin-top: 32px;

    button {
        min-width: 120px;
    }
`;
export default function ProjectModal({ backable = false, children, closeOnEscape = false, onReject, title, }) {
    useDiscardableEffect((discard) => void setTimeout(discard));
    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === 'Escape' && closeOnEscape) {
                onReject?.(RejectionReason.EscapeKey);
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onReject, closeOnEscape]);
    return (React.createElement(Root, null,
        React.createElement(Column, null,
            React.createElement(Nav, null, backable && (React.createElement("button", { type: "button", onClick: () => void onReject?.(RejectionReason.BackButton) },
                React.createElement(BackButtonIcon, { name: "backArrow" })))),
            !!title && React.createElement("h2", null,
                "\u200C",
                title),
            React.createElement("div", null, children))));
}
