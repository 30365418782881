import { hexToNumber } from 'web3-utils';
import { GetDataUnionsOwnedByDocument, } from '~/generated/gql/du';
import getClientConfig from '~/getters/getClientConfig';
import { getDataUnionGraphClient } from '~/getters/getGraphClient';
import { getWalletAccount, getWalletProvider } from '~/shared/stores/wallet';
import { getChainConfig, getChainConfigExtension } from '~/utils/chains';
import { getContractAddress } from '~/utils/contracts';
export async function getDataUnionsOwnedByInChain(account, chainId) {
    const client = getDataUnionGraphClient(chainId);
    const { data = { dataUnions: [] } } = await client.query({
        query: GetDataUnionsOwnedByDocument,
        variables: {
            owner: account.toLowerCase(),
        },
        fetchPolicy: 'network-only',
    });
    return data.dataUnions.map((du) => ({
        ...du,
        chainId,
    }));
}
export async function getDataUnionClient(chainId) {
    const provider = await getWalletProvider();
    const config = getChainConfig(chainId);
    const { dataUnionJoinServerUrl: joinServerUrl } = getChainConfigExtension(chainId);
    const providerUrl = config.rpcEndpoints.find((rpc) => rpc.url.startsWith('http'))?.url;
    const providerChainId = hexToNumber(provider.chainId);
    const isProviderInCorrectChain = providerChainId === chainId;
    const account = await getWalletAccount();
    if (!account) {
        throw new Error('No wallet connected');
    }
    const isInCorrectChainAndUnlocked = isProviderInCorrectChain;
    /**
     * Data unions client has to be brought to ethers v6.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const clientConfig = getClientConfig(chainId, {
        auth: {
            // If MetaMask is in right chain, use it to enable signing
            ethereum: isInCorrectChainAndUnlocked ? provider : undefined,
            // Otherwise use a throwaway private key to authenticate and allow read-only mode
            privateKey: !isInCorrectChainAndUnlocked
                ? '531479d5645596f264e7e3cbe80c4a52a505d60fad45193d1f6b8e4724bf0304'
                : undefined,
        },
        network: {
            chainId,
            rpcs: [
                {
                    url: providerUrl,
                    timeout: 120 * 1000,
                },
            ],
        },
        dataUnion: {
            factoryAddress: getContractAddress('dataUnionFactory', chainId),
        },
        ...(joinServerUrl
            ? {
                joinServerUrl,
            }
            : {}),
    });
    throw new Error('Not implemented');
}
export async function getDataUnion(dataUnionId, chainId) {
    const dataUnion = await (await getDataUnionClient(chainId)).getDataUnion(dataUnionId);
    if (!dataUnion) {
        throw new Error('Data Union not found');
    }
    return dataUnion;
}
async function getDataUnionAdminFee(dataUnionId, chainId) {
    return (await getDataUnion(dataUnionId, chainId)).getAdminFee();
}
export function getDataUnionAdminFeeForSalePoint(salePoint) {
    const { beneficiary: dataUnionId, domainId: chainId } = salePoint;
    return getDataUnionAdminFee(dataUnionId, chainId);
}
