import React, { useEffect, useMemo } from 'react';
import { Link, Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { BehindBlockErrorDisplay } from '~/components/BehindBlockErrorDisplay';
import { Button } from '~/components/Button';
import ColoredBox from '~/components/ColoredBox';
import Layout, { LayoutColumn } from '~/components/Layout';
import NetworkPageSegment, { Pad, SegmentGrid } from '~/components/NetworkPageSegment';
import { QueriedStreamsTable } from '~/components/QueriedStreamsTable';
import { TermsOfUse } from '~/components/TermsOfUse';
import { useInitialBehindIndexError, useLatestBehindBlockError, useRefetchQueryBehindIndexEffect, } from '~/hooks';
import { useProjectByIdQuery } from '~/hooks/projects';
import { useStreamsQuery } from '~/hooks/streams';
import { useTableOrder } from '~/hooks/useTableOrder';
import ProjectHero from '~/marketplace/containers/ProjectPage/Hero/ProjectHero2';
import NotFoundPage from '~/pages/NotFoundPage';
import { getEmptyParsedProject } from '~/parsers/ProjectParser';
import { DetailsPageHeader } from '~/shared/components/DetailsPageHeader';
import LoadingIndicator from '~/shared/components/LoadingIndicator';
import Segment from '~/shared/components/Segment';
import { StreamConnect } from '~/shared/components/StreamConnect';
import { StreamPreview } from '~/shared/components/StreamPreview';
import SvgIcon from '~/shared/components/SvgIcon';
import { ProjectPermission, useCurrentProjectAbility, } from '~/shared/stores/projectAbilities';
import { ProjectType } from '~/shared/types';
import { ProjectDraft, preselectSalePoint, useIsAccessibleByCurrentWallet, } from '~/stores/projectDraft';
import { isProjectType } from '~/utils';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCurrentChainSymbolicName } from '~/utils/chains';
import { AccessManifest } from './AccessManifest';
import GetAccess from './GetAccess';
import ProjectEditorPage from './ProjectEditorPage';
import ProjectLinkTabs from './ProjectLinkTabs';
export function ProjectDraftPage() {
    const { id: projectId } = useParams();
    const draftId = ProjectDraft.useInitDraft(projectId);
    return (React.createElement(ProjectDraft.DraftContext.Provider, { value: draftId },
        React.createElement(Outlet, null)));
}
export function NewProjectPage() {
    const type = useSearchParams()[0].get('type');
    const projectType = isProjectType(type) ? type : ProjectType.OpenData;
    const project = useMemo(() => {
        const project = getEmptyParsedProject({
            type: projectType,
        });
        preselectSalePoint(project);
        return project;
    }, [projectType]);
    const draftId = ProjectDraft.useDraftId();
    const { assign } = ProjectDraft.useDraftStore();
    const initialized = ProjectDraft.useDraft()?.initialized || false;
    useEffect(function assignProjectToDraft() {
        if (initialized) {
            assign(draftId, project);
        }
    }, [assign, draftId, initialized, project]);
    return React.createElement(ProjectEditorPage, null);
}
export function ExistingProjectPageWrap() {
    const { id: projectId } = useParams();
    const projectQuery = useProjectByIdQuery(projectId);
    const initialBehindBlockError = useInitialBehindIndexError(projectQuery, [projectId]);
    useRefetchQueryBehindIndexEffect(projectQuery);
    const behindBlockError = useLatestBehindBlockError(projectQuery);
    const { data: project = null } = projectQuery;
    const isFetching = projectQuery.isLoading || projectQuery.isFetching || !!behindBlockError;
    const draftId = ProjectDraft.useDraftId();
    const { assign } = ProjectDraft.useDraftStore();
    const initialized = ProjectDraft.useDraft()?.initialized || false;
    useEffect(function assignProjectToDraft() {
        if (initialized) {
            assign(draftId, project);
        }
    }, [assign, draftId, initialized, project]);
    const placeholder = behindBlockError ? (React.createElement(Layout, null,
        React.createElement(LayoutColumn, null,
            React.createElement(BehindBlockErrorDisplay, { latest: behindBlockError, initial: initialBehindBlockError || undefined })))) : isFetching ? (React.createElement(Layout, null,
        React.createElement(LoadingIndicator, { loading: true }))) : (React.createElement(NotFoundPage, null));
    if (!project) {
        return placeholder;
    }
    return React.createElement(Outlet, null);
}
export function ProjectOverviewPage() {
    const project = ProjectDraft.useEntity();
    if (!project) {
        return null;
    }
    const { id, name, description, termsOfUse, imageUrl, streams, type, contact, salePoints, } = project;
    if (!id) {
        return null;
    }
    const [firstSalePoint = undefined, ...otherSalePoints] = Object.values(salePoints).filter((salePoint) => salePoint?.enabled);
    return (React.createElement(LayoutColumn, null,
        React.createElement(SegmentGrid, null,
            React.createElement(Segment, null,
                React.createElement(ProjectHero, { contact: contact, description: description, imageUrl: imageUrl || undefined, name: name })),
            firstSalePoint && (React.createElement(Segment, null,
                React.createElement(ColoredBox, null,
                    React.createElement(Pad, null,
                        React.createElement(AccessManifest, { projectId: id, projectType: type, firstSalePoint: firstSalePoint, otherSalePoints: otherSalePoints }))))),
            React.createElement(NetworkPageSegment, { title: "Streams" },
                React.createElement(StreamTable, { streamIds: streams })),
            React.createElement(NetworkPageSegment, { title: "Terms and conditions" },
                React.createElement(Pad, null,
                    React.createElement(TermsOfUse, { ...termsOfUse }))))));
}
export function ProjectConnectPage() {
    const hasAccess = useIsAccessibleByCurrentWallet();
    const project = ProjectDraft.useEntity();
    if (!project) {
        return null;
    }
    const { id, name, type, streams, salePoints } = project;
    if (!id) {
        return null;
    }
    return (React.createElement(LayoutColumn, null,
        React.createElement(SegmentGrid, null,
            React.createElement(Segment, null, hasAccess ? (React.createElement(ColoredBox, null,
                React.createElement(Pad, null,
                    React.createElement(StreamConnect, { streams: streams })))) : (React.createElement(GetAccess, { projectId: id, projectName: name, projectType: type, salePoints: Object.values(salePoints).filter((salePoint) => salePoint?.enabled) }))))));
}
export function ProjectLiveDataPage() {
    const hasAccess = useIsAccessibleByCurrentWallet();
    const project = ProjectDraft.useEntity();
    if (!project) {
        return null;
    }
    const { id, name, type, streams, salePoints } = project;
    if (!id) {
        return null;
    }
    return hasAccess ? (React.createElement(StreamPreview, { streamsList: streams })) : (React.createElement(LayoutColumn, null,
        React.createElement(SegmentGrid, null,
            React.createElement(Segment, null,
                React.createElement(GetAccess, { projectId: id, projectName: name, projectType: type, salePoints: Object.values(salePoints).filter((salePoint) => salePoint?.enabled) })))));
}
export function ProjectTabbedPage() {
    const { id = '', name = '', creator = '' } = ProjectDraft.useEntity() || {};
    const busy = ProjectDraft.useIsDraftBusy();
    const canEdit = useCurrentProjectAbility(ProjectPermission.Edit);
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(Layout, { pageTitle: name },
        React.createElement(DetailsPageHeader, { backButtonLink: R.projects(routeOptions(chainName)), pageTitle: React.createElement(PageTitleContainer, null,
                React.createElement(ProjectTitle, null,
                    name,
                    !!creator && (React.createElement(React.Fragment, null,
                        React.createElement(Separator, null, " by "),
                        React.createElement("strong", null, creator)))),
                canEdit && (React.createElement(EditButton, { as: Link, to: R.projectEdit(id, routeOptions(chainName)), kind: "secondary", size: "mini" },
                    React.createElement(SvgIcon, { name: "pencilFull" })))), rightComponent: React.createElement(ProjectLinkTabs, { projectId: id }) }),
        React.createElement(LoadingIndicator, { loading: busy }),
        React.createElement(Outlet, null)));
}
/**
 * @todo Taken care of in `app`, no? Double-check & remove.
 */
export function ProjectIndexRedirect() {
    const { id = '' } = useParams();
    return (React.createElement(Navigate, { to: {
            pathname: R.project(id),
            search: window.location.search,
        }, replace: true }));
}
function StreamTable({ streamIds }) {
    const { orderBy = 'mps', orderDirection = 'desc', setOrder, } = useTableOrder();
    const streamsQuery = useStreamsQuery({
        orderBy,
        orderDirection,
        streamIds,
    });
    return (React.createElement(QueriedStreamsTable, { onOrderChange: setOrder, orderBy: orderBy, orderDirection: orderDirection, query: streamsQuery }));
}
const PageTitleContainer = styled.div.withConfig({ displayName: "PageTitleContainer", componentId: "sc-1wt590m" }) `
    align-items: center;
    display: flex;
`;
const EditButton = styled(Button).withConfig({ displayName: "EditButton", componentId: "sc-oiyq52" }) `
    border-radius: 100%;
    height: 32px;
    margin-left: 10px;
    width: 32px;
`;
const ProjectTitle = styled.span.withConfig({ displayName: "ProjectTitle", componentId: "sc-1cm0amj" }) `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const Separator = styled.span.withConfig({ displayName: "Separator", componentId: "sc-krz2kb" }) `
    white-space: pre-wrap;
`;
