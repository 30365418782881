import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import LightModal from '~/modals/LightModal';
import { Route as R } from '~/utils/routes';
import SvgIcon from '~/shared/components/SvgIcon';
import { DESKTOP, TABLET } from '~/shared/utils/styled';
import { Layer } from '~/utils/Layer';
export const DisclaimerBar = () => {
    const { pathname } = useLocation();
    // Show disclaimer only for Overview, Sponsorships, Sponsorship, Operators, Operator pages
    if (!pathname.includes(R.network())) {
        return null;
    }
    return (React.createElement(Root, null,
        React.createElement(SvgIcon, { name: "infoBadge" }),
        React.createElement("div", null,
            "Streamr risk disclaimer: The sponsorship, staking & delegation smart contracts are unproven technology, participate at your own risk.",
            ' ',
            React.createElement("a", { href: R.docs('/streamr-testnets/testnets'), target: "_blank", rel: "noopener noreferrer", onClick: async (e) => {
                    e.preventDefault();
                    try {
                        await toaster(LightModal, Layer.Modal).pop({
                            title: 'Disclaimer',
                            children: (React.createElement("p", null,
                                "The Streamr 1.0 testnets involve new, unproven technology including smart contracts and new protocols, which contain inherent risks. Users should conduct thorough research before engaging, and participate at their own risk. Participation can result in irreversible loss of funds. Exercise caution. Never share your private key with anyone.",
                                ' ',
                                React.createElement("a", { href: R.docs('/streamr-testnets/testnets'), target: "_blank", rel: "noopener noreferrer" }, "Learn more"),
                                ".")),
                        });
                    }
                    catch (e) { }
                } }, "Learn more"),
            ".")));
};
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1n7mz3b" }) `
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    padding: 12px 72px 12px 40px;
    align-items: center;
    justify-content: left;
    color: white;
    background: #323232;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    padding: 12px 72px 12px 24px;

    @media (${TABLET}) {
        padding: 12px 72px 12px 24px;
    }

    @media (${DESKTOP}) {
        padding: 12px 72px 12px 40px;
    }

    // Make it stick to top
    position: sticky;
    top: 0;
    z-index: 1000;

    & > svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        & circle {
            fill: #525252;
        }
    }

    & a {
        color: inherit !important;
        text-decoration: underline !important;
    }
`;
