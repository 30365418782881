import { gql } from '@apollo/client';
export var Aggregation_Interval;
(function (Aggregation_Interval) {
    Aggregation_Interval["Day"] = "day";
    Aggregation_Interval["Hour"] = "hour";
})(Aggregation_Interval || (Aggregation_Interval = {}));
export var BucketType;
(function (BucketType) {
    BucketType["Day"] = "DAY";
    BucketType["Hour"] = "HOUR";
})(BucketType || (BucketType = {}));
export var DataUnionStatsBucket_OrderBy;
(function (DataUnionStatsBucket_OrderBy) {
    DataUnionStatsBucket_OrderBy["DataUnion"] = "dataUnion";
    DataUnionStatsBucket_OrderBy["DataUnionCreationDate"] = "dataUnion__creationDate";
    DataUnionStatsBucket_OrderBy["DataUnionId"] = "dataUnion__id";
    DataUnionStatsBucket_OrderBy["DataUnionMemberCount"] = "dataUnion__memberCount";
    DataUnionStatsBucket_OrderBy["DataUnionOwner"] = "dataUnion__owner";
    DataUnionStatsBucket_OrderBy["DataUnionRevenueWei"] = "dataUnion__revenueWei";
    DataUnionStatsBucket_OrderBy["DataUnionTotalWeight"] = "dataUnion__totalWeight";
    DataUnionStatsBucket_OrderBy["EndDate"] = "endDate";
    DataUnionStatsBucket_OrderBy["Id"] = "id";
    DataUnionStatsBucket_OrderBy["MemberCountAtStart"] = "memberCountAtStart";
    DataUnionStatsBucket_OrderBy["MemberCountChange"] = "memberCountChange";
    DataUnionStatsBucket_OrderBy["RevenueAtStartWei"] = "revenueAtStartWei";
    DataUnionStatsBucket_OrderBy["RevenueChangeWei"] = "revenueChangeWei";
    DataUnionStatsBucket_OrderBy["StartDate"] = "startDate";
    DataUnionStatsBucket_OrderBy["TotalWeightAtStart"] = "totalWeightAtStart";
    DataUnionStatsBucket_OrderBy["TotalWeightChange"] = "totalWeightChange";
    DataUnionStatsBucket_OrderBy["Type"] = "type";
})(DataUnionStatsBucket_OrderBy || (DataUnionStatsBucket_OrderBy = {}));
export var DataUnion_OrderBy;
(function (DataUnion_OrderBy) {
    DataUnion_OrderBy["CreationDate"] = "creationDate";
    DataUnion_OrderBy["Id"] = "id";
    DataUnion_OrderBy["MemberCount"] = "memberCount";
    DataUnion_OrderBy["Members"] = "members";
    DataUnion_OrderBy["Owner"] = "owner";
    DataUnion_OrderBy["RevenueWei"] = "revenueWei";
    DataUnion_OrderBy["TotalWeight"] = "totalWeight";
})(DataUnion_OrderBy || (DataUnion_OrderBy = {}));
export var MemberStatus;
(function (MemberStatus) {
    MemberStatus["Active"] = "ACTIVE";
    MemberStatus["Inactive"] = "INACTIVE";
    MemberStatus["None"] = "NONE";
})(MemberStatus || (MemberStatus = {}));
export var Member_OrderBy;
(function (Member_OrderBy) {
    Member_OrderBy["Address"] = "address";
    Member_OrderBy["DataUnion"] = "dataUnion";
    Member_OrderBy["DataUnionCreationDate"] = "dataUnion__creationDate";
    Member_OrderBy["DataUnionId"] = "dataUnion__id";
    Member_OrderBy["DataUnionMemberCount"] = "dataUnion__memberCount";
    Member_OrderBy["DataUnionOwner"] = "dataUnion__owner";
    Member_OrderBy["DataUnionRevenueWei"] = "dataUnion__revenueWei";
    Member_OrderBy["DataUnionTotalWeight"] = "dataUnion__totalWeight";
    Member_OrderBy["Id"] = "id";
    Member_OrderBy["JoinDate"] = "joinDate";
    Member_OrderBy["Status"] = "status";
    Member_OrderBy["Weight"] = "weight";
})(Member_OrderBy || (Member_OrderBy = {}));
/** Defines the order direction, either ascending or descending */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "asc";
    OrderDirection["Desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
export var RevenueEvent_OrderBy;
(function (RevenueEvent_OrderBy) {
    RevenueEvent_OrderBy["AmountWei"] = "amountWei";
    RevenueEvent_OrderBy["DataUnion"] = "dataUnion";
    RevenueEvent_OrderBy["DataUnionCreationDate"] = "dataUnion__creationDate";
    RevenueEvent_OrderBy["DataUnionId"] = "dataUnion__id";
    RevenueEvent_OrderBy["DataUnionMemberCount"] = "dataUnion__memberCount";
    RevenueEvent_OrderBy["DataUnionOwner"] = "dataUnion__owner";
    RevenueEvent_OrderBy["DataUnionRevenueWei"] = "dataUnion__revenueWei";
    RevenueEvent_OrderBy["DataUnionTotalWeight"] = "dataUnion__totalWeight";
    RevenueEvent_OrderBy["Date"] = "date";
    RevenueEvent_OrderBy["Id"] = "id";
})(RevenueEvent_OrderBy || (RevenueEvent_OrderBy = {}));
export var _SubgraphErrorPolicy_;
(function (_SubgraphErrorPolicy_) {
    /** Data will be returned even if the subgraph has indexing errors */
    _SubgraphErrorPolicy_["Allow"] = "allow";
    /** If the subgraph has indexing errors, data will be omitted. The default. */
    _SubgraphErrorPolicy_["Deny"] = "deny";
})(_SubgraphErrorPolicy_ || (_SubgraphErrorPolicy_ = {}));
export const DataUnionFieldsFragmentDoc = gql `
    fragment DataUnionFields on DataUnion {
  id
  owner
  memberCount
  revenueWei
  creationDate
}
    `;
export const GetDataUnionsByIdDocument = gql `
    query getDataUnionsById($id: ID!) {
  dataUnions(where: {id: $id}) {
    ...DataUnionFields
  }
}
    ${DataUnionFieldsFragmentDoc}`;
export const GetDataUnionsOwnedByDocument = gql `
    query getDataUnionsOwnedBy($owner: String!) {
  dataUnions(where: {owner: $owner}) {
    ...DataUnionFields
  }
}
    ${DataUnionFieldsFragmentDoc}`;
