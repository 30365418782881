import React from 'react';
import styled from 'styled-components';
import { COLORS } from '~/shared/utils/styled';
export function Toggle({ id, value, onChange, ...rest }) {
    return (React.createElement(Root, { ...rest },
        React.createElement(Input, { type: "checkbox", id: id, checked: value, onChange: (e) => {
                onChange(e.target.checked);
            } }),
        React.createElement(Label, { htmlFor: id })));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-ytbvz9" }) `
    display: flex;
`;
const Label = styled.label.withConfig({ displayName: "Label", componentId: "sc-rz0pbf" }) `
    display: inline-block;
    width: 32px;
    height: 20px;
    background-color: #78788029;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 50%;
        transition: transform 0.3s;
    }
`;
const Input = styled.input.withConfig({ displayName: "Input", componentId: "sc-1wrf7bs" }) `
    display: none;

    &:checked + ${Label} {
        background-color: ${COLORS.link};
    }

    &:checked + ${Label}::before {
        transform: translateX(12px);
    }
`;
