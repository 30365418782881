import React, { useState } from 'react';
import { toaster } from 'toasterhea';
import { SponsorshipDecimals } from '~/components/Decimals';
import { SponsorshipDisclaimer } from '~/components/SponsorshipDisclaimer';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { StreamIdDropdown } from '~/components/StreamIdDropdown';
import { DayInSeconds } from '~/consts';
import { MinNumberOfOperatorsParser, isValidCreateSponsorshipForm, } from '~/forms/createSponsorshipForm';
import { useConfigValueFromChain, useMediaQuery } from '~/hooks';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import FormModal, { ErrorLabel, ErrorWrap, FieldWrap, Group, GroupHeadline, Hint, Section, SectionHeadline, TextAppendix, TextInput, WingedLabelWrap, } from '~/modals/FormModal';
import { createSponsorship } from '~/services/sponsorships';
import { checkIfStreamExists } from '~/services/streams';
import Label from '~/shared/components/Ui/Label';
import Toast from '~/shared/toasts/Toast';
import { waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { toBigInt } from '~/utils/bn';
import { RejectionReason, isRejectionReason, isTransactionRejection, } from '~/utils/exceptions';
import { getSponsorshipExtensionInDays } from '~/utils/sponsorships';
import { errorToast } from '~/utils/toast';
const streamNotFoundToaster = toaster(Toast, Layer.Toast);
function CreateSponsorshipModal({ balance, chainId, streamId: streamIdProp = '', onResolve, ...props }) {
    const [busy, setBusy] = useState(false);
    const [confirmState, setConfirmState] = useState(false);
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    const [streamId, setStreamId] = useState(streamIdProp);
    const [rawInitialAmount, setRawInitialAmount] = useState('');
    const initialAmount = toBigInt(rawInitialAmount || 0, decimals);
    const [rawDailyPayoutRate, setRawDailyPayoutRate] = useState('');
    const dailyPayoutRate = toBigInt(rawDailyPayoutRate || 0, decimals);
    const [rawMinStakeDurationInDays, setRawMinStakeDurationInDays] = useState('');
    const minStakeDuration = Math.max(0, Number(rawMinStakeDurationInDays) || 0);
    const [rawMinNumberOfOperators, setRawMinNumberOfOperators] = useState('1');
    const minNumberOfOperators = Math.max(0, Number(rawMinNumberOfOperators) || 0);
    const backdropDismissable = streamId === streamIdProp &&
        initialAmount !== 0n &&
        dailyPayoutRate !== 0n &&
        minStakeDuration === 0 &&
        minNumberOfOperators === 1;
    const extensionInDays = getSponsorshipExtensionInDays(initialAmount, dailyPayoutRate);
    const insufficientFunds = initialAmount > balance;
    const tooLowOperatorCount = !MinNumberOfOperatorsParser.safeParse(minNumberOfOperators).success;
    const formData = {
        streamId,
        initialAmount,
        dailyPayoutRate,
        minStakeDuration,
        minNumberOfOperators,
    };
    const canSubmit = !insufficientFunds && confirmState && isValidCreateSponsorshipForm(formData);
    const invalidMinStakeDuration = initialAmount > 0n && dailyPayoutRate > 0n && extensionInDays < minStakeDuration;
    const maxPenaltyPeriodInDays = useConfigValueFromChain('maxPenaltyPeriodSeconds', (value) => Number(value) * DayInSeconds);
    const tooLongMinStakeDuration = !!maxPenaltyPeriodInDays && minStakeDuration > maxPenaltyPeriodInDays;
    const limitedSpace = useMediaQuery('screen and (max-width: 460px)');
    return (React.createElement(FormModal, { ...props, title: "Create Sponsorship", canSubmit: canSubmit && !busy, submitLabel: "Create", submitting: busy, onBeforeAbort: (reason) => !busy && (backdropDismissable || reason !== RejectionReason.Backdrop), onSubmit: async () => {
            if (!canSubmit) {
                return;
            }
            setBusy(true);
            try {
                if (!(await checkIfStreamExists(chainId, streamId))) {
                    errorToast({ title: 'Stream does not exist' }, streamNotFoundToaster);
                    return;
                }
                let blockNumber = 0;
                const sponsorshipId = await createSponsorship(chainId, formData, {
                    onReceipt: ({ blockNumber: blockNo }) => {
                        blockNumber = blockNo;
                        return waitForIndexedBlock(chainId, blockNo);
                    },
                });
                onResolve?.({
                    sponsorshipId,
                    streamId: streamIdProp,
                    blockNumber,
                });
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(Group, null,
            React.createElement(GroupHeadline, null, "Select a Stream"),
            React.createElement(SectionHeadline, null, "Your Sponsorship will incentivize nodes to relay and secure this stream"),
            React.createElement(Section, null,
                React.createElement(Label, { "$wrap": true }, "Select a Stream"),
                React.createElement(StreamIdDropdown, { autoFocus: !streamIdProp, placeholder: "Type to select a stream", disabled: busy, readOnly: !!streamIdProp, onChange: (value) => {
                        setStreamId(value);
                    }, value: streamId }))),
        React.createElement(Group, null,
            React.createElement(GroupHeadline, null, "Set Sponsorship parameters"),
            React.createElement(Section, null,
                React.createElement(WingedLabelWrap, null,
                    React.createElement(Label, { "$wrap": true }, "Initial amount to fund"),
                    insufficientFunds && React.createElement(ErrorLabel, null, "Insufficient funds")),
                React.createElement(FieldWrap, { "$invalid": insufficientFunds },
                    React.createElement(TextInput, { name: "initialAmount", autoFocus: !!streamIdProp, onChange: (e) => {
                            setRawInitialAmount(e.target.value);
                        }, placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawInitialAmount }),
                    React.createElement(TextAppendix, null,
                        React.createElement(SponsorshipPaymentTokenName, null))),
                React.createElement(Hint, null,
                    React.createElement("p", null,
                        "Wallet balance:",
                        ' ',
                        React.createElement("strong", null,
                            React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: balance, tooltip: limitedSpace }))))),
            React.createElement(Section, null,
                React.createElement(Label, { "$wrap": true }, "Payout rate*"),
                React.createElement(FieldWrap, null,
                    React.createElement(TextInput, { name: "payoutRate", onChange: (e) => {
                            setRawDailyPayoutRate(e.target.value);
                        }, placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawDailyPayoutRate }),
                    React.createElement(TextAppendix, null,
                        React.createElement(SponsorshipPaymentTokenName, null),
                        "/day")),
                React.createElement(Hint, null,
                    React.createElement("p", null,
                        "The Sponsorship will be funded for",
                        ' ',
                        extensionInDays.toFixed(2).replace(/\.00$/, ''),
                        " days"))),
            React.createElement(Section, null,
                React.createElement(WingedLabelWrap, null,
                    React.createElement(Label, { "$wrap": true }, "Minimum time operators must stay staked"),
                    React.createElement(ErrorWrap, null,
                        invalidMinStakeDuration && (React.createElement(ErrorLabel, null, "The value is higher than the duration of the sponsorship")),
                        tooLongMinStakeDuration && (React.createElement(ErrorLabel, null,
                            "Max amount: ",
                            maxPenaltyPeriodInDays,
                            " days")))),
                React.createElement(FieldWrap, { "$invalid": invalidMinStakeDuration || tooLongMinStakeDuration },
                    React.createElement(TextInput, { name: "minStakeDuration", onChange: (e) => {
                            setRawMinStakeDurationInDays(e.target.value);
                        }, placeholder: "0", readOnly: busy, type: "number", min: 0, value: rawMinStakeDurationInDays }),
                    React.createElement(TextAppendix, null, "Days"))),
            React.createElement(Section, null,
                React.createElement(Label, { "$wrap": true }, "Minimum number of operators to start payout"),
                React.createElement(FieldWrap, { "$invalid": tooLowOperatorCount },
                    React.createElement(TextInput, { name: "minNumberOfOperators", onChange: (e) => {
                            setRawMinNumberOfOperators(e.target.value);
                        }, placeholder: "0", readOnly: busy, type: "number", min: 0, value: rawMinNumberOfOperators }),
                    React.createElement(TextAppendix, null,
                        React.createElement("span", null, "Operators"))))),
        React.createElement(SponsorshipDisclaimer, { checkboxState: confirmState, onCheckboxStateChange: (value) => setConfirmState(value) })));
}
export const createSponsorshipModal = toaster(CreateSponsorshipModal, Layer.Modal);
