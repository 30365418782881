export class DraftValidationError extends Error {
    constructor(key, message) {
        super(message);
        this.key = key;
        this.message = message;
        this.name = 'DraftValidationError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DraftValidationError);
        }
        Object.setPrototypeOf(this, DraftValidationError.prototype);
    }
}
export class ValidationError extends Error {
    constructor(messages) {
        super(messages.join(', '));
        this.messages = messages;
        this.name = 'ValidationError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }
        Object.setPrototypeOf(this, ValidationError.prototype);
    }
}
