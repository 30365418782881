import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import SvgIcon from '~/shared/components/SvgIcon';
import { LIGHT } from '~/shared/utils/styled';
import ProjectModal from './ProjectModal';
const Box = styled.div.withConfig({ displayName: "Box", componentId: "sc-es2kt8" }) `
    background: #ffffff;
    border-radius: 8px;
    padding: 32px 24px;
    margin: 0 auto;
    width: 408px;

    h2 {
        font-size: 24px;
        font-weight: ${LIGHT};
        line-height: normal;
        text-align: center;
        margin: 0 0 12px;
    }

    p {
        font-size: 14px;
        line-height: normal;
        text-align: center;
        margin: 0;
    }

    button {
        width: 100%;
    }
`;
const Icon = styled(SvgIcon).withConfig({ displayName: "Icon", componentId: "sc-f5648v" }) `
    width: 112px;
    height: 112px;
    justify-self: center;
    align-self: center;
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-7iig4k" }) `
    align-items: center;
    display: flex;
    height: 224px;
    justify-content: center;
    width: 100%;

    svg {
        display: block;
    }
`;
export default function PurchaseCompleteModal({ onResolve, onReject }) {
    useEffect(() => {
        function onKeyDown(e) {
            if (e.key === 'Escape') {
                onResolve?.();
            }
        }
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [onResolve]);
    return (React.createElement(ProjectModal, { onReject: onReject },
        React.createElement(Box, null,
            React.createElement("h2", null, "Success!"),
            React.createElement("p", null, "Access to the project was granted."),
            React.createElement(IconWrap, null,
                React.createElement(Icon, { name: "checkmarkOutline" })),
            React.createElement(Button, { type: "button", onClick: () => void onResolve?.() }, "Done"))));
}
