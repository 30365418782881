import { useEffect, useRef, useState } from 'react';
const isSSR = typeof IntersectionObserver === 'undefined';
export function useInViewport() {
    const [target, setTarget] = useState(null);
    const targetRef = useRef(null);
    const [inViewport, setInViewport] = useState(false);
    const observerRef = useRef(isSSR
        ? undefined
        : new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target === targetRef.current) {
                    setInViewport(entry.isIntersecting);
                }
            });
        }));
    useEffect(() => {
        const { current: observer } = observerRef;
        targetRef.current = target;
        if (target) {
            observer?.observe(target);
        }
        else {
            setInViewport(false);
        }
        return () => {
            if (target) {
                observer?.unobserve(target);
            }
        };
    }, [target]);
    useEffect(() => {
        const { current: observer } = observerRef;
        return () => {
            observer?.disconnect();
        };
    }, []);
    useEffect(() => {
        if (isSSR) {
            // Fallback for when `IntersectionObserver` isn't there. We do it in `useEffect` to
            // cover non-SSR environments that don't give us `IntersectionObserver`.
            setInViewport(true);
        }
    }, []);
    return [setTarget, inViewport];
}
