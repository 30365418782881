import { useCallback, useState } from 'react';
export function useTableOrder({ orderBy, orderDirection, } = {}) {
    const [order, setOrder] = useState(orderBy && orderDirection
        ? {
            orderBy,
            orderDirection,
        }
        : { orderBy: undefined, orderDirection: undefined });
    const handleOrderChange = useCallback((column, direction) => {
        if (!direction) {
            setOrder((currentOrder) => {
                return getNextSortingParameters(currentOrder.orderBy, column, currentOrder.orderDirection);
            });
            return;
        }
        setOrder({
            orderBy: column,
            orderDirection: direction,
        });
    }, []);
    return {
        ...order,
        setOrder: handleOrderChange,
    };
}
function getNextSortingParameters(currentOrderBy, newOrderBy, currentOrderDirection) {
    if (currentOrderBy !== newOrderBy) {
        return {
            orderBy: newOrderBy,
            orderDirection: 'asc',
        };
    }
    const newDirection = !currentOrderDirection
        ? 'asc'
        : currentOrderDirection === 'asc'
            ? 'desc'
            : undefined;
    if (newDirection == null) {
        return {
            orderBy: undefined,
            orderDirection: undefined,
        };
    }
    return {
        orderBy: newOrderBy,
        orderDirection: newDirection,
    };
}
