import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import NetworkPageSegment, { TitleBar } from '~/components/NetworkPageSegment';
import { QueriedSponsorshipsTable } from '~/components/QueriedSponsorshipsTable';
import { SponsorshipFilterButton, } from '~/components/SponsorshipFilterButton';
import { useCreateSponsorship, useSponsorshipsByStreamIdQuery, } from '~/hooks/sponsorships';
import { useTableOrder } from '~/hooks/useTableOrder';
import { NoData } from '~/shared/components/NoData';
import { useWalletAccount } from '~/shared/stores/wallet';
import { useCurrentChainId } from '~/utils/chains';
import { Route as R } from '~/utils/routes';
const PAGE_SIZE = 5;
export default function SponsorshipsTable({ streamId }) {
    const [filters, setFilters] = useState({
        expired: false,
        inactive: false,
        my: false,
        noFunding: false,
    });
    const { orderBy = 'remainingWei', orderDirection = 'desc', setOrder, } = useTableOrder();
    const wallet = useWalletAccount();
    const createSponsorship = useCreateSponsorship();
    const chainId = useCurrentChainId();
    const query = useSponsorshipsByStreamIdQuery({
        pageSize: PAGE_SIZE,
        streamId,
        orderBy,
        orderDirection,
        filters,
    });
    const sponsorships = query.data?.pages.map((page) => page.sponsorships).flat() || [];
    return (React.createElement(NetworkPageSegment, { foot: sponsorships.length > 0, title: React.createElement(TitleBar, { aux: React.createElement(React.Fragment, null,
                sponsorships.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(CreateButton, { type: "button", onClick: () => createSponsorship(chainId, wallet, {
                            streamId,
                        }), disabled: streamId == null || wallet == null }, "Sponsor stream"),
                    React.createElement(Separator, null))),
                React.createElement(SponsorshipFilterButton, { filter: filters, onFilterChange: setFilters })) }, "Active Stream Sponsorships") }, sponsorships.length > 0 ? (React.createElement(QueriedSponsorshipsTable, { query: query, orderBy: orderBy, orderDirection: orderDirection, onOrderChange: setOrder, hideStreamId: true })) : (React.createElement(NoDataContainer, null,
        React.createElement(NoData, { firstLine: "This stream does not have any Sponsorships matching your criteria yet.", secondLine: React.createElement("span", null,
                React.createElement("a", { href: R.docs('/streamr-network/incentives/stream-sponsorships'), rel: "noopener noreferrer", target: "_blank" }, "Stream Sponsorships"),
                ' ',
                "incentivize node operators to relay the",
                React.createElement("br", null),
                " stream's data, which enhances its connectability and robustness."), compact: true }),
        React.createElement(CreateButton, { type: "button", onClick: () => {
                createSponsorship(chainId, wallet, { streamId });
            }, disabled: streamId == null || wallet == null }, "Sponsor stream")))));
}
const NoDataContainer = styled.div.withConfig({ displayName: "NoDataContainer", componentId: "sc-1ya7itw" }) `
    display: grid;
    padding: 80px;
`;
const CreateButton = styled(Button).withConfig({ displayName: "CreateButton", componentId: "sc-1lhoiwm" }) `
    width: fit-content;
    justify-self: center;
`;
const Separator = styled.div.withConfig({ displayName: "Separator", componentId: "sc-1ggimjn" }) `
    width: 1px;
    height: 1rem;
    background: #f0f0f0;
    margin: 0 8px;
`;
