import React from 'react';
import styled from 'styled-components';
import { getEmptyParsedProject } from '~/parsers/ProjectParser';
import TextField from '~/shared/components/Ui/Text/StyledInput';
import { ProjectDraft } from '~/stores/projectDraft';
import { ProjectType } from '~/shared/types';
import { COLORS } from '~/shared/utils/styled';
export default function DataUnionFee({ disabled = false }) {
    const update = ProjectDraft.useUpdateEntity();
    const emptyProject = getEmptyParsedProject({ type: ProjectType.OpenData });
    const project = ProjectDraft.useEntity({ hot: true }) || emptyProject;
    const coldProject = ProjectDraft.useEntity() || emptyProject;
    const isDataUnion = project.type === ProjectType.DataUnion;
    return (React.createElement(Root, null,
        React.createElement("p", null, "Set the fee you receive from this Data Union (% of total\u00A0sales)"),
        React.createElement(InputWrap, null,
            React.createElement(TextField, { placeholder: "0", value: (isDataUnion && project.adminFee) || '', readOnly: !isDataUnion, disabled: disabled, onChange: (e) => {
                    update((draft) => {
                        if (draft.type !== ProjectType.DataUnion) {
                            return;
                        }
                        if (coldProject.type !== draft.type) {
                            throw new Error('Cold and hot projects have different type, lol. Code much?');
                        }
                        draft.adminFee = e.target.value;
                    });
                } }),
            React.createElement(Appendix, null, "%"))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-27xphx" }) `
    align-items: center;
    background-color: ${COLORS.inputBackground};
    border-radius: 4px;
    display: flex;
    padding: 24px;

    p {
        flex-grow: 1;
        margin: 0 56px 0 0;
    }
`;
const InputWrap = styled.div.withConfig({ displayName: "InputWrap", componentId: "sc-1ci6wiu" }) `
    flex: 1;
    min-width: 120px;
    position: relative;

    ${TextField} {
        padding-right: 48px;
    }
`;
const Appendix = styled.div.withConfig({ displayName: "Appendix", componentId: "sc-1k7mhzd" }) `
    align-items: center;
    border-left: 1px solid #efefef;
    color: #525252;
    display: flex;
    height: 38px;
    justify-content: center;
    line-height: normal;
    pointer-events: none;
    position: absolute;
    right: 1px;
    top: 1px;
    width: 36px;
`;
