import { gql } from '@apollo/client';
export var AbiChanged_OrderBy;
(function (AbiChanged_OrderBy) {
    AbiChanged_OrderBy["BlockNumber"] = "blockNumber";
    AbiChanged_OrderBy["ContentType"] = "contentType";
    AbiChanged_OrderBy["Id"] = "id";
    AbiChanged_OrderBy["Resolver"] = "resolver";
    AbiChanged_OrderBy["ResolverAddress"] = "resolver__address";
    AbiChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    AbiChanged_OrderBy["ResolverId"] = "resolver__id";
    AbiChanged_OrderBy["TransactionId"] = "transactionID";
})(AbiChanged_OrderBy || (AbiChanged_OrderBy = {}));
export var Account_OrderBy;
(function (Account_OrderBy) {
    Account_OrderBy["Domains"] = "domains";
    Account_OrderBy["Id"] = "id";
    Account_OrderBy["Registrations"] = "registrations";
    Account_OrderBy["WrappedDomains"] = "wrappedDomains";
})(Account_OrderBy || (Account_OrderBy = {}));
export var AddrChanged_OrderBy;
(function (AddrChanged_OrderBy) {
    AddrChanged_OrderBy["Addr"] = "addr";
    AddrChanged_OrderBy["AddrId"] = "addr__id";
    AddrChanged_OrderBy["BlockNumber"] = "blockNumber";
    AddrChanged_OrderBy["Id"] = "id";
    AddrChanged_OrderBy["Resolver"] = "resolver";
    AddrChanged_OrderBy["ResolverAddress"] = "resolver__address";
    AddrChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    AddrChanged_OrderBy["ResolverId"] = "resolver__id";
    AddrChanged_OrderBy["TransactionId"] = "transactionID";
})(AddrChanged_OrderBy || (AddrChanged_OrderBy = {}));
export var Aggregation_Interval;
(function (Aggregation_Interval) {
    Aggregation_Interval["Day"] = "day";
    Aggregation_Interval["Hour"] = "hour";
})(Aggregation_Interval || (Aggregation_Interval = {}));
export var AuthorisationChanged_OrderBy;
(function (AuthorisationChanged_OrderBy) {
    AuthorisationChanged_OrderBy["BlockNumber"] = "blockNumber";
    AuthorisationChanged_OrderBy["Id"] = "id";
    AuthorisationChanged_OrderBy["IsAuthorized"] = "isAuthorized";
    AuthorisationChanged_OrderBy["Owner"] = "owner";
    AuthorisationChanged_OrderBy["Resolver"] = "resolver";
    AuthorisationChanged_OrderBy["ResolverAddress"] = "resolver__address";
    AuthorisationChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    AuthorisationChanged_OrderBy["ResolverId"] = "resolver__id";
    AuthorisationChanged_OrderBy["Target"] = "target";
    AuthorisationChanged_OrderBy["TransactionId"] = "transactionID";
})(AuthorisationChanged_OrderBy || (AuthorisationChanged_OrderBy = {}));
export var ContenthashChanged_OrderBy;
(function (ContenthashChanged_OrderBy) {
    ContenthashChanged_OrderBy["BlockNumber"] = "blockNumber";
    ContenthashChanged_OrderBy["Hash"] = "hash";
    ContenthashChanged_OrderBy["Id"] = "id";
    ContenthashChanged_OrderBy["Resolver"] = "resolver";
    ContenthashChanged_OrderBy["ResolverAddress"] = "resolver__address";
    ContenthashChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    ContenthashChanged_OrderBy["ResolverId"] = "resolver__id";
    ContenthashChanged_OrderBy["TransactionId"] = "transactionID";
})(ContenthashChanged_OrderBy || (ContenthashChanged_OrderBy = {}));
export var DomainEvent_OrderBy;
(function (DomainEvent_OrderBy) {
    DomainEvent_OrderBy["BlockNumber"] = "blockNumber";
    DomainEvent_OrderBy["Domain"] = "domain";
    DomainEvent_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    DomainEvent_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    DomainEvent_OrderBy["DomainId"] = "domain__id";
    DomainEvent_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    DomainEvent_OrderBy["DomainLabelName"] = "domain__labelName";
    DomainEvent_OrderBy["DomainLabelhash"] = "domain__labelhash";
    DomainEvent_OrderBy["DomainName"] = "domain__name";
    DomainEvent_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    DomainEvent_OrderBy["DomainTtl"] = "domain__ttl";
    DomainEvent_OrderBy["Id"] = "id";
    DomainEvent_OrderBy["TransactionId"] = "transactionID";
})(DomainEvent_OrderBy || (DomainEvent_OrderBy = {}));
export var Domain_OrderBy;
(function (Domain_OrderBy) {
    Domain_OrderBy["CreatedAt"] = "createdAt";
    Domain_OrderBy["Events"] = "events";
    Domain_OrderBy["ExpiryDate"] = "expiryDate";
    Domain_OrderBy["Id"] = "id";
    Domain_OrderBy["IsMigrated"] = "isMigrated";
    Domain_OrderBy["LabelName"] = "labelName";
    Domain_OrderBy["Labelhash"] = "labelhash";
    Domain_OrderBy["Name"] = "name";
    Domain_OrderBy["Owner"] = "owner";
    Domain_OrderBy["OwnerId"] = "owner__id";
    Domain_OrderBy["Parent"] = "parent";
    Domain_OrderBy["ParentCreatedAt"] = "parent__createdAt";
    Domain_OrderBy["ParentExpiryDate"] = "parent__expiryDate";
    Domain_OrderBy["ParentId"] = "parent__id";
    Domain_OrderBy["ParentIsMigrated"] = "parent__isMigrated";
    Domain_OrderBy["ParentLabelName"] = "parent__labelName";
    Domain_OrderBy["ParentLabelhash"] = "parent__labelhash";
    Domain_OrderBy["ParentName"] = "parent__name";
    Domain_OrderBy["ParentSubdomainCount"] = "parent__subdomainCount";
    Domain_OrderBy["ParentTtl"] = "parent__ttl";
    Domain_OrderBy["Registrant"] = "registrant";
    Domain_OrderBy["RegistrantId"] = "registrant__id";
    Domain_OrderBy["Registration"] = "registration";
    Domain_OrderBy["RegistrationCost"] = "registration__cost";
    Domain_OrderBy["RegistrationExpiryDate"] = "registration__expiryDate";
    Domain_OrderBy["RegistrationId"] = "registration__id";
    Domain_OrderBy["RegistrationLabelName"] = "registration__labelName";
    Domain_OrderBy["RegistrationRegistrationDate"] = "registration__registrationDate";
    Domain_OrderBy["ResolvedAddress"] = "resolvedAddress";
    Domain_OrderBy["ResolvedAddressId"] = "resolvedAddress__id";
    Domain_OrderBy["Resolver"] = "resolver";
    Domain_OrderBy["ResolverAddress"] = "resolver__address";
    Domain_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    Domain_OrderBy["ResolverId"] = "resolver__id";
    Domain_OrderBy["SubdomainCount"] = "subdomainCount";
    Domain_OrderBy["Subdomains"] = "subdomains";
    Domain_OrderBy["Ttl"] = "ttl";
    Domain_OrderBy["WrappedDomain"] = "wrappedDomain";
    Domain_OrderBy["WrappedDomainExpiryDate"] = "wrappedDomain__expiryDate";
    Domain_OrderBy["WrappedDomainFuses"] = "wrappedDomain__fuses";
    Domain_OrderBy["WrappedDomainId"] = "wrappedDomain__id";
    Domain_OrderBy["WrappedDomainName"] = "wrappedDomain__name";
    Domain_OrderBy["WrappedOwner"] = "wrappedOwner";
    Domain_OrderBy["WrappedOwnerId"] = "wrappedOwner__id";
})(Domain_OrderBy || (Domain_OrderBy = {}));
export var ExpiryExtended_OrderBy;
(function (ExpiryExtended_OrderBy) {
    ExpiryExtended_OrderBy["BlockNumber"] = "blockNumber";
    ExpiryExtended_OrderBy["Domain"] = "domain";
    ExpiryExtended_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    ExpiryExtended_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    ExpiryExtended_OrderBy["DomainId"] = "domain__id";
    ExpiryExtended_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    ExpiryExtended_OrderBy["DomainLabelName"] = "domain__labelName";
    ExpiryExtended_OrderBy["DomainLabelhash"] = "domain__labelhash";
    ExpiryExtended_OrderBy["DomainName"] = "domain__name";
    ExpiryExtended_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    ExpiryExtended_OrderBy["DomainTtl"] = "domain__ttl";
    ExpiryExtended_OrderBy["ExpiryDate"] = "expiryDate";
    ExpiryExtended_OrderBy["Id"] = "id";
    ExpiryExtended_OrderBy["TransactionId"] = "transactionID";
})(ExpiryExtended_OrderBy || (ExpiryExtended_OrderBy = {}));
export var FusesSet_OrderBy;
(function (FusesSet_OrderBy) {
    FusesSet_OrderBy["BlockNumber"] = "blockNumber";
    FusesSet_OrderBy["Domain"] = "domain";
    FusesSet_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    FusesSet_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    FusesSet_OrderBy["DomainId"] = "domain__id";
    FusesSet_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    FusesSet_OrderBy["DomainLabelName"] = "domain__labelName";
    FusesSet_OrderBy["DomainLabelhash"] = "domain__labelhash";
    FusesSet_OrderBy["DomainName"] = "domain__name";
    FusesSet_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    FusesSet_OrderBy["DomainTtl"] = "domain__ttl";
    FusesSet_OrderBy["Fuses"] = "fuses";
    FusesSet_OrderBy["Id"] = "id";
    FusesSet_OrderBy["TransactionId"] = "transactionID";
})(FusesSet_OrderBy || (FusesSet_OrderBy = {}));
export var InterfaceChanged_OrderBy;
(function (InterfaceChanged_OrderBy) {
    InterfaceChanged_OrderBy["BlockNumber"] = "blockNumber";
    InterfaceChanged_OrderBy["Id"] = "id";
    InterfaceChanged_OrderBy["Implementer"] = "implementer";
    InterfaceChanged_OrderBy["InterfaceId"] = "interfaceID";
    InterfaceChanged_OrderBy["Resolver"] = "resolver";
    InterfaceChanged_OrderBy["ResolverAddress"] = "resolver__address";
    InterfaceChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    InterfaceChanged_OrderBy["ResolverId"] = "resolver__id";
    InterfaceChanged_OrderBy["TransactionId"] = "transactionID";
})(InterfaceChanged_OrderBy || (InterfaceChanged_OrderBy = {}));
export var MulticoinAddrChanged_OrderBy;
(function (MulticoinAddrChanged_OrderBy) {
    MulticoinAddrChanged_OrderBy["Addr"] = "addr";
    MulticoinAddrChanged_OrderBy["BlockNumber"] = "blockNumber";
    MulticoinAddrChanged_OrderBy["CoinType"] = "coinType";
    MulticoinAddrChanged_OrderBy["Id"] = "id";
    MulticoinAddrChanged_OrderBy["Resolver"] = "resolver";
    MulticoinAddrChanged_OrderBy["ResolverAddress"] = "resolver__address";
    MulticoinAddrChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    MulticoinAddrChanged_OrderBy["ResolverId"] = "resolver__id";
    MulticoinAddrChanged_OrderBy["TransactionId"] = "transactionID";
})(MulticoinAddrChanged_OrderBy || (MulticoinAddrChanged_OrderBy = {}));
export var NameChanged_OrderBy;
(function (NameChanged_OrderBy) {
    NameChanged_OrderBy["BlockNumber"] = "blockNumber";
    NameChanged_OrderBy["Id"] = "id";
    NameChanged_OrderBy["Name"] = "name";
    NameChanged_OrderBy["Resolver"] = "resolver";
    NameChanged_OrderBy["ResolverAddress"] = "resolver__address";
    NameChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    NameChanged_OrderBy["ResolverId"] = "resolver__id";
    NameChanged_OrderBy["TransactionId"] = "transactionID";
})(NameChanged_OrderBy || (NameChanged_OrderBy = {}));
export var NameRegistered_OrderBy;
(function (NameRegistered_OrderBy) {
    NameRegistered_OrderBy["BlockNumber"] = "blockNumber";
    NameRegistered_OrderBy["ExpiryDate"] = "expiryDate";
    NameRegistered_OrderBy["Id"] = "id";
    NameRegistered_OrderBy["Registrant"] = "registrant";
    NameRegistered_OrderBy["RegistrantId"] = "registrant__id";
    NameRegistered_OrderBy["Registration"] = "registration";
    NameRegistered_OrderBy["RegistrationCost"] = "registration__cost";
    NameRegistered_OrderBy["RegistrationExpiryDate"] = "registration__expiryDate";
    NameRegistered_OrderBy["RegistrationId"] = "registration__id";
    NameRegistered_OrderBy["RegistrationLabelName"] = "registration__labelName";
    NameRegistered_OrderBy["RegistrationRegistrationDate"] = "registration__registrationDate";
    NameRegistered_OrderBy["TransactionId"] = "transactionID";
})(NameRegistered_OrderBy || (NameRegistered_OrderBy = {}));
export var NameRenewed_OrderBy;
(function (NameRenewed_OrderBy) {
    NameRenewed_OrderBy["BlockNumber"] = "blockNumber";
    NameRenewed_OrderBy["ExpiryDate"] = "expiryDate";
    NameRenewed_OrderBy["Id"] = "id";
    NameRenewed_OrderBy["Registration"] = "registration";
    NameRenewed_OrderBy["RegistrationCost"] = "registration__cost";
    NameRenewed_OrderBy["RegistrationExpiryDate"] = "registration__expiryDate";
    NameRenewed_OrderBy["RegistrationId"] = "registration__id";
    NameRenewed_OrderBy["RegistrationLabelName"] = "registration__labelName";
    NameRenewed_OrderBy["RegistrationRegistrationDate"] = "registration__registrationDate";
    NameRenewed_OrderBy["TransactionId"] = "transactionID";
})(NameRenewed_OrderBy || (NameRenewed_OrderBy = {}));
export var NameTransferred_OrderBy;
(function (NameTransferred_OrderBy) {
    NameTransferred_OrderBy["BlockNumber"] = "blockNumber";
    NameTransferred_OrderBy["Id"] = "id";
    NameTransferred_OrderBy["NewOwner"] = "newOwner";
    NameTransferred_OrderBy["NewOwnerId"] = "newOwner__id";
    NameTransferred_OrderBy["Registration"] = "registration";
    NameTransferred_OrderBy["RegistrationCost"] = "registration__cost";
    NameTransferred_OrderBy["RegistrationExpiryDate"] = "registration__expiryDate";
    NameTransferred_OrderBy["RegistrationId"] = "registration__id";
    NameTransferred_OrderBy["RegistrationLabelName"] = "registration__labelName";
    NameTransferred_OrderBy["RegistrationRegistrationDate"] = "registration__registrationDate";
    NameTransferred_OrderBy["TransactionId"] = "transactionID";
})(NameTransferred_OrderBy || (NameTransferred_OrderBy = {}));
export var NameUnwrapped_OrderBy;
(function (NameUnwrapped_OrderBy) {
    NameUnwrapped_OrderBy["BlockNumber"] = "blockNumber";
    NameUnwrapped_OrderBy["Domain"] = "domain";
    NameUnwrapped_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    NameUnwrapped_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    NameUnwrapped_OrderBy["DomainId"] = "domain__id";
    NameUnwrapped_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    NameUnwrapped_OrderBy["DomainLabelName"] = "domain__labelName";
    NameUnwrapped_OrderBy["DomainLabelhash"] = "domain__labelhash";
    NameUnwrapped_OrderBy["DomainName"] = "domain__name";
    NameUnwrapped_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    NameUnwrapped_OrderBy["DomainTtl"] = "domain__ttl";
    NameUnwrapped_OrderBy["Id"] = "id";
    NameUnwrapped_OrderBy["Owner"] = "owner";
    NameUnwrapped_OrderBy["OwnerId"] = "owner__id";
    NameUnwrapped_OrderBy["TransactionId"] = "transactionID";
})(NameUnwrapped_OrderBy || (NameUnwrapped_OrderBy = {}));
export var NameWrapped_OrderBy;
(function (NameWrapped_OrderBy) {
    NameWrapped_OrderBy["BlockNumber"] = "blockNumber";
    NameWrapped_OrderBy["Domain"] = "domain";
    NameWrapped_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    NameWrapped_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    NameWrapped_OrderBy["DomainId"] = "domain__id";
    NameWrapped_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    NameWrapped_OrderBy["DomainLabelName"] = "domain__labelName";
    NameWrapped_OrderBy["DomainLabelhash"] = "domain__labelhash";
    NameWrapped_OrderBy["DomainName"] = "domain__name";
    NameWrapped_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    NameWrapped_OrderBy["DomainTtl"] = "domain__ttl";
    NameWrapped_OrderBy["ExpiryDate"] = "expiryDate";
    NameWrapped_OrderBy["Fuses"] = "fuses";
    NameWrapped_OrderBy["Id"] = "id";
    NameWrapped_OrderBy["Name"] = "name";
    NameWrapped_OrderBy["Owner"] = "owner";
    NameWrapped_OrderBy["OwnerId"] = "owner__id";
    NameWrapped_OrderBy["TransactionId"] = "transactionID";
})(NameWrapped_OrderBy || (NameWrapped_OrderBy = {}));
export var NewOwner_OrderBy;
(function (NewOwner_OrderBy) {
    NewOwner_OrderBy["BlockNumber"] = "blockNumber";
    NewOwner_OrderBy["Domain"] = "domain";
    NewOwner_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    NewOwner_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    NewOwner_OrderBy["DomainId"] = "domain__id";
    NewOwner_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    NewOwner_OrderBy["DomainLabelName"] = "domain__labelName";
    NewOwner_OrderBy["DomainLabelhash"] = "domain__labelhash";
    NewOwner_OrderBy["DomainName"] = "domain__name";
    NewOwner_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    NewOwner_OrderBy["DomainTtl"] = "domain__ttl";
    NewOwner_OrderBy["Id"] = "id";
    NewOwner_OrderBy["Owner"] = "owner";
    NewOwner_OrderBy["OwnerId"] = "owner__id";
    NewOwner_OrderBy["ParentDomain"] = "parentDomain";
    NewOwner_OrderBy["ParentDomainCreatedAt"] = "parentDomain__createdAt";
    NewOwner_OrderBy["ParentDomainExpiryDate"] = "parentDomain__expiryDate";
    NewOwner_OrderBy["ParentDomainId"] = "parentDomain__id";
    NewOwner_OrderBy["ParentDomainIsMigrated"] = "parentDomain__isMigrated";
    NewOwner_OrderBy["ParentDomainLabelName"] = "parentDomain__labelName";
    NewOwner_OrderBy["ParentDomainLabelhash"] = "parentDomain__labelhash";
    NewOwner_OrderBy["ParentDomainName"] = "parentDomain__name";
    NewOwner_OrderBy["ParentDomainSubdomainCount"] = "parentDomain__subdomainCount";
    NewOwner_OrderBy["ParentDomainTtl"] = "parentDomain__ttl";
    NewOwner_OrderBy["TransactionId"] = "transactionID";
})(NewOwner_OrderBy || (NewOwner_OrderBy = {}));
export var NewResolver_OrderBy;
(function (NewResolver_OrderBy) {
    NewResolver_OrderBy["BlockNumber"] = "blockNumber";
    NewResolver_OrderBy["Domain"] = "domain";
    NewResolver_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    NewResolver_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    NewResolver_OrderBy["DomainId"] = "domain__id";
    NewResolver_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    NewResolver_OrderBy["DomainLabelName"] = "domain__labelName";
    NewResolver_OrderBy["DomainLabelhash"] = "domain__labelhash";
    NewResolver_OrderBy["DomainName"] = "domain__name";
    NewResolver_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    NewResolver_OrderBy["DomainTtl"] = "domain__ttl";
    NewResolver_OrderBy["Id"] = "id";
    NewResolver_OrderBy["Resolver"] = "resolver";
    NewResolver_OrderBy["ResolverAddress"] = "resolver__address";
    NewResolver_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    NewResolver_OrderBy["ResolverId"] = "resolver__id";
    NewResolver_OrderBy["TransactionId"] = "transactionID";
})(NewResolver_OrderBy || (NewResolver_OrderBy = {}));
export var NewTtl_OrderBy;
(function (NewTtl_OrderBy) {
    NewTtl_OrderBy["BlockNumber"] = "blockNumber";
    NewTtl_OrderBy["Domain"] = "domain";
    NewTtl_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    NewTtl_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    NewTtl_OrderBy["DomainId"] = "domain__id";
    NewTtl_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    NewTtl_OrderBy["DomainLabelName"] = "domain__labelName";
    NewTtl_OrderBy["DomainLabelhash"] = "domain__labelhash";
    NewTtl_OrderBy["DomainName"] = "domain__name";
    NewTtl_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    NewTtl_OrderBy["DomainTtl"] = "domain__ttl";
    NewTtl_OrderBy["Id"] = "id";
    NewTtl_OrderBy["TransactionId"] = "transactionID";
    NewTtl_OrderBy["Ttl"] = "ttl";
})(NewTtl_OrderBy || (NewTtl_OrderBy = {}));
/** Defines the order direction, either ascending or descending */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "asc";
    OrderDirection["Desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
export var PubkeyChanged_OrderBy;
(function (PubkeyChanged_OrderBy) {
    PubkeyChanged_OrderBy["BlockNumber"] = "blockNumber";
    PubkeyChanged_OrderBy["Id"] = "id";
    PubkeyChanged_OrderBy["Resolver"] = "resolver";
    PubkeyChanged_OrderBy["ResolverAddress"] = "resolver__address";
    PubkeyChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    PubkeyChanged_OrderBy["ResolverId"] = "resolver__id";
    PubkeyChanged_OrderBy["TransactionId"] = "transactionID";
    PubkeyChanged_OrderBy["X"] = "x";
    PubkeyChanged_OrderBy["Y"] = "y";
})(PubkeyChanged_OrderBy || (PubkeyChanged_OrderBy = {}));
export var RegistrationEvent_OrderBy;
(function (RegistrationEvent_OrderBy) {
    RegistrationEvent_OrderBy["BlockNumber"] = "blockNumber";
    RegistrationEvent_OrderBy["Id"] = "id";
    RegistrationEvent_OrderBy["Registration"] = "registration";
    RegistrationEvent_OrderBy["RegistrationCost"] = "registration__cost";
    RegistrationEvent_OrderBy["RegistrationExpiryDate"] = "registration__expiryDate";
    RegistrationEvent_OrderBy["RegistrationId"] = "registration__id";
    RegistrationEvent_OrderBy["RegistrationLabelName"] = "registration__labelName";
    RegistrationEvent_OrderBy["RegistrationRegistrationDate"] = "registration__registrationDate";
    RegistrationEvent_OrderBy["TransactionId"] = "transactionID";
})(RegistrationEvent_OrderBy || (RegistrationEvent_OrderBy = {}));
export var Registration_OrderBy;
(function (Registration_OrderBy) {
    Registration_OrderBy["Cost"] = "cost";
    Registration_OrderBy["Domain"] = "domain";
    Registration_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    Registration_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    Registration_OrderBy["DomainId"] = "domain__id";
    Registration_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    Registration_OrderBy["DomainLabelName"] = "domain__labelName";
    Registration_OrderBy["DomainLabelhash"] = "domain__labelhash";
    Registration_OrderBy["DomainName"] = "domain__name";
    Registration_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    Registration_OrderBy["DomainTtl"] = "domain__ttl";
    Registration_OrderBy["Events"] = "events";
    Registration_OrderBy["ExpiryDate"] = "expiryDate";
    Registration_OrderBy["Id"] = "id";
    Registration_OrderBy["LabelName"] = "labelName";
    Registration_OrderBy["Registrant"] = "registrant";
    Registration_OrderBy["RegistrantId"] = "registrant__id";
    Registration_OrderBy["RegistrationDate"] = "registrationDate";
})(Registration_OrderBy || (Registration_OrderBy = {}));
export var ResolverEvent_OrderBy;
(function (ResolverEvent_OrderBy) {
    ResolverEvent_OrderBy["BlockNumber"] = "blockNumber";
    ResolverEvent_OrderBy["Id"] = "id";
    ResolverEvent_OrderBy["Resolver"] = "resolver";
    ResolverEvent_OrderBy["ResolverAddress"] = "resolver__address";
    ResolverEvent_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    ResolverEvent_OrderBy["ResolverId"] = "resolver__id";
    ResolverEvent_OrderBy["TransactionId"] = "transactionID";
})(ResolverEvent_OrderBy || (ResolverEvent_OrderBy = {}));
export var Resolver_OrderBy;
(function (Resolver_OrderBy) {
    Resolver_OrderBy["Addr"] = "addr";
    Resolver_OrderBy["AddrId"] = "addr__id";
    Resolver_OrderBy["Address"] = "address";
    Resolver_OrderBy["CoinTypes"] = "coinTypes";
    Resolver_OrderBy["ContentHash"] = "contentHash";
    Resolver_OrderBy["Domain"] = "domain";
    Resolver_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    Resolver_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    Resolver_OrderBy["DomainId"] = "domain__id";
    Resolver_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    Resolver_OrderBy["DomainLabelName"] = "domain__labelName";
    Resolver_OrderBy["DomainLabelhash"] = "domain__labelhash";
    Resolver_OrderBy["DomainName"] = "domain__name";
    Resolver_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    Resolver_OrderBy["DomainTtl"] = "domain__ttl";
    Resolver_OrderBy["Events"] = "events";
    Resolver_OrderBy["Id"] = "id";
    Resolver_OrderBy["Texts"] = "texts";
})(Resolver_OrderBy || (Resolver_OrderBy = {}));
export var TextChanged_OrderBy;
(function (TextChanged_OrderBy) {
    TextChanged_OrderBy["BlockNumber"] = "blockNumber";
    TextChanged_OrderBy["Id"] = "id";
    TextChanged_OrderBy["Key"] = "key";
    TextChanged_OrderBy["Resolver"] = "resolver";
    TextChanged_OrderBy["ResolverAddress"] = "resolver__address";
    TextChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    TextChanged_OrderBy["ResolverId"] = "resolver__id";
    TextChanged_OrderBy["TransactionId"] = "transactionID";
    TextChanged_OrderBy["Value"] = "value";
})(TextChanged_OrderBy || (TextChanged_OrderBy = {}));
export var Transfer_OrderBy;
(function (Transfer_OrderBy) {
    Transfer_OrderBy["BlockNumber"] = "blockNumber";
    Transfer_OrderBy["Domain"] = "domain";
    Transfer_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    Transfer_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    Transfer_OrderBy["DomainId"] = "domain__id";
    Transfer_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    Transfer_OrderBy["DomainLabelName"] = "domain__labelName";
    Transfer_OrderBy["DomainLabelhash"] = "domain__labelhash";
    Transfer_OrderBy["DomainName"] = "domain__name";
    Transfer_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    Transfer_OrderBy["DomainTtl"] = "domain__ttl";
    Transfer_OrderBy["Id"] = "id";
    Transfer_OrderBy["Owner"] = "owner";
    Transfer_OrderBy["OwnerId"] = "owner__id";
    Transfer_OrderBy["TransactionId"] = "transactionID";
})(Transfer_OrderBy || (Transfer_OrderBy = {}));
export var VersionChanged_OrderBy;
(function (VersionChanged_OrderBy) {
    VersionChanged_OrderBy["BlockNumber"] = "blockNumber";
    VersionChanged_OrderBy["Id"] = "id";
    VersionChanged_OrderBy["Resolver"] = "resolver";
    VersionChanged_OrderBy["ResolverAddress"] = "resolver__address";
    VersionChanged_OrderBy["ResolverContentHash"] = "resolver__contentHash";
    VersionChanged_OrderBy["ResolverId"] = "resolver__id";
    VersionChanged_OrderBy["TransactionId"] = "transactionID";
    VersionChanged_OrderBy["Version"] = "version";
})(VersionChanged_OrderBy || (VersionChanged_OrderBy = {}));
export var WrappedDomain_OrderBy;
(function (WrappedDomain_OrderBy) {
    WrappedDomain_OrderBy["Domain"] = "domain";
    WrappedDomain_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    WrappedDomain_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    WrappedDomain_OrderBy["DomainId"] = "domain__id";
    WrappedDomain_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    WrappedDomain_OrderBy["DomainLabelName"] = "domain__labelName";
    WrappedDomain_OrderBy["DomainLabelhash"] = "domain__labelhash";
    WrappedDomain_OrderBy["DomainName"] = "domain__name";
    WrappedDomain_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    WrappedDomain_OrderBy["DomainTtl"] = "domain__ttl";
    WrappedDomain_OrderBy["ExpiryDate"] = "expiryDate";
    WrappedDomain_OrderBy["Fuses"] = "fuses";
    WrappedDomain_OrderBy["Id"] = "id";
    WrappedDomain_OrderBy["Name"] = "name";
    WrappedDomain_OrderBy["Owner"] = "owner";
    WrappedDomain_OrderBy["OwnerId"] = "owner__id";
})(WrappedDomain_OrderBy || (WrappedDomain_OrderBy = {}));
export var WrappedTransfer_OrderBy;
(function (WrappedTransfer_OrderBy) {
    WrappedTransfer_OrderBy["BlockNumber"] = "blockNumber";
    WrappedTransfer_OrderBy["Domain"] = "domain";
    WrappedTransfer_OrderBy["DomainCreatedAt"] = "domain__createdAt";
    WrappedTransfer_OrderBy["DomainExpiryDate"] = "domain__expiryDate";
    WrappedTransfer_OrderBy["DomainId"] = "domain__id";
    WrappedTransfer_OrderBy["DomainIsMigrated"] = "domain__isMigrated";
    WrappedTransfer_OrderBy["DomainLabelName"] = "domain__labelName";
    WrappedTransfer_OrderBy["DomainLabelhash"] = "domain__labelhash";
    WrappedTransfer_OrderBy["DomainName"] = "domain__name";
    WrappedTransfer_OrderBy["DomainSubdomainCount"] = "domain__subdomainCount";
    WrappedTransfer_OrderBy["DomainTtl"] = "domain__ttl";
    WrappedTransfer_OrderBy["Id"] = "id";
    WrappedTransfer_OrderBy["Owner"] = "owner";
    WrappedTransfer_OrderBy["OwnerId"] = "owner__id";
    WrappedTransfer_OrderBy["TransactionId"] = "transactionID";
})(WrappedTransfer_OrderBy || (WrappedTransfer_OrderBy = {}));
export var _SubgraphErrorPolicy_;
(function (_SubgraphErrorPolicy_) {
    /** Data will be returned even if the subgraph has indexing errors */
    _SubgraphErrorPolicy_["Allow"] = "allow";
    /** If the subgraph has indexing errors, data will be omitted. The default. */
    _SubgraphErrorPolicy_["Deny"] = "deny";
})(_SubgraphErrorPolicy_ || (_SubgraphErrorPolicy_ = {}));
export const GetEnsDomainsForAccountDocument = gql `
    query getEnsDomainsForAccount($account: String!) {
  domains(where: {owner_in: [$account]}, orderBy: name) {
    name
  }
  wrappedDomains(where: {owner_in: [$account]}, orderBy: name) {
    name
  }
}
    `;
