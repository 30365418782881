import React from 'react';
import styled from 'styled-components';
import { COLORS } from '~/shared/utils/styled';
import Spinner from './Spinner';
import { Tooltip } from './Tooltip';
export function BehindBlockErrorDisplay({ latest, initial = latest, }) {
    const { actualBlockNumber: initialBlockNumber } = initial;
    const { actualBlockNumber, expectedBlockNumber } = latest;
    const total = Math.max(1, expectedBlockNumber - initialBlockNumber);
    const completed = Math.max(0, actualBlockNumber - initialBlockNumber);
    const togo = Math.max(0, expectedBlockNumber - actualBlockNumber);
    return (React.createElement(Root, null,
        React.createElement(Spinner, { fixed: true, coverage: Math.min(1, completed / total), size: 24, strokeWidth: 3 }),
        React.createElement("h2", null, "Indexing"),
        React.createElement("p", null,
            "Your resource is being indexed.",
            React.createElement("br", null),
            "It will be available shortly."),
        React.createElement(Tooltip, { anchorDisplay: "inline-block", content: `${actualBlockNumber} / ${expectedBlockNumber}` },
            React.createElement(Blocks, null,
                togo,
                " block",
                togo !== 1 ? 's' : '',
                " to go"))));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-5c2k4i" }) `
    padding: 240px 0;
    text-align: center;

    ${Spinner} {
        margin: 0 auto;
    }

    p {
        margin: 12px 0 16px;
        line-height: 1.5em;
    }

    h2 {
        font-weight: normal;
        font-size: 24px;
        margin: 20px 0 0;
    }
`;
const Blocks = styled.div.withConfig({ displayName: "Blocks", componentId: "sc-e0ey2b" }) `
    align-items: center;
    background: #fafafa;
    border-radius: 16px;
    color: ${COLORS.primaryLight};
    display: flex;
    font-size: 14px;
    gap: 4px;
    height: 32px;
    margin: 0 auto;
    padding: 0 12px;
    width: max-content;
`;
