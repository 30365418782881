import { produce } from 'immer';
import { create } from 'zustand';
import { useCurrentChainId } from '~/utils/chains';
import { onIndexedBlock } from '~/utils/blocks';
function getFinalKey(chainId, key) {
    return JSON.stringify([chainId, ...key]);
}
const useBlockNumberDependenciesStore = create((set) => {
    return {
        dependencies: {},
        setDependency(chainId, blockNumber, key) {
            const finalKey = getFinalKey(chainId, key);
            set((store) => produce(store, (draft) => {
                draft.dependencies[finalKey] = blockNumber;
            }));
            onIndexedBlock(chainId, blockNumber, () => {
                set((store) => produce(store, (draft) => {
                    if (draft.dependencies[finalKey] !== blockNumber) {
                        /**
                         * Apparently the awaited block number for the given key has changed.
                         * Usually it's a result of… scheduling a wait for a newer block.
                         */
                        return;
                    }
                    delete draft.dependencies[finalKey];
                }));
            });
        },
    };
});
export function useIsWaitingForBlockNumber(key) {
    const chainId = useCurrentChainId();
    const { dependencies: { [getFinalKey(chainId, key)]: awaitedBlockNumber }, } = useBlockNumberDependenciesStore();
    return awaitedBlockNumber != null;
}
export function useSetBlockDependency() {
    return useBlockNumberDependenciesStore().setDependency;
}
