import { isError, } from 'ethers';
import { getWalletWeb3Provider } from '~/shared/stores/wallet';
export async function call(contract, method, params) {
    const { args, onReceipt, replaceable = true } = params;
    const provider = await getWalletWeb3Provider();
    const blockNumber = await provider.getBlockNumber();
    let tx = await contract[method](...args);
    if (replaceable) {
        tx = tx.replaceableTransaction(blockNumber);
    }
    for (;;) {
        try {
            const receipt = await tx.wait();
            if (receipt) {
                await onReceipt?.(receipt);
            }
            break;
        }
        catch (e) {
            if (!isError(e, 'TRANSACTION_REPLACED') || e.cancelled) {
                throw e;
            }
            tx = e.replacement;
        }
    }
}
