import React from 'react';
import styled, { keyframes } from 'styled-components';
export function DottedLoadingIndicator() {
    return (React.createElement(DottedLoadingIndicatorRoot, null,
        React.createElement(Left, null),
        React.createElement(Dot, null),
        React.createElement(Right, null)));
}
const animation = keyframes `
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
`;
export const DottedLoadingIndicatorRoot = styled.div.withConfig({ displayName: "DottedLoadingIndicatorRoot", componentId: "sc-1lf770x" }) `
    display: grid;
    gap: 8px;
    grid-template-columns: 16px 16px 16px;
    margin: 0 auto;
    width: max-content;
`;
const Dot = styled.div.withConfig({ displayName: "Dot", componentId: "sc-gpj5ky" }) `
    animation: ${animation} 1s ease alternate infinite;
    animation-delay: 0.2s;
    background: #0052ec;
    border-radius: 50%;
    height: 16px;
    width: 16px;
`;
const Left = styled(Dot).withConfig({ displayName: "Left", componentId: "sc-eimgru" }) `
    animation-delay: 0s;
`;
const Right = styled(Dot).withConfig({ displayName: "Right", componentId: "sc-ix6dqe" }) `
    animation-delay: 0.4s;
`;
