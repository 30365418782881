import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Hint, IconWrap } from '~/components/Hint';
import { Separator } from '~/components/Separator';
import Spinner from '~/components/Spinner';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { useOperatorByIdQuery } from '~/hooks/operators';
import { useInterceptHeartbeats } from '~/hooks/useInterceptHeartbeats';
import useOperatorLiveNodes from '~/hooks/useOperatorLiveNodes';
import { useOperatorReachability } from '~/shared/stores/operatorReachability';
import { TABLET } from '~/shared/utils/styled';
import { useIsWaitingForBlockNumber } from '~/stores/blockNumberDependencies';
import { getBalance } from '~/utils/balance';
import { toBigInt } from '~/utils/bn';
import { useCurrentChainId } from '~/utils/chains';
export function OperatorChecklist({ operatorId }) {
    const { funded, nodesDeclared, nodesFunded, nodesReachability, nodesRunning } = useOperatorChecklist(operatorId);
    const [reachable = 0, total = 0] = nodesReachability || [];
    const nodesReachable = !nodesReachability
        ? undefined
        : reachable <= total / 2
            ? false
            : reachable === total
                ? true
                : 'caution';
    return (React.createElement(React.Fragment, null,
        React.createElement(ChecklistItem, { state: funded, tip: React.createElement("p", null,
                "The owner must fund the Operator with",
                ' ',
                React.createElement(SponsorshipPaymentTokenName, null),
                "\u00A0 tokens before it can be used for staking on sponsorships or receiving delegations.") }, "Operator funded"),
        React.createElement(Separator, null),
        React.createElement(ChecklistItem, { state: nodesDeclared, tip: React.createElement("p", null, "The owner must pair at least 1 node address with their Operator.") }, "Node addresses declared"),
        React.createElement(Separator, null),
        React.createElement(ChecklistItem, { state: nodesFunded, tip: React.createElement(React.Fragment, null,
                React.createElement("p", null, "The Operator's node address(es) must each have at least 0.1 MATIC tokens."),
                React.createElement("p", null, "This alert triggers if a balance is less than 0.1 MATIC.")) }, "Node addresses funded"),
        React.createElement(Separator, null),
        React.createElement(ChecklistItem, { state: nodesRunning, tip: React.createElement("p", null, "The Operator's nodes must run the operator plugin, the node's config file must contain a private key of one of the paired node addresses, and it must contain the Operator contract address.") }, "Nodes running"),
        React.createElement(Separator, null),
        React.createElement(ChecklistItem, { state: nodesReachable, tip: React.createElement(React.Fragment, null,
                React.createElement("p", null, "The Operator must ensure that their nodes can be reached on their node's configured WebSocket port."),
                React.createElement("p", null, "The default port is 32200.")) },
            "Nodes reachable",
            nodesReachable != null && (React.createElement(React.Fragment, null,
                ' ',
                "(",
                reachable,
                " out of ",
                total,
                ")")))));
}
function useOperatorChecklist(operatorId) {
    const stale = useIsWaitingForBlockNumber(['operatorNodes', operatorId]);
    const operatorQuery = useOperatorByIdQuery(operatorId);
    const { data: operator, isLoading, isFetching } = operatorQuery;
    const [nodesFunded, setNodesFunded] = useState();
    const heartbeats = useInterceptHeartbeats(operatorId);
    const { count, isLoading: isLoadingLiveNodes } = useOperatorLiveNodes(heartbeats);
    const nodesRunning = isLoadingLiveNodes ? undefined : count > 0;
    const reachability = useOperatorReachability(heartbeats);
    const nodesReachability = isLoadingLiveNodes || reachability === 'probing' ? undefined : reachability;
    const currentChainId = useCurrentChainId();
    useEffect(() => {
        setNodesFunded(undefined);
        if (!operator) {
            return;
        }
        if (!operator.nodes.length) {
            /**
             * No nodes = no funded nodes, eh? Let's report that
             * and cut it short!
             */
            return void setNodesFunded(false);
        }
        let mounted = true;
        setTimeout(async () => {
            class InsufficientFundsError {
            }
            let result = false;
            try {
                for (const { address: nodeAddress } of operator.nodes) {
                    const balance = await getBalance({
                        chainId: currentChainId,
                        tokenAddress: 'native',
                        walletAddress: nodeAddress,
                    });
                    if (!mounted) {
                        return;
                    }
                    if (balance < toBigInt(0.1, 18n)) {
                        throw new InsufficientFundsError();
                    }
                }
                result = true;
            }
            catch (e) {
                if (e instanceof InsufficientFundsError) {
                    return;
                }
                console.warn('Failed to check if nodes are funded', e);
            }
            finally {
                if (mounted) {
                    setNodesFunded(result);
                }
            }
        });
        return () => {
            mounted = false;
        };
    }, [operator, currentChainId]);
    if (isLoading || isFetching) {
        /**
         * We're loading the operator. Make all checklist items undefined (all TBD).
         */
        return {};
    }
    if (!operator) {
        /**
         * We're done loading, and found no Operator. There are no checklist
         * items to dash out.
         */
        return {
            funded: false,
            nodesDeclared: false,
            nodesFunded: false,
            nodesReachability: [0, 0],
            nodesRunning: false,
        };
    }
    const funded = operator.valueWithoutEarnings > 0n;
    if (stale) {
        return {
            funded,
        };
    }
    const nodesDeclared = operator.nodes.length > 0;
    return {
        funded,
        nodesDeclared,
        nodesFunded,
        nodesReachability,
        nodesRunning,
    };
}
function ChecklistItem({ children, state: stateProp, tip = '', }) {
    const state = typeof stateProp === 'string'
        ? stateProp
        : typeof stateProp === 'undefined'
            ? 'tbd'
            : stateProp
                ? 'success'
                : 'failure';
    return (React.createElement(ChecklistItemRoot, null,
        React.createElement("div", null,
            state === 'failure' && (React.createElement(IconWrap, { "$color": "#FF5C00" },
                React.createElement(JiraFailedBuildStatusIcon, { label: "Error", size: "medium" }))),
            state === 'caution' && (React.createElement(IconWrap, { "$color": "#FFB800" },
                React.createElement(JiraFailedBuildStatusIcon, { label: "Error", size: "medium" }))),
            state === 'tbd' && (React.createElement(IconWrap, null,
                React.createElement(Spinner, { color: "blue" }))),
            state === 'success' && (React.createElement(IconWrap, { "$color": "#0EAC1B" },
                React.createElement(CheckCircleIcon, { label: "Ok", size: "medium" })))),
        React.createElement("div", null, children),
        tip ? (React.createElement("div", null,
            React.createElement(Hint, null, tip))) : (React.createElement(React.Fragment, null))));
}
const ChecklistItemRoot = styled.div.withConfig({ displayName: "ChecklistItemRoot", componentId: "sc-10dh8x2" }) `
    align-items: center;
    display: grid;
    gap: 20px;
    grid-template-columns: 24px 1fr 24px;
    padding: 12px 24px;

    @media ${TABLET} {
        padding: 16px 40px;
    }
`;
