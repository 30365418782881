import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS, DESKTOP, MEDIUM, TABLET } from '~/shared/utils/styled';
export const StatGrid = styled.div.withConfig({ displayName: "StatGrid", componentId: "sc-14q38um" }) `
    align-items: center;
    display: grid;
    gap: 20px;

    @media ${TABLET} {
        gap: 16px;
        grid-template-columns: repeat(
            ${({ children }) => Math.min(3, Math.max(1, React.Children.count(children)))},
            1fr
        );
    }

    @media ${DESKTOP} {
        gap: 24px;
        grid-template-columns: repeat(
            ${({ children, $desktopColumnCount: maxColumns = 4 }) => Math.min(maxColumns, Math.max(1, React.Children.count(children)))},
            1fr
        );
    }
`;
export function StatCell({ children, label = 'Label', tip, }) {
    return (React.createElement(StatCellRoot, null,
        React.createElement(StatCellLabel, null, label),
        React.createElement(StatCellBody, null,
            React.createElement(StatCellContent, null, children),
            tip && React.createElement(TipWrap, null, tip))));
}
export const StatCellLabel = styled.div.withConfig({ displayName: "StatCellLabel", componentId: "sc-e46dw8" }) `
    color: #868686;
    font-size: 14px;
    line-height: 24px;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
export const StatCellContent = styled.div.withConfig({ displayName: "StatCellContent", componentId: "sc-1cr91m2" }) `
    color: ${COLORS.primary};
    font-size: 18px;
    font-weight: ${MEDIUM};
    letter-spacing: -0.05em;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${TABLET} {
        font-size: 20px;
    }
`;
const StatCellBody = styled.div.withConfig({ displayName: "StatCellBody", componentId: "sc-ksn0fi" }) `
    ${({ children }) => React.Children.count(children) > 1 &&
    css `
            align-items: center;
            display: flex;
        `}
`;
const TipWrap = styled.div.withConfig({ displayName: "TipWrap", componentId: "sc-anlg9t" }) `
    flex-shrink: 0;
    margin-left: 8px;
`;
const StatCellRoot = styled.div.withConfig({ displayName: "StatCellRoot", componentId: "sc-1bzg6kh" }) `
    align-items: center;
    border: 1px solid ${COLORS.Border};
    border-radius: 12px;
    display: flex;
    gap: 8px;
    min-width: 0;
    padding: 16px 20px;

    ${StatCellLabel} {
        flex-grow: 1;
    }

    @media ${TABLET} {
        border-radius: 16px;
        display: block;
        padding: 32px 40px;

        ${StatCellLabel} {
            margin-bottom: 10px;
        }
    }
`;
