import React from 'react';
import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';
import SvgIcon from '~/shared/components/SvgIcon';
// TODO add typing
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '0',
        '&:hover': {
            path: {
                stroke: '#A3A3A3',
            },
        },
        backgroundColor: state.isDisabled ? '#EFEFEF' : provided.backgroundColor,
        opacity: state.isDisabled ? 0.5 : 1,
        backfaceVisibility: 'hidden',
        color: state.isDisabled ? '#32323280' : '#323232',
        border: state.isFocused ? '1px solid #0324FF' : '1px solid #EFEFEF',
        borderRadius: '4px',
        height: '40px',
        boxShadow: 'none',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        pointerEvents: 'auto',
        fontSize: '1rem',
        letterSpacing: '0',
        lineHeight: '2rem',
        width: '100%',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#32323280' : '#323232',
        marginRight: '8px',
    }),
    indicatorSeparator: () => ({}),
    menu: (provided) => ({
        ...provided,
        marginTop: '0.5rem',
        padding: '0',
        zIndex: '10',
    }),
    menuList: (provided) => ({ ...provided, margin: '0.2rem 0', padding: '0' }),
    option: (provided, state) => ({
        ...provided,
        display: 'flex',
        textAlign: 'left',
        padding: '0 1rem',
        paddingLeft: '1rem',
        color: '#323232',
        position: 'relative',
        backgroundColor: state.isSelected || state.isFocused ? '#f8f8f8' : null,
        '&:active': {
            backgroundColor: '#f8f8f8',
        },
        lineHeight: '2rem',
        alignItems: 'center',
    }),
    placeholder: () => ({
        color: '#CDCDCD',
        lineHeight: '1rem',
        position: 'absolute',
        left: '16px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 1rem',
        color: '#323232',
        lineHeight: '1rem',
        overflow: 'visible',
    }),
    singleValue: (provided) => ({
        ...provided,
        margin: 0,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    }),
};
const Control = ({ className, children, ...props }) => {
    const { controlClassName } = props.selectProps;
    return (React.createElement(components.Control, { ...props, className: `${className || ''} ${controlClassName || ''}`.trim() }, children));
};
const EllipsisSpan = styled.span.withConfig({ displayName: "EllipsisSpan", componentId: "sc-1qf15oz" }) `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const EllipsisSpanWithTickMargin = styled(EllipsisSpan).withConfig({ displayName: "EllipsisSpanWithTickMargin", componentId: "sc-nqet0j" }) `
    margin-right: 18px;
`;
const UnstyledTick = (props) => React.createElement(SvgIcon, { ...props, name: "tick" });
const Tick = styled(UnstyledTick).withConfig({ displayName: "Tick", componentId: "sc-8tgos6" }) `
    height: 8px;
    right: 12px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
`;
const OptionIconWrapper = styled.div.withConfig({ displayName: "OptionIconWrapper", componentId: "sc-1wwq1j1" }) `
    width: 20px;
    margin-right: 0.5rem;
`;
const IconOption = (props) => (React.createElement(components.Option, { ...props },
    props.isSelected && React.createElement(Tick, null),
    props.data.icon != null && (React.createElement(OptionIconWrapper, null, props.data.icon)),
    React.createElement(EllipsisSpanWithTickMargin, null, props.data.label)));
const Caret = styled(SvgIcon).withConfig({ displayName: "Caret", componentId: "sc-1rjc8rn" }) `
    height: 8px;
    width: 10px;
    transition: transform 180ms ease-in-out;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : '')};
`;
const DropdownIndicator = (props) => components.DropdownIndicator && (React.createElement(components.DropdownIndicator, { ...props },
    React.createElement(Caret, { name: "caretDown", "$isOpen": props.selectProps.menuIsOpen })));
const IconWrapper = styled.div.withConfig({ displayName: "IconWrapper", componentId: "sc-1p94s1j" }) `
    width: 24px;
    margin-right: 0.5rem;
`;
const SingleValue = ({ children, ...props }) => {
    const { icon } = props.getValue()[0] || {};
    return (React.createElement(components.SingleValue, { ...props },
        icon != null && React.createElement(IconWrapper, null, icon),
        React.createElement(EllipsisSpan, null, children)));
};
const ClearIndicator = () => React.createElement(React.Fragment, null);
const UnstyledSelect = ({ controlClassName, required = false, clearable = true, disabled, ...props }) => (React.createElement(ReactSelect, { styles: customStyles, components: {
        Control,
        IndicatorSeparator: null,
        Option: IconOption,
        DropdownIndicator,
        SingleValue,
        ClearIndicator,
    }, isMulti: false, className: controlClassName, required: required, isClearable: clearable, isDisabled: disabled, isSearchable: false, ...props }));
const Select = styled(UnstyledSelect).withConfig({ displayName: "Select", componentId: "sc-1pupo8a" }) `
    font-size: 0.875rem;
`;
/**
 * @deprecated
 * Replaced by SelectField2 due to redesign of the Hub
 */
export default Select;
