import { gql } from '@apollo/client';
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["Asc"] = "ASC";
    OrderDirection["Desc"] = "DESC";
})(OrderDirection || (OrderDirection = {}));
export var StreamOrderBy;
(function (StreamOrderBy) {
    StreamOrderBy["BytesPerSecond"] = "BYTES_PER_SECOND";
    StreamOrderBy["Description"] = "DESCRIPTION";
    StreamOrderBy["Id"] = "ID";
    StreamOrderBy["MessagesPerSecond"] = "MESSAGES_PER_SECOND";
    StreamOrderBy["PeerCount"] = "PEER_COUNT";
    StreamOrderBy["PublisherCount"] = "PUBLISHER_COUNT";
    StreamOrderBy["SubscriberCount"] = "SUBSCRIBER_COUNT";
})(StreamOrderBy || (StreamOrderBy = {}));
export const GetStreamsDocument = gql `
    query getStreams($streamIds: [String!], $first: Int, $orderBy: StreamOrderBy, $orderDirection: OrderDirection, $search: String, $owner: String, $cursor: String) {
  streams(
    pageSize: $first
    ids: $streamIds
    orderBy: $orderBy
    orderDirection: $orderDirection
    owner: $owner
    searchTerm: $search
    cursor: $cursor
  ) {
    items {
      bytesPerSecond
      description
      id
      messagesPerSecond
      peerCount
      publisherCount
      subscriberCount
    }
    cursor
  }
}
    `;
export const GetGlobalStreamsStatsDocument = gql `
    query getGlobalStreamsStats {
  summary {
    bytesPerSecond
    messagesPerSecond
    streamCount
  }
}
    `;
