import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from '~/shared/components/Skeleton';
import { MEDIUM, REGULAR, MD } from '~/shared/utils/styled';
const Name = styled.div.withConfig({ displayName: "Name", componentId: "sc-potrb" }) ``;
const Secondary = styled.div.withConfig({ displayName: "Secondary", componentId: "sc-eu8zg6" }) `
    font-size: 16px;
    font-weight: ${REGULAR};
`;
const Description = styled(Secondary).withConfig({ displayName: "Description", componentId: "sc-162w7ua" }) `
    color: #323232;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-weight: ${REGULAR};
    padding-top: 8px;
`;
const Label = styled(Secondary).withConfig({ displayName: "Label", componentId: "sc-1gztiax" }) `
    text-align: right;
`;
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-1429pub" }) `
    color: #323232;
    font-size: 18px;
    font-weight: ${MEDIUM};
    line-height: 24px;
    padding-top: 16px;

    ${({ label }) => label &&
    css `
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 0.5em;
        `};

    @media (min-width: ${MD}px) {
        padding-top: 24px;
    }
`;
const Summary = ({ name, description, label, skeletonize, ...props }) => (React.createElement(Root, { ...props, label: label },
    React.createElement("div", null,
        React.createElement(Name, null,
            React.createElement(Skeleton, { disabled: !skeletonize }, name)),
        !!description && (React.createElement(Description, null,
            React.createElement(Skeleton, { disabled: !skeletonize, width: "45%" }, description)))),
    !!label && (React.createElement(Label, null,
        React.createElement(Skeleton, { disabled: !skeletonize, width: "30%" }, label)))));
export default Summary;
