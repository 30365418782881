export default class Transaction {
    constructor(emitter) {
        this.emitter = emitter;
    }
    onTransactionHash(cb) {
        this.emitter.on('transactionHash', cb);
        return this;
    }
    onTransactionComplete(cb) {
        this.emitter.on('receipt', cb);
        return this;
    }
    onError(cb) {
        this.emitter.on('error', cb);
        return this;
    }
}
