import React from 'react';
import styled from 'styled-components';
import ImageFilePicker from '~/components/ImageFilePicker';
import { COLORS, DESKTOP } from '~/shared/utils/styled';
import PngIcon from '~/shared/components/PngIcon';
const Radius = 16;
export const Wide = 1100;
export default function CoverImage({ disabled = false, onChange, src, }) {
    return (React.createElement(Root, null,
        React.createElement(ImageFilePicker, { disabled: disabled, onDrop: onChange },
            React.createElement(Dropzone, null,
                React.createElement(Placeholder, null,
                    React.createElement(PngIcon, { name: "imageUpload", alt: "" }),
                    React.createElement(Mobile, null, "Tap to take a photo or browse for one"),
                    React.createElement(Desktop, null, src ? (React.createElement(React.Fragment, null, "Drag & drop to replace your cover image or\u00A0click to browse for one")) : (React.createElement(React.Fragment, null, "Drag & drop to upload a cover image or\u00A0click to browse for one")))),
                src && (React.createElement(React.Fragment, null,
                    React.createElement(Preview, { as: "div" }),
                    React.createElement(Preview, { src: src, alt: "New image" })))))));
}
const Mobile = styled.p.withConfig({ displayName: "Mobile", componentId: "sc-iqvs27" }) `
    @media ${DESKTOP} {
        display: none;
    }
`;
const Desktop = styled.p.withConfig({ displayName: "Desktop", componentId: "sc-fkr4xt" }) `
    display: none;

    @media ${DESKTOP} {
        display: block;
    }
`;
export const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-m95a6g" }) `
    max-width: 100%;
    position: relative;

    @media (min-width: ${Wide}px) {
        width: 400px;
    }
`;
const Preview = styled.img.withConfig({ displayName: "Preview", componentId: "sc-8sfyit" }) `
    display: block;
    background: white;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    visibility: visible;
    opacity: 1;
    transition: 0.5s;
    transition-property: visibility, opacity;

    & + & {
        border-radius: ${Radius}px;
    }
`;
const Dropzone = styled.div.withConfig({ displayName: "Dropzone", componentId: "sc-18sgfc" }) `
    align-items: center;
    aspect-ratio: 1 / 1;
    background-color: ${COLORS.primaryLight};
    border-radius: ${Radius}px;
    color: #a3a3a3;
    display: flex;
    flex-direction: row;
    font-weight: 500;
    justify-content: center;
    padding: 0 3em;
    text-align: center;
    width: 100%;

    :hover ${Preview} {
        opacity: 0;
        transition-delay: 0.05s, 0s;
        transition-duration: 0.05s;
        visibility: hidden;
    }
`;
const Placeholder = styled.div.withConfig({ displayName: "Placeholder", componentId: "sc-13y3kyh" }) `
    transform: translateY(3%);

    > img {
        margin: 0 auto;
    }

    p {
        line-height: 1.75em;
        margin: 2em 0 0;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    }
`;
