import React, { useMemo } from 'react';
import styled from 'styled-components';
import { isAddress } from 'web3-validator';
import { FallbackImage as UnstyledFallbackImage } from '~/components/FallbackImage';
import SvgIcon from '~/shared/components/SvgIcon';
import Initials from './Initials';
import Identicon from './Identicon';
const FallbackImage = styled(UnstyledFallbackImage).withConfig({ displayName: "FallbackImage", componentId: "sc-yikr6x" }) `
    display: block;
    height: 100%;
    width: 100%;
`;
const UnstyledAvatarImage = ({ username, name, src, upload = false, ...props }) => {
    const placeholder = useMemo(() => {
        if (isAddress(username)) {
            return React.createElement(Identicon, { id: username });
        }
        if (name) {
            const initials = (name || '')
                .split(/\s+/)
                .filter(Boolean)
                .map((s) => s[0])
                .slice(0, 2)
                .join('')
                .toUpperCase();
            if (initials) {
                return React.createElement(Initials, null, initials);
            }
        }
        return React.createElement(SvgIcon, { name: upload ? 'emptyAvatarUpload' : 'profileMan' });
    }, [username, name, upload]);
    return (React.createElement("div", { ...props },
        React.createElement(FallbackImage, { alt: name || '', src: src || '', placeholder: placeholder })));
};
const AvatarImage = styled(UnstyledAvatarImage).withConfig({ displayName: "AvatarImage", componentId: "sc-1f8ub5y" }) ``;
export default AvatarImage;
export const HubAvatar = styled(Identicon).withConfig({ displayName: "HubAvatar", componentId: "sc-1cycps8" }) `
    width: 32px;
    height: 32px;
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    background-color: white;
`;
export const HubImageAvatar = styled(UnstyledFallbackImage).withConfig({ displayName: "HubImageAvatar", componentId: "sc-1upsyze" }) `
    width: 32px;
    height: 32px;
    border: 1px solid #f3f3f3;
    border-radius: 50%;
    background-color: white;
`;
