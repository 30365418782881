import React, { useState } from 'react';
import styled from 'styled-components';
import { Bits, matchBits, setBits, unsetBits } from '~/parsers/StreamParser';
import SvgIcon from '~/shared/components/SvgIcon';
import { useWalletAccount } from '~/shared/stores/wallet';
import { COLORS } from '~/shared/utils/styled';
import { truncate } from '~/shared/utils/text';
import { StreamDraft } from '~/stores/streamDraft';
import UnstyledPermissionEditor from './PermissionEditor';
export function PermissionItem(props) {
    const { disabled = false, address, permissionBits } = props;
    const [isOpen, setIsOpen] = useState(false);
    const account = useWalletAccount();
    const operations = Object.keys(Bits).filter((permission) => matchBits(Bits[permission], permissionBits));
    const update = StreamDraft.useUpdateEntity();
    return (React.createElement(Container, null,
        React.createElement(Title, { "$isOpen": isOpen, onClick: () => {
                setIsOpen((prev) => !prev);
            } },
            isOpen ? address : truncate(address),
            isOpen ? (React.createElement("div", null)) : (React.createElement(Labels, null,
                account?.toLowerCase() === address.toLowerCase() && (React.createElement(YouLabel, null, "You")),
                operations.map((op) => (React.createElement(Label, { key: op }, op.replace(/^\w/, (s) => s.toUpperCase())))))),
            React.createElement(DropdownCaret, { name: "caretDown", "$isOpen": isOpen })),
        isOpen && (React.createElement(PermissionEditor, { address: address, permissionBits: permissionBits, disabled: disabled, onChange: (permission, enabled) => {
                update((hot, cold) => {
                    if (cold.permissions[address] == null) {
                        cold.permissions[address] = 0;
                    }
                    hot.permissions[address] = (enabled ? setBits : unsetBits)(permissionBits, Bits[permission]);
                });
            } }))));
}
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1xvusws" }) `
    background: #ffffff;
    border-radius: 4px;
    display: grid;
    grid-template-rows: 1fr auto auto;
    align-items: center;
    transition: all 180ms ease-in-out;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-s68zva" }) `
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    align-items: center;
    border-bottom: ${({ $isOpen }) => ($isOpen ? '1px' : '0px')} solid ${COLORS.Border};
    height: 72px;
    padding: 21px;

    &:hover {
        cursor: pointer;
    }
`;
const Labels = styled.div.withConfig({ displayName: "Labels", componentId: "sc-1qxgbcj" }) `
    display: flex;
    overflow: hidden;
`;
const Label = styled.div.withConfig({ displayName: "Label", componentId: "sc-8cz70z" }) `
    color: ${COLORS.primary};
    background-color: ${COLORS.secondary};
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
    margin-right: 8px;
`;
const YouLabel = styled(Label).withConfig({ displayName: "YouLabel", componentId: "sc-1gon3k3" }) `
    color: white;
    background-color: ${COLORS.primaryLight};
`;
const PermissionEditor = styled(UnstyledPermissionEditor).withConfig({ displayName: "PermissionEditor", componentId: "sc-wo864x" }) `
    padding: 21px;
`;
const DropdownCaret = styled(SvgIcon).withConfig({ displayName: "DropdownCaret", componentId: "sc-3xm4ma" }) `
    display: block;
    color: ${COLORS.primaryLight};
    width: 12px;
    height: 12px;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: transform 180ms ease-in-out;
`;
