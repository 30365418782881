import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { toaster } from 'toasterhea';
import BaseModal from '~/modals/BaseModal';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import { Layer } from '~/utils/Layer';
import { RejectionReason } from '~/utils/exceptions';
const ConfirmationModal = ({ title, description, proceedLabel, cancelLabel, isDangerous = false, onResolve, ...props }) => {
    return (React.createElement(BaseModal, { ...props }, (close) => (React.createElement(ModalBody, null,
        React.createElement(Icon, null,
            React.createElement(IconWrap, { "$color": isDangerous ? '#ff0f2d' : '#6240AF' },
                React.createElement(InfoIcon, { label: "Info" }))),
        React.createElement(Title, { "$isDangerous": isDangerous }, title),
        React.createElement(CloseButton, { type: "button", onClick: () => void close(RejectionReason.CloseButton) },
            React.createElement(SvgIcon, { name: "crossMedium" })),
        React.createElement(Description, null, description),
        React.createElement(DecisionButtons, null,
            React.createElement(DecisionButton, { onClick: () => void onResolve?.(true), "$isDangerous": isDangerous }, proceedLabel),
            React.createElement(Dot, null),
            React.createElement(DecisionButton, { onClick: () => close(RejectionReason.CloseButton) }, cancelLabel))))));
};
export const confirmationModal = toaster(ConfirmationModal, Layer.Modal);
const ModalBody = styled.div.withConfig({ displayName: "ModalBody", componentId: "sc-nq0bfj" }) `
    display: grid;
    grid-template-columns: 24px auto 24px;
    grid-template-rows: auto auto auto;
    padding: 20px 16px;
    grid-gap: 16px;
    width: 400px;
`;
const Icon = styled.div.withConfig({ displayName: "Icon", componentId: "sc-1nh3n5s" }) `
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 1;
    grid-column-end: 1;
    width: 24px;
    height: 24px;
`;
const CloseButton = styled.button.withConfig({ displayName: "CloseButton", componentId: "sc-1479row" }) `
    color: ${COLORS.close};
    line-height: 14px;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    background: none;
    outline: none;
    border: none;
    > svg {
        width: 12px;
        height: 12px;
    }
`;
const Title = styled.p.withConfig({ displayName: "Title", componentId: "sc-1sebjmn" }) `
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 2;
    grid-column-end: 2;
    font-weight: ${MEDIUM};
    margin: 0;
    font-size: 16px;
    color: ${({ $isDangerous }) => ($isDangerous ? '#ff0f2d' : 'inherit')};
`;
const Description = styled.p.withConfig({ displayName: "Description", componentId: "sc-1g5t9t4" }) `
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 2;
    margin: 0;
    font-size: 14px;
    line-height: 20px;
`;
const DecisionButtons = styled.div.withConfig({ displayName: "DecisionButtons", componentId: "sc-yjnuna" }) `
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 2;
    display: flex;
    align-items: center;
    gap: 8px;
`;
const DecisionButton = styled.p.withConfig({ displayName: "DecisionButton", componentId: "sc-saah94" }) `
    margin: 0;
    color: ${({ $isDangerous }) => ($isDangerous ? '#ff0f2d' : COLORS.link)};
    cursor: pointer;
`;
const Dot = styled.div.withConfig({ displayName: "Dot", componentId: "sc-xnw5m9" }) `
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${COLORS.primary};
`;
const IconWrap = styled.div.withConfig({ displayName: "IconWrap", componentId: "sc-1hmzinb" }) `
    height: 24px;
    width: 24px;
    position: relative;
    display: flex;
    items-center;
    justify-content: center;
    flex-shrink: 0;
    margin-right: 16px;
    color: ${({ $color = 'inherit' }) => $color};
`;
