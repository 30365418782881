import React from 'react';
import styled from 'styled-components';
import { LoadMoreButton } from '~/components/LoadMore';
const StyledContainer = styled.div.withConfig({ displayName: "StyledContainer", componentId: "sc-12m0yn3" }) `
    display: flex;
    justify-content: center;
`;
const LoadMore = ({ onClick, hasMoreSearchResults, preserveSpace, className }) => {
    if (!hasMoreSearchResults) {
        return preserveSpace ? React.createElement(StyledContainer, { className: className }) : null;
    }
    return (React.createElement(StyledContainer, { className: className },
        React.createElement(LoadMoreButton, { onClick: onClick, kind: "primary2" }, "Load more")));
};
export default LoadMore;
