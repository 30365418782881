import React from 'react';
import { EmptyState } from '~/components/EmptyState';
import emptyStateIcon from '~/shared/assets/images/empty_state_icon.png';
import emptyStateIcon2x from '~/shared/assets/images/empty_state_icon@2x.png';
import { useCurrentChainFullName } from '~/utils/chains';
export default function NoProductsView({ noOwnProjects = false }) {
    const fullChainName = useCurrentChainFullName();
    return (React.createElement(EmptyState, { image: React.createElement("img", { src: emptyStateIcon, srcSet: `${emptyStateIcon2x} 2x`, alt: "Not found" }) }, noOwnProjects ? (React.createElement("p", null,
        React.createElement("span", null,
            "You haven't created any projects on the ",
            fullChainName,
            " chain yet"))) : (React.createElement("p", null,
        React.createElement("span", null, "We couldn't find anything to match your search"),
        React.createElement("small", null,
            "You are on the ",
            fullChainName,
            " chain. Please try some different keywords or switch chains.")))));
}
