import React from 'react';
import styled from 'styled-components';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import Spinner from '~/components/Spinner';
export const Alert = ({ type, title, className, children }) => {
    return (React.createElement(AlertWrap, { "$type": type, className: className },
        React.createElement(AlertIcon, { type: type }),
        React.createElement("div", null,
            React.createElement(Title, null, title),
            children != null && React.createElement(Content, null, children))));
};
const AlertIcon = ({ type }) => {
    switch (type) {
        case 'success':
            return React.createElement(StyledIcon, { name: "checkmark" });
        case 'error':
            return React.createElement(StyledIcon, { name: "warnBadge" });
        case 'loading':
            return React.createElement(StyledSpinner, { color: "blue", size: "medium" });
        case 'notice':
            return React.createElement(StyledIcon, { name: "infoBadge" });
    }
};
const getAlertBackgroundColor = ({ $type }) => {
    switch ($type) {
        case 'success':
            return COLORS.alertSuccessBackground;
        case 'error':
            return COLORS.alertErrorBackground;
        case 'loading':
        case 'notice':
            return COLORS.alertInfoBackground;
        default:
            return 'transparent';
    }
};
const AlertWrap = styled.div.withConfig({ displayName: "AlertWrap", componentId: "sc-11lnu4q" }) `
    display: grid;
    grid-template-columns: 24px auto;
    gap: 16px;
    padding: 20px 16px;
    border-radius: 8px;
    background-color: ${getAlertBackgroundColor};
`;
const StyledIcon = styled(SvgIcon).withConfig({ displayName: "StyledIcon", componentId: "sc-1ertfhs" }) `
    width: 22px;
    height: 22px;
    flex-shrink: 0;
`;
const StyledSpinner = styled(Spinner).withConfig({ displayName: "StyledSpinner", componentId: "sc-1yg7d5c" }) `
    align-self: baseline !important;
    flex-shrink: 0;
`;
const Title = styled.p.withConfig({ displayName: "Title", componentId: "sc-1ghwuxd" }) `
    font-weight: ${MEDIUM};
    line-height: 20px;
    margin: 0;
`;
const Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-17lwvxa" }) `
    margin-top: 4px;
    line-height: 20px;
`;
