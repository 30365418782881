export default class InsufficientFundsError extends Error {
    constructor(address) {
        super(`"${address}" does not have enough funds to cover the cost of gas`);
        this.name = 'InsufficientFundsError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InsufficientFundsError);
        }
        Object.setPrototypeOf(this, InsufficientFundsError.prototype);
    }
}
