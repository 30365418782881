import React from 'react';
import { createPortal } from 'react-dom';
import { Context } from '~/shared/contexts/ModalPortal';
import NoModalRootError from '~/shared/errors/NoModalRootError';
class ModalPortal extends React.Component {
    constructor() {
        super(...arguments);
        this.root = document.createElement('div');
    }
    componentDidMount() {
        this.modalRoot = document.getElementById('modal-root');
        const { registerModal } = this.context || {};
        if (!this.modalRoot) {
            throw new NoModalRootError();
        }
        this.modalRoot.appendChild(this.root);
        registerModal?.();
    }
    componentWillUnmount() {
        const { unregisterModal } = this.context || {};
        const { modalRoot, root } = this;
        modalRoot?.removeChild(root);
        unregisterModal?.();
    }
    render() {
        return createPortal(this.props.children, this.root);
    }
}
ModalPortal.contextType = Context;
export default ModalPortal;
