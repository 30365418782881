import React, { useState, forwardRef, useEffect, useCallback } from 'react';
const sanitizeValue = (value) => (value != null ? value : '');
const RevertOnEscapeDecorator = (WrappedComponent) => {
    const RevertOnEscapeDecoratorWrapper = ({ value: valueProp, onKeyDown: onKeyDownProp, onChange: onChangeProp, ...props }, ref) => {
        const [value, setValue] = useState(sanitizeValue(valueProp));
        useEffect(() => {
            setValue(sanitizeValue(valueProp));
        }, [valueProp]);
        const onKeyDown = useCallback((e) => {
            if (e.key === 'Escape') {
                setValue(sanitizeValue(valueProp));
            }
            if (onKeyDownProp) {
                onKeyDownProp(e);
            }
        }, [valueProp, onKeyDownProp]);
        const onChange = useCallback((e) => {
            setValue(e.target.value);
            if (onChangeProp) {
                onChangeProp(e);
            }
        }, [onChangeProp]);
        return (React.createElement(WrappedComponent, { ...props, onChange: onChange, onKeyDown: onKeyDown, ref: ref, value: value }));
    };
    const RevertOnEscapeDecoratorWrapperFR = forwardRef(RevertOnEscapeDecoratorWrapper);
    const OptInRevertOnEscapeDecoratorWrapper = ({ revertOnEscape, ...props }, ref) => revertOnEscape ? (React.createElement(RevertOnEscapeDecoratorWrapperFR, { ...props, ref: ref })) : (React.createElement(WrappedComponent, { ...props, ref: ref }));
    return forwardRef(OptInRevertOnEscapeDecoratorWrapper);
};
export default RevertOnEscapeDecorator;
