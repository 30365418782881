import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { maxFileSizeForImageUpload, maxFileSizeForImageUpload as maxSize, } from '~/shared/utils/constants';
import useIsMounted from '~/shared/hooks/useIsMounted';
import { errorToast } from '~/utils/toast';
export default function ImageFilePicker({ children, disabled = false, onDrop, onReject, ...props }) {
    const isMounted = useIsMounted();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
        },
        maxSize,
        maxFiles: 1,
        async onDropAccepted([file]) {
            onDrop?.(file);
        },
        onDropRejected([{ file }]) {
            if (onReject) {
                return void onReject(file);
            }
            if (!isMounted()) {
                return;
            }
            if (file.size > maxFileSizeForImageUpload) {
                errorToast({
                    title: `Image file size must be less than ${0 | (maxFileSizeForImageUpload / 1e6)}MB`,
                });
            }
        },
        disabled,
    });
    return (React.createElement(ImageFilePickerRoot, { ...getRootProps(props), "area-disabled": disabled, "$isDragActive": isDragActive },
        React.createElement("input", { ...getInputProps() }),
        children));
}
const ImageFilePickerRoot = styled.div.withConfig({ displayName: "ImageFilePickerRoot", componentId: "sc-16x59kj" }) `
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &[area-disabled] {
        opacity: 0.5;
    }
`;
