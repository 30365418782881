import React from 'react';
import styled from 'styled-components';
import { SANS } from '~/shared/utils/styled';
import BaseModal from './BaseModal';
/**
 * `BaseModal` with centered title bar with a subtle bottom border.
 */
export default function Modal({ children, title = 'Untitled modal', ...props }) {
    return (React.createElement(BaseModal, { ...props },
        React.createElement(Root, null,
            React.createElement(Head, null,
                React.createElement(Title, null, title)),
            children)));
}
const Root = styled.div.withConfig({ displayName: "Root", componentId: "sc-mociql" }) `
    max-width: 560px;
    width: 90vw;
`;
const Head = styled.div.withConfig({ displayName: "Head", componentId: "sc-109s1rk" }) `
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    display: flex;
    font-family: ${SANS};
    font-size: 20px;
    font-weight: normal;
    height: 80px;
    line-height: 32px;
    margin: 0;
    position: relative;
    text-align: center;
    width: 100%;
`;
const Title = styled.div.withConfig({ displayName: "Title", componentId: "sc-309veq" }) `
    flex-grow: 1;
`;
