import { Contract } from 'ethers';
import { toBN } from '~/utils/bn';
import { getContractAbi } from '~/utils/contracts';
import { getPublicProvider } from '~/utils/providers';
/**
 * Scouts for Operator's funding share.
 */
export function getSponsorshipStakeForOperator(stakes, operatorId) {
    return stakes.find((stake) => stake.operatorId === operatorId);
}
/**
 * Checks if a given Operator funds a given Sponsorship.
 */
export function isSponsorshipFundedByOperator(sponsorship, operator) {
    if (operator == null) {
        return false;
    }
    const operatorStake = getSponsorshipStakeForOperator(sponsorship.stakes, operator.id);
    return operatorStake != null && operatorStake.amountWei > 0n;
}
/**
 * Gets the current Sponsorship leave penalty for a given Operator.
 */
export async function getSponsorshipLeavePenalty(chainId, sponsorshipId, operatorId) {
    const provider = await getPublicProvider(chainId);
    const contract = new Contract(sponsorshipId, getContractAbi('sponsorship'), provider);
    return contract.getLeavePenalty(operatorId);
}
export function getSponsorshipExtensionInDays(amount, dailyPayoutRate) {
    return dailyPayoutRate > 0n && amount >= 0n
        ? toBN(amount).dividedBy(toBN(dailyPayoutRate)).toNumber()
        : 0;
}
