import { produce } from 'immer';
import uniqueId from 'lodash/uniqueId';
import { useCallback, useEffect, useReducer } from 'react';
import { create } from 'zustand';
import { history } from '~/consts';
const useBlockerStore = create((set) => {
    return {
        blockers: {},
        register(blocker) {
            const id = uniqueId('blocker-');
            set((current) => produce(current, (next) => {
                next.blockers[id] = blocker;
            }));
            return () => {
                set((current) => produce(current, (next) => {
                    delete next.blockers[id];
                }));
            };
        },
    };
});
function useBeforeUnload(callback, options) {
    const { capture } = options || {};
    useEffect(() => {
        const opts = capture != null ? { capture } : undefined;
        window.addEventListener('hub_beforeunload', callback, opts);
        return () => {
            window.removeEventListener('hub_beforeunload', callback, opts);
        };
    }, [callback, capture]);
}
export default function usePreventNavigatingAway({ message = 'You have unsaved changes. Are you sure you want to leave?', isDirty, }) {
    const { register } = useBlockerStore();
    useEffect(() => {
        return register({
            message,
            isDirty,
        });
    }, [register, isDirty, message]);
    useBeforeUnload(useCallback((e) => {
        /**
         * Limitations of `history.block` don't apply to the `beforeunload`
         * events. We can register as many as we want and the browser will
         * take care of the rest.
         */
        if (isDirty()) {
            e.returnValue = message;
            return message;
        }
        return '';
    }, [isDirty, message]));
}
function patchBeforeUnload() {
    /**
     * `history` forces in its own `beforeunload` event handler which makes the user
     * always having to confirm a leave. Here we disable it in the hackiest of ways.
     *
     * We overwrite `Window#addEventListener` and `Window#removeEventListener` to make
     * them ignore "unauthorized" `beforeunload` and introduce `hub_beforeunload` event,
     * and let that through.
     *
     * This effectively disables all library-driven logic for that event. It also enforces
     * us to use the new event internally, too. Win some. Lose some.
     */
    if (window.addEventListener !== Window.prototype.addEventListener) {
        return;
    }
    function addEventListener(type, ...rest) {
        if (type === 'beforeunload') {
            return;
        }
        Window.prototype.addEventListener.call(window, type.replace(/^hub_/, ''), ...rest);
    }
    window.addEventListener = addEventListener;
    function removeEventListener(type, ...rest) {
        if (type === 'beforeunload') {
            return;
        }
        Window.prototype.removeEventListener.call(window, type.replace(/^hub_/, ''), ...rest);
    }
    window.removeEventListener = removeEventListener;
}
export function useBlockHistoryEffect() {
    const { blockers } = useBlockerStore();
    const [cache, bump] = useReducer((x) => x + 1, 0);
    /**
     * On each change to `blockers` we reblock history using the new set
     * of potential blockers.
     */
    useEffect(() => {
        patchBeforeUnload();
        const unblock = history.block(({ retry, location: { pathname } }) => {
            const blocker = Object.values(blockers).find((blocker) => {
                return blocker?.isDirty(pathname);
            });
            if (!blocker || confirm(blocker.message)) {
                unblock();
                retry();
                return () => { };
            }
            /**
             * If the user rejects the message thrown by `confirm` we have to manually
             * reblock routing for further attempts.
             */
            bump();
        });
        cache; // keep
        return unblock;
    }, [blockers, cache]);
}
