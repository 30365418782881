import React, { useState, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import { Slider } from '~/components/Slider';
import BaseModal, { Footer } from '~/modals/BaseModal';
import SvgIcon from '~/shared/components/SvgIcon';
import { FormModalCloseButton, FormModalContent, FormModalHead, FormModalRoot, FormModalTitle, } from '~/modals/FormModal';
import { Button } from '~/components/Button';
import { COLORS } from '~/shared/utils/styled';
import MaskSvg from '~/shared/assets/images/mask.svg';
import { RejectionReason } from '~/utils/exceptions';
const MAX_WIDTH = 1024;
// only width is considered because images returned from the cropper will always be squared
export const getCroppedAndResizedBlob = async (imageUrl, cropInfo) => {
    const imageElement = document.createElement('img');
    await new Promise((resolve) => {
        imageElement.onload = () => resolve(null);
        imageElement.src = imageUrl;
    });
    let canvas = document.createElement('canvas');
    const cropCanvasContext = canvas.getContext('2d');
    const x = Math.round(imageElement.width * cropInfo.x);
    const y = Math.round(imageElement.height * cropInfo.y);
    const width = Math.round(imageElement.width * cropInfo.width);
    const height = Math.round(imageElement.height * cropInfo.height);
    canvas.width = width;
    canvas.height = height;
    cropCanvasContext.drawImage(imageElement, x, y, width, height, 0, 0, width, height);
    if (canvas.width > MAX_WIDTH) {
        const resizedCanvas = document.createElement('canvas');
        const resizedCanvasContext = resizedCanvas.getContext('2d');
        // Start with original image size
        resizedCanvas.width = canvas.width;
        resizedCanvas.height = canvas.height;
        // Draw the original image on the (temp) resizing canvas
        resizedCanvasContext.drawImage(canvas, 0, 0);
        // Quickly reduce the size by 50% each time in few iterations until the size is less then
        // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
        // created with direct reduction of very big image to small image
        while (resizedCanvas.width * 0.5 > MAX_WIDTH) {
            resizedCanvas.width *= 0.5;
            resizedCanvas.height *= 0.5;
            resizedCanvasContext.drawImage(resizedCanvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
        }
        // Now do final resize for the resizingCanvas to meet the dimension requirments
        // directly to the output canvas, that will output the final image
        resizedCanvas.width = MAX_WIDTH;
        resizedCanvas.height = (resizedCanvas.width * canvas.height) / canvas.width;
        resizedCanvasContext.drawImage(canvas, 0, 0, resizedCanvas.width, resizedCanvas.height);
        canvas = resizedCanvas;
    }
    return await new Promise((resolve) => canvas.toBlob(resolve));
};
const CropImageModal = ({ imageUrl, onResolve = (file) => file, onReject = () => { }, title = 'Scale and crop your image', mask = 'none', ...props }) => {
    const editorRef = useRef(null);
    const [sliderValue, setSliderValue] = useState(1);
    const onSave = useCallback(async () => {
        if (editorRef.current) {
            const blob = await getCroppedAndResizedBlob(imageUrl, editorRef.current.getCroppingRect());
            if (!blob) {
                console.warn('Failed to get the blob');
                return;
            }
            onResolve(new File([blob], 'coverImage.png'));
        }
    }, [onResolve, editorRef, imageUrl]);
    return (React.createElement(BaseModal, { ...props, onReject: onReject }, (close) => (React.createElement(FormModalRoot, null,
        React.createElement(FormModalHead, null,
            React.createElement(FormModalTitle, null, title),
            React.createElement(FormModalCloseButton, { type: "button", onClick: close },
                React.createElement(SvgIcon, { name: "crossMedium" }))),
        React.createElement(FormModalContent, null,
            React.createElement("div", null,
                React.createElement(AvatarEditorWrap, null,
                    React.createElement(StyledAvatarEditor, { ref: editorRef, image: imageUrl, width: 1024, height: 1024, border: [0, 0], borderRadius: 0, color: [255, 255, 255, 0.6], scale: (100 + sliderValue) / 100, rotate: 0, "$mask": mask })),
                React.createElement(ZoomControls, null,
                    React.createElement(ZoomIcon, { name: 'zoomOut' }),
                    React.createElement(ZoomSlider, { min: 0, max: 200, value: sliderValue, onChange: setSliderValue }),
                    React.createElement(ZoomIcon, { name: 'zoomIn' })))),
        React.createElement(Footer, { "$borderless": true, "$autoHeight": true },
            React.createElement(ButtonsContainer, null,
                React.createElement(StyledButton, { onClick: onSave }, "Crop"),
                React.createElement(StyledButton, { kind: "secondary", onClick: () => void close(RejectionReason.CancelButton) }, "Cancel")))))));
};
export default CropImageModal;
const StyledAvatarEditor = styled(AvatarEditor).withConfig({ displayName: "StyledAvatarEditor", componentId: "sc-7y3vsd" }) `
    width: 100% !important;
    height: auto !important;

    ${({ $mask }) => $mask === 'round' &&
    css `
            mask: url(${MaskSvg}) center;
            mask-size: 100% 100%;
        `}
`;
const AvatarEditorWrap = styled.div.withConfig({ displayName: "AvatarEditorWrap", componentId: "sc-6nesfo" }) `
    display: flex;
    background-color: black;
`;
const ZoomSlider = styled(Slider).withConfig({ displayName: "ZoomSlider", componentId: "sc-nqk6hd" }) `
    width: 50%;
    min-width: 200px;
    margin: 0 12px;
`;
const ZoomControls = styled.div.withConfig({ displayName: "ZoomControls", componentId: "sc-29bw1i" }) `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
`;
const ZoomIcon = styled(SvgIcon).withConfig({ displayName: "ZoomIcon", componentId: "sc-15axdia" }) `
    color: ${COLORS.link};
    width: 20px;
`;
const ButtonsContainer = styled.div.withConfig({ displayName: "ButtonsContainer", componentId: "sc-oq5j55" }) `
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 40px 0 30px;
`;
const StyledButton = styled(Button).withConfig({ displayName: "StyledButton", componentId: "sc-8fy0qn" }) `
    margin-bottom: 10px;
    width: 100%;
`;
