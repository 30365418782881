import { useQuery } from '@tanstack/react-query';
import { getERC20TokenContract } from '~/getters';
import { getQueryClient } from '~/utils';
import { getPublicProvider } from '~/utils/providers';
export async function getTokenInfo(tokenAddress, chainId) {
    const tokenInfo = await getQueryClient().fetchQuery(tokenInfoQueryParams(chainId, tokenAddress));
    if (!tokenInfo || !tokenInfo.symbol) {
        throw new Error('Not an ERC-20 token');
    }
    return tokenInfo;
}
export function useTokenInfo(tokenAddress, chainId) {
    return useQuery(tokenInfoQueryParams(chainId, tokenAddress)).data;
}
function tokenInfoQueryParams(chainId, tokenAddress) {
    return {
        queryKey: ['tokenInfo', chainId, tokenAddress],
        queryFn: async () => {
            if (chainId == null || !tokenAddress) {
                return null;
            }
            const provider = await getPublicProvider(chainId);
            const contact = getERC20TokenContract({
                tokenAddress,
                provider,
            });
            try {
                return {
                    decimals: await contact.decimals(),
                    name: await contact.name(),
                    symbol: await contact.symbol(),
                };
            }
            catch (e) {
                console.warn(`Failed to fetch token info for ${tokenAddress}`, e);
            }
            return null;
        },
        staleTime: Infinity,
        gcTime: Infinity,
    };
}
export function getCachedTokenInfo(tokenAddress, chainId) {
    if (!tokenAddress || chainId == null) {
        return null;
    }
    return getQueryClient().getQueryData(tokenInfoQueryParams(chainId, tokenAddress).queryKey);
}
