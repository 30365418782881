import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { toaster } from 'toasterhea';
import { Alert } from '~/components/Alert';
import { SponsorshipDecimals } from '~/components/Decimals';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { useConfigValueFromChain, useMaxUndelegationQueueDays, useMediaQuery, } from '~/hooks';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import FormModal, { FieldWrap, MaxButton, Prop, PropList, PropValue, Section, SectionHeadline, TextAppendix, TextInput, } from '~/modals/FormModal';
import { undelegateFromOperator } from '~/services/operators';
import Label from '~/shared/components/Ui/Label';
import { useWalletAccount } from '~/shared/stores/wallet';
import { waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { toBN, toBigInt, toFloat } from '~/utils/bn';
import { RejectionReason, isRejectionReason, isTransactionRejection, } from '~/utils/exceptions';
function UndelegateFundsModal({ balance, chainId, delegatedTotal, onResolve, operator, ...props }) {
    const wallet = useWalletAccount();
    const isOwner = wallet?.toLowerCase() === operator.owner.toLowerCase();
    const [title, submitLabel, amountLabel, totalLabel, subtitlePartial, pastAction] = isOwner
        ? [
            'Withdraw from Operator',
            'Withdraw',
            'Amount to unstake',
            'Amount currently staked on Operator',
            'you wish to unstake from your Operator',
            'withdrawn',
        ]
        : [
            'Undelegate from Operator',
            'Undelegate',
            'Amount to undelegate',
            'Amount currently delegated to Operator',
            'to undelegate from the selected Operator',
            'undelegated',
        ];
    const [rawAmount, setRawAmount] = useState('');
    const maxUndelegationQueueDays = useMaxUndelegationQueueDays();
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    const amount = toBigInt(rawAmount || 0, decimals);
    const freeFunds = operator.dataTokenBalanceWei;
    const hasZeroDeployed = operator.totalStakeInSponsorshipsWei === 0n;
    const minimumSelfDelegationFraction = useConfigValueFromChain('minimumSelfDelegationFraction', (value) => toFloat(value, decimals)) || toBN(0);
    const minimumSelfDelegationPercentage = minimumSelfDelegationFraction
        .multipliedBy(100)
        .toNumber();
    const isSelfDelegationTooLow = isOwner &&
        minimumSelfDelegationFraction.isGreaterThan(0) &&
        delegatedTotal - amount <
            toBigInt(toBN(delegatedTotal).multipliedBy(minimumSelfDelegationFraction));
    const earliestUndelegationTimestamp = operator.delegations.find((d) => d.delegator.toLowerCase() === wallet?.toLowerCase())?.earliestUndelegationTimestamp;
    const isTooEarlyToUndelegate = operator.contractVersion > 0 &&
        !isOwner &&
        earliestUndelegationTimestamp != null &&
        earliestUndelegationTimestamp * 1000 > Date.now();
    const canSubmit = amount > 0n &&
        !(isSelfDelegationTooLow && !hasZeroDeployed) &&
        !isTooEarlyToUndelegate;
    const [busy, setBusy] = useState(false);
    const limitedSpace = useMediaQuery('screen and (max-width: 460px)');
    return (React.createElement(FormModal, { ...props, title: title, canSubmit: canSubmit && !busy, submitting: busy, submitLabel: submitLabel, onBeforeAbort: (reason) => !busy && (rawAmount === '' || reason !== RejectionReason.Backdrop), onSubmit: async () => {
            setBusy(true);
            try {
                await undelegateFromOperator(chainId, operator.id, amount >= delegatedTotal
                    ? /**
                       * If we are requesting all funds to be undelegated, send
                       * a truely big number instead of infinity.
                       */
                        110763745230805656649802800132303954225n
                    : amount, {
                    onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                });
                onResolve?.();
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(SectionHeadline, null,
            "Please enter the amount of ",
            React.createElement(SponsorshipPaymentTokenName, null),
            " tokens",
            ' ',
            subtitlePartial),
        React.createElement(Section, null,
            React.createElement(Label, { "$wrap": true }, amountLabel),
            React.createElement(FieldWrap, { "$top": true },
                React.createElement(TextInput, { name: "amount", autoFocus: true, onChange: ({ target }) => {
                        setRawAmount(target.value);
                    }, placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawAmount }),
                React.createElement(MaxButton, { onClick: () => {
                        setRawAmount(toFloat(delegatedTotal, decimals).toString());
                    } }),
                React.createElement(TextAppendix, null,
                    React.createElement(SponsorshipPaymentTokenName, null))),
            React.createElement(FieldWrap, { "$bottom": true, "$padded": true },
                React.createElement(Prop, null,
                    totalLabel,
                    ":",
                    ' ',
                    React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: delegatedTotal, tooltip: limitedSpace }))),
            React.createElement(PropList, null,
                React.createElement("li", null,
                    React.createElement(Prop, null, "Your wallet balance"),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: balance, tooltip: limitedSpace }))),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Operator"),
                    React.createElement(PropValue, null, operator.id)),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Available balance in Operator"),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: freeFunds, tooltip: limitedSpace }))))),
        React.createElement(Footer, null,
            amount > 0 && amount <= freeFunds && (React.createElement(Alert, { type: "notice", title: React.createElement(React.Fragment, null,
                    React.createElement(SponsorshipDecimals, { amount: amount }),
                    " will be",
                    ' ',
                    pastAction,
                    " immediately") })),
            amount > freeFunds && (React.createElement(Alert, { type: "notice", title: "Undelegation will be queued" },
                "Your undelegation will be queued for a maximum of",
                ' ',
                maxUndelegationQueueDays.toString(),
                " days, after which you will be able to force undelegation.")),
            isSelfDelegationTooLow && hasZeroDeployed && (React.createElement(Alert, { type: "error", title: "Low self-funding" },
                "At least ",
                minimumSelfDelegationPercentage.toFixed(0),
                "% of the Operator's total stake must come from you as the owner. After your withdrawal, your remaining amount will be below this limit. This will prevent your Operator from staking on Sponsorships. It will also signal to Delegators that you're shutting down, and will most likely cause them to undelegate.")),
            isSelfDelegationTooLow && !hasZeroDeployed && (React.createElement(Alert, { type: "error", title: "Low self-funding" },
                "At least ",
                minimumSelfDelegationPercentage.toFixed(0),
                "% of the Operator's total stake must come from you as the owner. If you wish to stop being an Operator, you can withdraw any amount once your Operator is not staked on any Sponsorships. Note that this prevents your Operator from staking on Sponsorships until the limit is reached again. It is also a strong signal to Delegators that you're shutting down, and will most likely cause them to undelegate.")),
            isTooEarlyToUndelegate && (React.createElement(Alert, { type: "error", title: React.createElement(React.Fragment, null,
                    "You can not undelegate because your minimum delegation period is still active. It will expire on",
                    ' ',
                    moment(earliestUndelegationTimestamp * 1000).format('YYYY-MM-DD HH:mm'),
                    ".") })))));
}
const Footer = styled.div.withConfig({ displayName: "Footer", componentId: "sc-1rbs3xj" }) `
    display: grid;
    gap: 8px;
    margin-top: 8px;
`;
export const undelegateFundsModal = toaster(UndelegateFundsModal, Layer.Modal);
