import InsufficientFundsError from '~/shared/errors/InsufficientFundsError';
import { getBalance } from '~/utils/balance';
export async function requirePositiveBalance(chainId, walletAddress) {
    const balance = await getBalance({
        chainId,
        tokenAddress: 'native',
        walletAddress,
    });
    if (balance <= 0n) {
        throw new InsufficientFundsError(walletAddress);
    }
}
