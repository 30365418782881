import React, { useEffect, useMemo, useState } from 'react';
import { useClient } from 'streamr-client-react';
import styled, { css } from 'styled-components';
import { EmptyState } from '~/components/EmptyState';
import emptyStateIcon from '~/shared/assets/images/empty_state_icon.png';
import emptyStateIcon2x from '~/shared/assets/images/empty_state_icon@2x.png';
import LoadingIndicator from '~/shared/components/LoadingIndicator';
import SvgIcon from '~/shared/components/SvgIcon';
import Errors from '~/shared/components/Ui/Errors';
import useStreamData from '~/shared/hooks/useStreamData';
import { COLORS } from '~/shared/utils/styled';
import Feed from './Feed';
import Foot from './Foot';
import IconButton from './IconButton';
import Selector from './Selector';
const InspectorButton = styled(IconButton).withConfig({ displayName: "InspectorButton", componentId: "sc-1y7klif" }) `
    width: 32px;
    height: 32px;
    text-align: center;
    position: relative;
    border: none;
    background: none;
    appearance: none;
    border-radius: 2px;
    color: #cdcdcd;

    &:hover,
    &:active,
    &:focus {
        background-color: #efefef;
        color: #525252;
    }

    ${({ active }) => !!active &&
    css `
            background-color: #efefef;
            color: #525252;
        `}
`;
const UnstyledStreamPreview = ({ streamsList, activePartition = 0, className, preselectedStreamId, previewDisabled = false, }) => {
    const client = useClient();
    const [inspectorFocused, setInspectorFocused] = useState(false);
    const [selectedStreamId, setSelectedStreamId] = useState(!!preselectedStreamId && streamsList.includes(preselectedStreamId)
        ? preselectedStreamId
        : streamsList[0]);
    const [partition, setPartition] = useState(activePartition);
    const [loading, setIsLoading] = useState();
    const [stream, setStream] = useState();
    const { partitions } = stream?.getMetadata() || {};
    const partitionOptions = useMemo(() => partitions ? [...new Array(partitions)].map((_, index) => index) : undefined, [partitions]);
    const streamData = useStreamData(selectedStreamId, {
        tail: 20,
        partition,
    });
    useEffect(() => {
        const loadStreamData = async () => {
            if (client) {
                setIsLoading(true);
                const result = await client.getStream(selectedStreamId);
                setIsLoading(false);
                setStream(result);
            }
        };
        loadStreamData();
    }, [selectedStreamId, client]);
    return (React.createElement(React.Fragment, null,
        React.createElement(LoadingIndicator, { loading: loading }),
        previewDisabled && (React.createElement(EmptyState, { image: React.createElement("img", { src: emptyStateIcon, srcSet: `${emptyStateIcon2x} 2x`, alt: "No permission to subscribe" }) },
            React.createElement("p", null,
                React.createElement("span", null,
                    "You do not have permission to",
                    React.createElement("br", null),
                    "subscribe to the stream.")))),
        !loading && (React.createElement(React.Fragment, null,
            !previewDisabled && (React.createElement(Feed, { className: className, inspectorFocused: inspectorFocused, streamData: streamData, streamLoaded: !loading, 
                // errorComponent={
                //     <Fragment>
                //         {!!dataError && <Errors>{dataError}</Errors>}
                //     </Fragment>
                // }
                onPartitionChange: setPartition, onSettingsButtonClick: undefined, onStreamChange: setSelectedStreamId, partition: partition, partitions: partitionOptions || [], streamId: selectedStreamId, streamIds: streamsList })),
            React.createElement(Foot, null,
                React.createElement("div", null,
                    React.createElement(InspectorButton, { active: !inspectorFocused, onClick: () => setInspectorFocused(false), type: "button" },
                        React.createElement(SvgIcon, { name: "list" }))),
                React.createElement("div", null,
                    React.createElement(InspectorButton, { active: !!inspectorFocused, onClick: () => setInspectorFocused(true), type: "button" },
                        React.createElement(SvgIcon, { name: "listInspect" }))),
                !inspectorFocused && !loading && (React.createElement("div", null,
                    React.createElement(Selector, { title: "Partitions", options: partitionOptions || [], active: partition, onChange: setPartition }))))))));
};
export const StreamPreview = styled(UnstyledStreamPreview).withConfig({ displayName: "StreamPreview", componentId: "sc-60uxsk" }) `
    background: #ffffff;
    color: #323232;
    font-size: 14px;
    border-top: 1px solid ${COLORS.Border};
    height: 100%;

    ${Errors} {
        margin: 20px 40px 0;
    }

    ${Errors} + ${Errors} {
        margin-top: 1em;
    }

    ${Errors}:last-child {
        padding-bottom: 20px;
    }
`;
