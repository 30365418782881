import CheckIcon from '@atlaskit/icon/glyph/check';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import styled, { css } from 'styled-components';
import { z } from 'zod';
import { StreamDescription } from '~/components/StreamDescription';
import { Tooltip, TooltipIconWrap } from '~/components/Tooltip';
import { HubAvatar, HubImageAvatar } from '~/shared/components/AvatarImage';
import { COLORS, MEDIUM } from '~/shared/utils/styled';
import { truncate, truncateStreamName } from '~/shared/utils/text';
import { getStreamGptApiUrl } from '~/utils';
import { getChainConfigExtension } from '~/utils/chains';
import { getSponsorshipStakeForOperator } from '~/utils/sponsorships';
import Spinner from './Spinner';
import { OperatorAvatar } from './avatars';
/**
 * Operator name and avatar formatter.
 */
export function OperatorIdCell({ operatorId, imageUrl, operatorName, truncate: truncateProp = false, }) {
    return (React.createElement(OperatorIdCellRoot, null,
        React.createElement(OperatorAvatarWrap, null,
            React.createElement(OperatorAvatar, { operatorId: operatorId, imageUrl: imageUrl })),
        React.createElement(OperatorName, { "$truncate": truncateProp }, operatorName || truncate(operatorId))));
}
const OperatorAvatarWrap = styled.div.withConfig({ displayName: "OperatorAvatarWrap", componentId: "sc-105cvqr" }) `
    height: 32px;
    width: 32px;
`;
const OperatorName = styled.div.withConfig({ displayName: "OperatorName", componentId: "sc-12fkijy" }) `
    ${({ $truncate = false }) => $truncate &&
    css `
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        `}
`;
const OperatorIdCellRoot = styled.div.withConfig({ displayName: "OperatorIdCellRoot", componentId: "sc-18wgd2r" }) `
    align-items: center;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
`;
/**
 * Deleted stream id formatter.
 */
function DeletedStreamIdCell() {
    return (React.createElement(StreamInfoCell, null,
        React.createElement(StreamAvatarWrap, null),
        React.createElement(StreamInfoCellOuter, null,
            React.createElement(StreamIdWrap, { as: "em" }, "(deleted stream)"))));
}
/**
 * Stream id and description formatter.
 */
export function StreamIdCell({ streamId = '', description, }) {
    const imageUrlQuery = useStreamImageUrlQuery(streamId);
    if (!streamId) {
        return React.createElement(DeletedStreamIdCell, null);
    }
    return (React.createElement(StreamInfoCell, null,
        React.createElement(StreamAvatarWrap, null,
            React.createElement(HubImageAvatar, { src: imageUrlQuery.data || '', alt: "", placeholder: imageUrlQuery.isFetching ? (React.createElement(SpinnerWrap, null,
                    React.createElement(Spinner, { color: "blue", size: 10 }))) : (React.createElement(HubAvatar, { id: streamId })) })),
        React.createElement(StreamInfoCellOuter, null,
            React.createElement(StreamIdWrap, null, truncateStreamName(streamId)),
            React.createElement(StreamDescriptionWrap, null, description == null ? (React.createElement(StreamDescription, { streamId: streamId })) : (description)))));
}
function useStreamImageUrlQuery(streamId) {
    return useQuery({
        queryKey: ['useStreamImageUrlQuery', streamId || ''],
        queryFn: async () => {
            if (!streamId) {
                return null;
            }
            const resp = await fetch(getStreamGptApiUrl(`streams/${encodeURIComponent(streamId)}`));
            if (resp.status !== 200) {
                return null;
            }
            /**
             * The GPT only processes streams on the Polygon network (137).
             */
            const { ipfsGatewayUrl } = getChainConfigExtension(137).ipfs;
            return z
                .object({
                imageHash: z.string(),
            })
                .transform(({ imageHash }) => imageHash ? `${ipfsGatewayUrl}${imageHash}` : null)
                .parse(await resp.json());
        },
        staleTime: Infinity,
    });
}
const SpinnerWrap = styled.div.withConfig({ displayName: "SpinnerWrap", componentId: "sc-1cpxmx0" }) `
    align-items: center;
    display: flex;
    justify-content: center;
`;
const StreamAvatarWrap = styled.div.withConfig({ displayName: "StreamAvatarWrap", componentId: "sc-10voy19" }) `
    background: #f0f0f0;
    border-radius: 50%;

    &,
    ${SpinnerWrap}, ${HubImageAvatar}, ${HubAvatar} {
        width: 40px;
        height: 40px;
    }
`;
const StreamInfoCellOuter = styled.div.withConfig({ displayName: "StreamInfoCellOuter", componentId: "sc-190y8ww" }) ``;
const StreamInfoCell = styled.div.withConfig({ displayName: "StreamInfoCell", componentId: "sc-1lqof7u" }) `
    align-items: center;
    display: flex;
    gap: 12px;
    line-height: 26px;
`;
const StreamIdWrap = styled.div.withConfig({ displayName: "StreamIdWrap", componentId: "sc-ip3u2s" }) `
    display: block;
    font-weight: ${MEDIUM};
    color: ${COLORS.primary};
`;
const StreamDescriptionWrap = styled.div.withConfig({ displayName: "StreamDescriptionWrap", componentId: "sc-1lx1q4b" }) `
    font-size: 14px;
    max-width: 208px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    :empty {
        display: none;
    }
`;
/**
 * Sponsorship's projected insolvency timestamp formatter.
 */
export function FundedUntilCell({ projectedInsolvencyAt, remainingBalance, }) {
    const value = projectedInsolvencyAt == null ? null : moment(projectedInsolvencyAt * 1000);
    return (React.createElement(Iconized, null,
        value == null || !value.isValid() ? (React.createElement(React.Fragment, null, "N/A")) : (React.createElement(Tooltip, { content: value.format('YYYY-MM-DD, HH:mm') }, value.format('YYYY-MM-DD'))),
        remainingBalance <= 0n && (React.createElement(Tooltip, { content: "Sponsorship expired" },
            React.createElement(TooltipIconWrap, { "$color": "#ff5c00", "$svgSize": {
                    width: '18px',
                    height: '18px',
                } },
                React.createElement(JiraFailedBuildStatusIcon, { label: "Error" }))))));
}
const Iconized = styled.div.withConfig({ displayName: "Iconized", componentId: "sc-18s1f06" }) `
    align-items: center;
    display: grid;
    gap: 8px;
    grid-template-columns: auto auto;
    line-height: normal;

    ${TooltipIconWrap} svg {
        width: 18px;
        height: 18px;
    }
`;
export function SponsorshipApyCell({ spotAPY, isRunning, }) {
    return (React.createElement(Iconized, null,
        `${(spotAPY * 100).toFixed(0)}%`,
        !isRunning && (React.createElement(Tooltip, { content: "Sponsorship not runnning" },
            React.createElement(TooltipIconWrap, { "$color": "#ff5c00", "$svgSize": {
                    width: '18px',
                    height: '18px',
                } },
                React.createElement(JiraFailedBuildStatusIcon, { label: "Error" }))))));
}
export function NumberOfOperatorsCell({ sponsorship, currentOperatorId, }) {
    const joinedByCurrentOperator = !!currentOperatorId &&
        !!getSponsorshipStakeForOperator(sponsorship.stakes, currentOperatorId);
    return (React.createElement(Iconized, null,
        sponsorship.operatorCount,
        joinedByCurrentOperator && (React.createElement(Tooltip, { content: "Already joined as Operator." },
            React.createElement(TooltipIconWrap, { "$color": "currentColor" },
                React.createElement(CheckIcon, { label: "Already joined as Operator." }))))));
}
