export var ProjectType;
(function (ProjectType) {
    ProjectType["OpenData"] = "OPEN_DATA";
    ProjectType["PaidData"] = "PAID_DATA";
    ProjectType["DataUnion"] = "DATA_UNION";
})(ProjectType || (ProjectType = {}));
export var TheGraph;
(function (TheGraph) {
    let ProjectType;
    (function (ProjectType) {
        ProjectType["Open"] = "openData";
        ProjectType["Paid"] = "paidData";
        ProjectType["DataUnion"] = "dataUnion";
    })(ProjectType = TheGraph.ProjectType || (TheGraph.ProjectType = {}));
})(TheGraph || (TheGraph = {}));
