import { GetProjectDocument, GetProjectSubscriptionsDocument, } from '~/generated/gql/network';
import { parseProject } from '~/parsers/ProjectParser';
import { getGraphClient } from '~/getters/getGraphClient';
import { prehandleBehindBlockError } from '~/errors/BehindIndexError';
export async function getParsedProjectById(chainId, projectId, { force = false, minBlockNumber = 0 } = {}) {
    try {
        const { data: { project }, } = await getGraphClient(chainId).query({
            query: GetProjectDocument,
            variables: {
                id: projectId.toLowerCase(),
                minBlockNumber,
            },
            fetchPolicy: force ? 'network-only' : void 0,
        });
        return (project || null) && (await parseProject(project, { chainId }));
    }
    catch (e) {
        prehandleBehindBlockError(e, minBlockNumber);
        throw e;
    }
}
export async function getProjectSubscriptions(chainId, projectId, { force = false } = {}) {
    const { data: { project }, } = await getGraphClient(chainId).query({
        query: GetProjectSubscriptionsDocument,
        variables: {
            id: projectId,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return project?.subscriptions || [];
}
