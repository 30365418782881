import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { toaster } from 'toasterhea';
import { SponsorshipDecimals } from '~/components/Decimals';
import { SponsorshipDisclaimer } from '~/components/SponsorshipDisclaimer';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { useMediaQuery } from '~/hooks';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import FormModal, { FieldWrap, MaxButton, Prop, PropList, PropValue, Section, SectionHeadline, TextAppendix, TextInput, } from '~/modals/FormModal';
import { fundSponsorship } from '~/services/sponsorships';
import Label from '~/shared/components/Ui/Label';
import { waitForIndexedBlock } from '~/utils';
import { Layer } from '~/utils/Layer';
import { toBigInt, toFloat } from '~/utils/bn';
import { RejectionReason, isRejectionReason, isTransactionRejection, } from '~/utils/exceptions';
import { pluralizeUnit } from '~/utils/pluralizeUnit';
function FundSponsorshipModal({ balance, chainId, onResolve, sponsorship, ...props }) {
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    const [rawAmount, setRawAmount] = useState('');
    const amount = ((a) => (a > 0n ? a : 0n))(toBigInt(rawAmount || 0, decimals));
    const [confirmState, setConfirmState] = useState(false);
    const { payoutPerSec: pricePerSecond } = sponsorship;
    const extensionInSeconds = Number(pricePerSecond > 0n ? amount / pricePerSecond : 0n);
    const extensionDuration = moment.duration(extensionInSeconds, 'seconds');
    const extensionText = useMemo(() => {
        if (extensionDuration.asSeconds() === 0) {
            return '0 days';
        }
        const years = extensionDuration.get('years');
        const months = extensionDuration.get('months');
        const days = extensionDuration.get('days');
        const hours = extensionDuration.get('hours');
        const minutes = extensionDuration.get('minutes');
        if (!years && !months && !days && !hours) {
            return `${minutes} ${pluralizeUnit(minutes, 'minute')}`;
        }
        if (!years && !months && !days) {
            return (`${hours} ${pluralizeUnit(hours, 'hour')}` +
                (minutes ? ` & ${minutes} ${pluralizeUnit(minutes, 'minute')}` : ''));
        }
        if (!years && !months) {
            return `${days} ${pluralizeUnit(days, 'day')} & ${hours} ${pluralizeUnit(hours, 'hour')}`;
        }
        if (!years) {
            return `${months} ${pluralizeUnit(months, 'month')} & ${days} ${pluralizeUnit(days, 'day')}`;
        }
        return `${years} ${pluralizeUnit(years, 'year')}, ${months} ${pluralizeUnit(months, 'month')} & ${days} ${pluralizeUnit(days, 'day')}`;
    }, [extensionDuration]);
    const startDate = sponsorship.projectedInsolvencyAt == null
        ? Date.now()
        : sponsorship.projectedInsolvencyAt * 1000;
    const endDate = new Date(startDate + extensionInSeconds * 1000);
    const insufficientFunds = amount > balance;
    const canSubmit = amount > 0n && !insufficientFunds && confirmState;
    const [busy, setBusy] = useState(false);
    const clean = rawAmount === '';
    const limitedSpace = useMediaQuery('screen and (max-width: 460px)');
    return (React.createElement(FormModal, { ...props, title: "Fund Sponsorship", canSubmit: canSubmit && !busy, submitting: busy, submitLabel: "Fund", onBeforeAbort: (reason) => !busy && (reason !== RejectionReason.Backdrop || clean), onSubmit: async () => {
            setBusy(true);
            try {
                await fundSponsorship(chainId, sponsorship.id, amount, {
                    onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                });
                onResolve?.();
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(SectionHeadline, null,
            "Please set the amount of ",
            React.createElement(SponsorshipPaymentTokenName, null),
            " to spend to extend the Sponsorship"),
        React.createElement(Section, null,
            React.createElement(Label, { "$wrap": true }, "Amount to sponsor"),
            React.createElement(FieldWrap, { "$invalid": insufficientFunds },
                React.createElement(TextInput, { name: "amount", autoFocus: true, onChange: ({ target }) => void setRawAmount(target.value), placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawAmount }),
                React.createElement(MaxButton, { onClick: () => {
                        setRawAmount(toFloat(balance, decimals).toString());
                    } }),
                React.createElement(TextAppendix, null,
                    React.createElement(SponsorshipPaymentTokenName, null))),
            React.createElement(PropList, null,
                React.createElement("li", null,
                    React.createElement(Prop, { "$invalid": insufficientFunds }, insufficientFunds ? (React.createElement(React.Fragment, null, "Not enough balance in your wallet")) : (React.createElement(React.Fragment, null, "Your wallet balance"))),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: balance, tooltip: limitedSpace }))),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Sponsorship extended by"),
                    React.createElement(PropValue, null, extensionText)),
                React.createElement("li", null,
                    React.createElement(Prop, null, "New end date"),
                    React.createElement(PropValue, null, moment(endDate).format(limitedSpace ? 'YY-MM-DD' : 'YYYY-MM-DD'))),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Rate"),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: sponsorship.payoutPerDay, unitSuffix: "/day", tooltip: limitedSpace }))))),
        React.createElement(SponsorshipDisclaimer, { sponsorship: sponsorship, onResolve: onResolve, checkboxState: confirmState, onCheckboxStateChange: (value) => setConfirmState(value) })));
}
export const fundSponsorshipModal = toaster(FundSponsorshipModal, Layer.Modal);
