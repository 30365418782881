import React, { useMemo } from 'react';
export function TermsOfUse({ termsUrl, termsName, ...flags }) {
    const entries = Object.entries(flags);
    const permitted = entries.filter(([, flag]) => flag).map(([name]) => name);
    const notPermitted = entries.filter(([, flag]) => !flag).map(([name]) => name);
    const permittedStr = useMemo(() => getTermStrings(permitted), [permitted]);
    const notPermittedStr = useMemo(() => getTermStrings(notPermitted), [notPermitted]);
    return (React.createElement(React.Fragment, null,
        (permitted.length > 0 || notPermitted.length > 0) && (React.createElement("p", null,
            React.createElement("strong", null, "Basic terms"),
            ' ',
            permitted.length > 0 && (React.createElement(React.Fragment, null,
                permittedStr,
                permitted.length === 1
                    ? ' is permitted.'
                    : ' are permitted.',
                ' ')),
            notPermitted.length > 0 && (React.createElement(React.Fragment, null,
                notPermittedStr,
                notPermitted.length === 1 ? ' is not' : ' are not')),
            permitted.length === 0 && ' permitted',
            notPermitted.length > 0 && '.')),
        !!termsUrl && (React.createElement("p", null,
            React.createElement("strong", null, "Detailed terms"),
            ' ',
            React.createElement("strong", null,
                React.createElement("a", { href: termsUrl, target: "_blank", rel: "noopener noreferrer" }, termsName != null && termsName.length > 0
                    ? termsName
                    : termsUrl))))));
}
const termNames = {
    redistribution: 'Redistribution',
    commercialUse: 'Commercial use',
    reselling: 'Reselling',
    storage: 'Storage',
};
function getTermStrings(ids) {
    const names = ids.map((id) => termNames[id]);
    return [...names.slice(0, -2), names.slice(-2).join(' & ')].join(', ');
}
