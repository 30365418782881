import React from 'react';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar, { SearchBarWrap } from '~/shared/components/SearchBar';
import { SelectField2 } from '~/marketplace/components/SelectField2';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { TheGraph } from '~/shared/types';
import { useWalletAccount } from '~/shared/stores/wallet';
import { Button } from '~/components/Button';
import Faders from '~/assets/Faders.svg';
import { ActionBarContainer, CreateProjectButton, DropdownFilters, FiltersBar, FiltersWrap, MobileFilterText, MobileFilterWrap, SelectFieldWrap, } from '~/components/ActionBar.styles';
import { projectTypeFilterModal } from '~/modals/ProjectTypeFilter';
import { Route as R, routeOptions } from '~/utils/routes';
import { useCurrentChainSymbolicName } from '~/utils/chains';
var TabOption;
(function (TabOption) {
    TabOption["Any"] = "all";
    TabOption["Owned"] = "my";
})(TabOption || (TabOption = {}));
export function isOwnedTabOption(value) {
    return value === TabOption.Owned;
}
function isTabOption(value) {
    return value === TabOption.Any || value === TabOption.Owned;
}
const productTypeOptions = [
    {
        value: TheGraph.ProjectType.Open,
        label: 'Open data',
    },
    {
        value: TheGraph.ProjectType.Paid,
        label: 'Paid data',
    },
    {
        value: TheGraph.ProjectType.DataUnion,
        label: 'Data union',
    },
];
const UnstyledActionBar = ({ filter, onCreateProject, onFilterChange, ...props }) => {
    const [params] = useSearchParams();
    const tab = params.get('tab');
    const scope = isTabOption(tab) ? tab : TabOption.Any;
    const walletAccount = useWalletAccount();
    const navigate = useNavigate();
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(ActionBarContainer, { ...props },
        React.createElement(SearchBarWrap, null,
            React.createElement(SearchBar, { value: filter.search, onChange: (search) => {
                    onFilterChange?.({
                        ...filter,
                        search,
                    });
                } })),
        React.createElement(FiltersBar, null,
            React.createElement(FiltersWrap, null,
                React.createElement(Tabs, { selection: scope, onSelectionChange: (id) => {
                        navigate(R.projects(routeOptions(chainName, { tab: id })));
                    } },
                    React.createElement(Tab, { id: TabOption.Any }, "All projects"),
                    React.createElement(Tab, { id: TabOption.Owned, disabled: !walletAccount, title: walletAccount
                            ? undefined
                            : 'You need to be connected in to view your projects' }, "Your projects")),
                React.createElement(DropdownFilters, null,
                    React.createElement("span", null, "Filter by"),
                    React.createElement(SelectFieldWrap, null,
                        React.createElement(SelectField2, { placeholder: "Project type", options: productTypeOptions, value: filter.type, onChange: (type) => {
                                onFilterChange?.({
                                    ...filter,
                                    type: type === null
                                        ? undefined
                                        : type,
                                });
                            } }))),
                React.createElement(MobileFilterWrap, null,
                    React.createElement(MobileFilterTrigger, { onClick: async () => {
                            try {
                                const type = await projectTypeFilterModal.pop({
                                    value: filter.type,
                                });
                                onFilterChange?.({
                                    ...filter,
                                    type,
                                });
                            }
                            catch (e) { }
                        }, kind: "secondary" },
                        React.createElement(MobileFilterText, null,
                            "Filter",
                            filter.type != null && '*'),
                        React.createElement("img", { src: Faders })))),
            React.createElement(CreateProjectButton, { kind: "primary", type: "button", onClick: onCreateProject }, "Create project"))));
};
const ActionBar = styled(UnstyledActionBar).withConfig({ displayName: "ActionBar", componentId: "sc-xux815" }) ``;
export default ActionBar;
const MobileFilterTrigger = styled(Button).withConfig({ displayName: "MobileFilterTrigger", componentId: "sc-coqg64" }) `
    padding: 0 10px !important;
`;
