import React from 'react';
import styled from 'styled-components';
import emptyStateIcon from '~/shared/assets/images/empty_state_icon.png';
import emptyStateIcon2x from '~/shared/assets/images/empty_state_icon@2x.png';
import { EmptyState } from '~/components/EmptyState';
export const NoData = ({ firstLine, secondLine, width, compact, }) => {
    return (React.createElement(EmptyStateWrap, { image: React.createElement("img", { src: emptyStateIcon, srcSet: `${emptyStateIcon2x} 2x`, alt: "Not found", style: width
                ? { width: `${width}px`, height: 'auto', maxWidth: '100%' }
                : {} }), compact: compact },
        React.createElement(EmptyStateParagraph, null, firstLine),
        secondLine && React.createElement(EmptyStateParagraph, null, secondLine)));
};
const EmptyStateWrap = styled(EmptyState).withConfig({ displayName: "EmptyStateWrap", componentId: "sc-fnlpjx" }) `
    padding: ${({ compact }) => (compact ? '48px' : '150px')} 0;
`;
const EmptyStateParagraph = styled.p.withConfig({ displayName: "EmptyStateParagraph", componentId: "sc-1n8cbet" }) `
    font-size: 16px;
    margin-bottom: 0;
`;
