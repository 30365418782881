import React, { useCallback, useRef, forwardRef } from 'react';
const sanitise = (value) => (value == null ? '' : value);
const normalize = (value) => typeof value === 'string' ? value.trim() : String(sanitise(value));
const OnCommitDecorator = (WrappedComponent) => {
    const OnCommitDecoratorWrapper = ({ noEmptyCommit, onBlur: onBlurProp, onChange: onChangeProp, onCommit: onCommitProp, onFocus: onFocusProp, onKeyDown: onKeyDownProp, smartCommit, ...props }, ref) => {
        const valueRef = useRef(null);
        const onCommit = useCallback((value, requireChanged = false) => {
            if (onCommitProp) {
                const newValue = noEmptyCommit ? normalize(value) : value;
                if ((!requireChanged || valueRef.current !== value) &&
                    (newValue || !noEmptyCommit)) {
                    onCommitProp(value);
                }
            }
        }, [onCommitProp, noEmptyCommit]);
        const onChange = useCallback((e) => {
            if (!smartCommit) {
                onCommit(e.target.value);
            }
            if (onChangeProp) {
                onChangeProp(e);
            }
        }, [onChangeProp, onCommit, smartCommit]);
        const onKeyDown = useCallback((e) => {
            if (e.key === 'Enter' &&
                smartCommit &&
                e.target instanceof HTMLInputElement) {
                onCommit(e.target.value);
            }
            if (onKeyDownProp) {
                onKeyDownProp(e);
            }
        }, [onKeyDownProp, onCommit, smartCommit]);
        const onFocus = useCallback((e) => {
            if (e.target instanceof HTMLInputElement ||
                e.target instanceof HTMLTextAreaElement) {
                valueRef.current = e.target.value;
            }
            if (onFocusProp) {
                onFocusProp(e);
            }
        }, [onFocusProp]);
        const onBlur = useCallback((e) => {
            if (e.target instanceof HTMLInputElement ||
                e.target instanceof HTMLTextAreaElement) {
                const { value } = e.target;
                if (smartCommit) {
                    onCommit(value, true);
                }
            }
            if (onBlurProp) {
                onBlurProp(e);
            }
        }, [onBlurProp, onCommit, smartCommit]);
        return (React.createElement(WrappedComponent, { ...props, onBlur: onBlur, onChange: onChange, onFocus: onFocus, onKeyDown: onKeyDown, ref: ref }));
    };
    const OnCommitDecoratorWrapperFR = forwardRef(OnCommitDecoratorWrapper);
    const OptInOnCommitDecorator = ({ onCommit, smartCommit, noEmptyCommit, ...props }, ref) => onCommit ? (React.createElement(OnCommitDecoratorWrapperFR, { ...props, noEmptyCommit: noEmptyCommit, onCommit: onCommit, ref: ref, smartCommit: smartCommit })) : (React.createElement(WrappedComponent, { ...props, ref: ref }));
    return forwardRef(OptInOnCommitDecorator);
};
export default OnCommitDecorator;
