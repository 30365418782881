import { useEffect, useState } from 'react';
export default function useOperatorLiveNodes(heartbeats) {
    const count = Object.keys(heartbeats).length;
    const [isLoading, setIsLoading] = useState(false);
    const detectedAny = count > 0;
    useEffect(() => {
        if (detectedAny) {
            return void setIsLoading(false);
        }
        let mounted = true;
        setIsLoading(true);
        setTimeout(() => {
            if (mounted) {
                setIsLoading(false);
            }
        }, 30000);
        return () => {
            mounted = false;
        };
    }, [detectedAny]);
    return {
        count,
        isLoading,
    };
}
