import React, { useMemo } from 'react';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { Tooltip } from '~/components/Tooltip';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import { abbr } from '~/utils';
import { toFloat } from '~/utils/bn';
/**
 * Displays amount (in wei) in a human-readable way. Optionally it abbreviates
 * it and adds a on-hover tooltip showing a full value.
 */
export function Decimals({ abbr: abbrProp = false, amount, decimals, tooltip = false, unit, }) {
    const humanAmount = toFloat(amount, decimals);
    const value = useMemo(() => (abbrProp ? abbr(humanAmount) : humanAmount.toString()), [abbrProp, humanAmount]);
    if (value === humanAmount.toString() || !tooltip) {
        return (React.createElement(React.Fragment, null,
            value,
            unit != null ? React.createElement(React.Fragment, null,
                "\u00A0",
                unit) : React.createElement(React.Fragment, null)));
    }
    return (React.createElement(Tooltip, { anchorDisplay: "inline", content: React.createElement(React.Fragment, null,
            humanAmount.toString(),
            unit != null ? React.createElement(React.Fragment, null,
                "\u00A0",
                unit) : React.createElement(React.Fragment, null)) },
        value,
        unit != null ? React.createElement(React.Fragment, null,
            "\u00A0",
            unit) : React.createElement(React.Fragment, null)));
}
export function SponsorshipDecimals({ abbr = false, amount, tooltip = false, unitSuffix, }) {
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    return (React.createElement(Decimals, { abbr: abbr, amount: amount, decimals: decimals, tooltip: tooltip, unit: React.createElement(React.Fragment, null,
            React.createElement(SponsorshipPaymentTokenName, null),
            unitSuffix) }));
}
