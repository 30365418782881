import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { NavLink, NavbarLinkDesktop } from '~/components/Nav/Nav.styles';
import { DefaultSimpleDropdownMenu, SimpleDropdown } from '~/components/SimpleDropdown';
import SvgIcon from '~/shared/components/SvgIcon';
import { COLORS } from '~/shared/utils/styled';
import { useCurrentChainSymbolicName } from '~/utils/chains';
import { Route as R, routeOptions } from '~/utils/routes';
export function Dropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const { pathname } = useLocation();
    const timeoutRef = useRef();
    function clear() {
        if (timeoutRef.current != null) {
            clearTimeout(timeoutRef.current);
        }
    }
    function show(toggle) {
        clear();
        toggle(true);
    }
    function hide(toggle, { immediately = false } = {}) {
        clear();
        if (immediately) {
            return void toggle(false);
        }
        timeoutRef.current = window.setTimeout(() => {
            toggle(false);
        }, 250);
    }
    const chainName = useCurrentChainSymbolicName();
    return (React.createElement(NavbarLinkDesktop, { highlight: isOpen || isNetworkTabActive(pathname) },
        React.createElement(SimpleDropdown, { onToggle: setIsOpen, menu: (toggle) => (React.createElement(Menu, { onMouseEnter: () => void show(toggle), onMouseLeave: () => void hide(toggle) }, NetworkNavItems.map((i) => (React.createElement(DropdownItem, { "$active": pathname.startsWith(i.linkFn()), key: i.title, to: i.linkFn(routeOptions(chainName)), onFocus: () => void show(toggle), onClick: () => void hide(toggle, { immediately: true }) },
                React.createElement("strong", null, i.title),
                React.createElement(Subtitle, null, i.subtitle)))))) }, (toggle) => (React.createElement(NavLink, { as: Link, to: R.networkOverview(routeOptions(chainName)), onFocus: () => void show(toggle), onBlur: () => void hide(toggle), onMouseEnter: () => void show(toggle), onMouseLeave: () => void hide(toggle), onClick: (e) => void e.preventDefault() }, "Network")))));
}
const networkLinks = [R.networkOverview(), R.sponsorships(), R.operators()];
export const isNetworkTabActive = (path) => networkLinks.some((addr) => path.startsWith(addr));
const Menu = styled(DefaultSimpleDropdownMenu).withConfig({ displayName: "Menu", componentId: "sc-195d9q9" }) `
    padding-left: 0;
    padding-right: 0;
`;
export const NetworkNavItems = [
    {
        title: 'Overview',
        subtitle: 'Your activity on one glance',
        linkFn: R.networkOverview,
    },
    {
        title: 'Sponsorships',
        subtitle: 'Explore, create and join Sponsorships',
        linkFn: R.sponsorships,
    },
    {
        title: 'Operators',
        subtitle: 'Explore Operators and delegate',
        linkFn: R.operators,
    },
];
const Subtitle = styled.div.withConfig({ displayName: "Subtitle", componentId: "sc-1jdsuzu" }) `
    color: ${COLORS.primaryLight};
`;
function getTickIconAttrs() {
    return {
        name: 'tick',
    };
}
const TickIcon = styled(SvgIcon).attrs(getTickIconAttrs).withConfig({ displayName: "TickIcon", componentId: "sc-atvk7h" }) `
    width: 10px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 24px;
`;
function getDropdownItemAttrs(props) {
    return {
        ...props,
        children: (React.createElement(React.Fragment, null,
            React.createElement("div", null, props.children),
            props.$active && React.createElement(TickIcon, null))),
    };
}
const DropdownItem = styled(Link).attrs(getDropdownItemAttrs).withConfig({ displayName: "DropdownItem", componentId: "sc-1r8k7t1" }) `
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 28px;
    padding: 12px 32px;
    position: relative;

    &,
    :hover,
    :visited,
    :link {
        color: #000000;
    }

    :focus,
    :hover {
        background-color: #e9ecef;
    }

    &[disabled] {
        color: #adadad;
    }

    :focus {
        outline: none;
        box-shadow: inset 0 0 0 1.5px var(--focus-border-color);
    }

    :active {
        background-color: ${COLORS.primary};
        color: #ffffff;
    }

    :active ${Subtitle} {
        color: ${COLORS.radioBorder};
    }
`;
