export default class StreamNotFoundError extends Error {
    constructor(streamId) {
        super('Stream not found');
        this.streamId = streamId;
        this.name = 'StreamNotFoundError';
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, StreamNotFoundError);
        }
        Object.setPrototypeOf(this, StreamNotFoundError.prototype);
    }
}
