import React from 'react';
import Tabs, { Tab } from '~/shared/components/Tabs';
import { ChartPeriod, isChartPeriod } from '~/types';
export function ChartPeriodTabs({ value = ChartPeriod.SevenDays, onChange, ...rest }) {
    return (React.createElement(Tabs, { ...rest, selection: value, onSelectionChange: (value) => {
            if (!isChartPeriod(value)) {
                throw new Error('Invalid chart period');
            }
            onChange?.(value);
        } },
        React.createElement(Tab, { id: ChartPeriod.SevenDays }, "7D"),
        React.createElement(Tab, { id: ChartPeriod.OneMonth }, "1M"),
        React.createElement(Tab, { id: ChartPeriod.ThreeMonths }, "3M"),
        React.createElement(Tab, { id: ChartPeriod.OneYear }, "1Y"),
        React.createElement(Tab, { id: ChartPeriod.YearToDate }, "YTD"),
        React.createElement(Tab, { id: ChartPeriod.All }, "ALL")));
}
