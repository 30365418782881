import { Contract } from 'ethers';
import { Minute } from '~/consts';
import { getContractAbi, getContractAddress } from '~/utils/contracts';
import { getPublicProvider } from '~/utils/providers';
const cache = {};
const TTL = 60 * Minute;
/**
 * @todo Refactor to use `fetchQuery` for cache.
 */
export async function getConfigValueFromChain(chainId, key) {
    const { updatedAt = 0, value } = cache[chainId]?.[key] || {};
    if (value && updatedAt + TTL > Date.now()) {
        return value;
    }
    const provider = await getPublicProvider(chainId);
    const contract = new Contract(getContractAddress('config', chainId), getContractAbi('config'), provider);
    const result = (await contract[key]());
    const obj = cache[chainId] || {};
    obj[key] = {
        updatedAt: Date.now(),
        value: result,
    };
    cache[chainId] = obj;
    return result;
}
