import React from 'react';
import styled from 'styled-components';
import { COLORS } from '~/shared/utils/styled';
export function Slider({ min, max, value, onChange: onChangeProp, disabled, className, }) {
    const borderPercentage = (100 * value) / (max - min);
    return (React.createElement(SliderRoot, { className: className },
        React.createElement("input", { type: "range", min: min, max: max, value: value, onChange: (e) => {
                onChangeProp?.(Number.parseInt(e.target.value, 10));
            }, disabled: disabled, style: {
                background: `linear-gradient(to right, ${COLORS.link} calc(${borderPercentage}%), ${COLORS.linkInactive} 1%)`,
            } })));
}
const SliderRoot = styled.div.withConfig({ displayName: "SliderRoot", componentId: "sc-1u8szce" }) `
    width: 100%;
    position: relative;
    padding-bottom: 6px;

    input {
        appearance: none;
        width: 100%;
        outline: none;
        transition: opacity 0.2s;
        height: 3px;
        border-radius: 2px;
    }

    input::-webkit-slider-thumb {
        appearance: none;
        width: 12px;
        height: 12px;
        background: #0324ff;
        cursor: pointer;
        border-radius: 50%;
    }

    input::-moz-range-thumb {
        width: 12px;
        height: 12px;
        background: #0324ff;
        cursor: pointer;
        border-radius: 50%;
    }

    input:focus {
        outline: none;
    }

    input[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;
