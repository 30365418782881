import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownMenuItem } from '~/components/DropdownMenuItem';
import { LoadMoreButton } from '~/components/LoadMore';
import { Meatball } from '~/components/Meatball';
import { ActionsToggle } from '~/components/SalePointSelector/BeneficiaryAddressEditor';
import { SimpleDropdown, SimpleListDropdownMenu } from '~/components/SimpleDropdown';
import { useMediaQuery } from '~/hooks';
import { NoData } from '~/shared/components/NoData';
import { FloatingLoadingIndicator, NoDataWrap, OrderCaretIcon, ScrollTableCell, ScrollTableCellsWrap, ScrollTableColumn, ScrollTableHeaderCell, ScrollTableNonStickyColumnsWrap, } from '~/shared/components/ScrollTable/ScrollTable.styles';
import { TABLET } from '~/shared/utils/styled';
export const ScrollTable = ({ isLoading, footerComponent, hasMoreResults, onLoadMore, ...tableProps }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ScrollTableCore, { isLoading: isLoading, ...tableProps }),
        hasMoreResults && (React.createElement(LoadMoreButton, { disabled: isLoading, onClick: () => onLoadMore?.(), kind: "primary2" }, "Load more")),
        footerComponent != null && footerComponent));
};
export const ScrollTableCore = ({ elements, columns, actions, isLoading, noDataFirstLine, noDataSecondLine, orderDirection, orderBy, onOrderChange, linkMapper, }) => {
    const isMobile = !useMediaQuery(`only screen and ${TABLET}`);
    let actualColumns = columns;
    // Disable sticky columns on mobile since it makes scrolling difficult
    if (isMobile) {
        actualColumns = columns.map((c) => ({
            ...c,
            isSticky: false,
        }));
    }
    const stickyColumns = actualColumns.filter((column) => column.isSticky);
    const nonStickyColumns = actualColumns.filter((column) => !column.isSticky);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
    const hasOrderingEnabled = orderBy && orderDirection && onOrderChange;
    return (React.createElement(ScrollTableCellsWrap, { stickyColumnCount: stickyColumns.length, nonStickyColumnCount: nonStickyColumns.length },
        stickyColumns.map((stickyColumn) => {
            return (React.createElement(ScrollTableColumn, { key: stickyColumn.key },
                React.createElement(ScrollTableHeaderCell, { "$align": stickyColumn.align, "$pointer": onOrderChange && stickyColumn.sortable, onClick: () => {
                        if (onOrderChange && stickyColumn.sortable) {
                            onOrderChange(stickyColumn.key);
                        }
                    } },
                    React.createElement("span", null, stickyColumn.displayName),
                    ' ',
                    hasOrderingEnabled && stickyColumn.key === orderBy && (React.createElement(OrderCaret, { orderDirection: orderDirection }))),
                elements && elements.length > 0 && (React.createElement(React.Fragment, null, elements.map((element, id) => {
                    return (React.createElement(ScrollTableCell, { key: id, "$align": stickyColumn.align, "$hover": hoveredRowIndex === id, as: linkMapper ? Link : 'div', to: linkMapper ? linkMapper(element) : '', onMouseEnter: () => setHoveredRowIndex(id), onMouseLeave: () => setHoveredRowIndex(null) }, stickyColumn.valueMapper(element)));
                })))));
        }),
        React.createElement(ScrollTableNonStickyColumnsWrap, null,
            nonStickyColumns.map((nonStickyColumn) => {
                return (React.createElement(ScrollTableColumn, { key: nonStickyColumn.key },
                    React.createElement(ScrollTableHeaderCell, { "$align": nonStickyColumn.align, "$pointer": onOrderChange && nonStickyColumn.sortable, onClick: () => {
                            if (onOrderChange && nonStickyColumn.sortable) {
                                onOrderChange(nonStickyColumn.key);
                            }
                        } },
                        React.createElement("span", null, nonStickyColumn.displayName),
                        hasOrderingEnabled &&
                            nonStickyColumn.key === orderBy && (React.createElement(OrderCaret, { orderDirection: orderDirection }))),
                    elements && elements.length > 0 && (React.createElement(React.Fragment, null, elements.map((element, id) => {
                        return (React.createElement(ScrollTableCell, { as: linkMapper ? Link : 'div', to: linkMapper ? linkMapper(element) : '', key: id, "$align": nonStickyColumn.align, "$hover": hoveredRowIndex === id, onMouseEnter: () => setHoveredRowIndex(id), onMouseLeave: () => setHoveredRowIndex(null) }, nonStickyColumn.valueMapper(element)));
                    })))));
            }),
            actions && actions.length && (React.createElement(ScrollTableColumn, { "$actionColumn": true },
                React.createElement(ScrollTableHeaderCell, { "$actionCell": true }),
                elements.map((element, id) => (React.createElement(ScrollTableCell, { key: id, "$actionCell": true, "$hover": hoveredRowIndex === id, onMouseEnter: () => setHoveredRowIndex(id), onMouseLeave: () => setHoveredRowIndex(null) },
                    React.createElement(SimpleDropdown, { detached: true, menu: (toggle) => (React.createElement(SimpleListDropdownMenu, null,
                            React.createElement("ul", null, actions.map((action, actionIndex) => {
                                const { displayName, callback, disabled, } = typeof action === 'function'
                                    ? action(element)
                                    : {
                                        displayName: typeof action.displayName ===
                                            'function'
                                            ? action.displayName(element)
                                            : action.displayName,
                                        callback() {
                                            action.callback(element);
                                        },
                                        disabled: typeof action.disabled ===
                                            'function'
                                            ? action.disabled(element)
                                            : action.disabled,
                                    };
                                return (React.createElement(DropdownMenuItem, { key: actionIndex, type: "button", disabled: disabled, onClick: () => {
                                        callback();
                                        toggle(false);
                                    } }, displayName));
                            })))) }, (toggle) => (React.createElement(ActionsToggle, { type: "button", onClick: () => void toggle((x) => !x) },
                        React.createElement(Meatball, { alt: "", layout: "vertical" })))))))))),
        (!elements || elements.length === 0) && (React.createElement(NoDataWrap, null,
            React.createElement(NoData, { firstLine: noDataFirstLine || 'No data', secondLine: noDataSecondLine, compact: true }))),
        React.createElement(FloatingLoadingIndicator, { loading: isLoading })));
};
const OrderCaret = ({ orderDirection }) => {
    return React.createElement(OrderCaretIcon, { name: "caretUp", "$direction": orderDirection });
};
