import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { z } from 'zod';
import { create } from 'zustand';
import { DraftValidationError, ValidationError } from '~/errors';
import { isRejectionReason, isTransactionRejection } from '~/utils/exceptions';
export function getEmptyDraft(entity) {
    return {
        abandoned: false,
        dirty: false,
        entity: entity && {
            cold: entity,
            hot: entity,
        },
        errors: {},
        fetching: false,
        initialized: false,
        persisting: false,
    };
}
export function createDraftStore(options) {
    const useDraftStore = create((set, get) => {
        function isPersisting(draftId) {
            return get().drafts[draftId]?.persisting === true;
        }
        function setDraft(draftId, update, { force = false } = {}) {
            set((store) => {
                if (!store.drafts[draftId] && !force) {
                    return store;
                }
                return produce(store, ({ drafts }) => {
                    Object.assign(drafts, {
                        [draftId]: produce(store.drafts[draftId] || options.getEmptyDraft(), update),
                    });
                });
            });
        }
        const update = (draftId, updater) => {
            setDraft(draftId, (draft) => {
                if (!draft.entity) {
                    return;
                }
                updater(draft.entity.hot, draft.entity.cold);
                draft.dirty = !(options.isEqual || isEqual)(draft.entity.hot, draft.entity.cold);
            });
        };
        return {
            idMap: {},
            drafts: {},
            init(draftId) {
                /**
                 * Retrieve or create a draft associated with the given draft id.
                 */
                setDraft(draftId, (draft) => {
                    draft.abandoned = false;
                    draft.initialized = true;
                    draft.fetching = true;
                }, {
                    force: true,
                });
            },
            assign(draftId, entity) {
                setDraft(draftId, (draft) => {
                    if (draft.entity) {
                        /**
                         * The rule is: 1 draft = 1 entity, thus the logic does not
                         * support overwriting existing hot/cold entities by assign.
                         */
                        return;
                    }
                    draft.entity = entity ? { cold: entity, hot: entity } : undefined;
                    draft.fetching = typeof entity === 'undefined';
                });
            },
            setErrors(draftId, update) {
                setDraft(draftId, (draft) => {
                    update(draft.errors);
                });
            },
            update,
            teardown(draftId, { onlyAbandoned = false } = {}) {
                set((store) => produce(store, ({ idMap, drafts }) => {
                    const draft = drafts[draftId];
                    if (!draft) {
                        return;
                    }
                    const { id } = draft.entity?.cold || {};
                    if (!onlyAbandoned || draft.abandoned) {
                        if (id) {
                            delete idMap[id];
                        }
                        delete drafts[draftId];
                    }
                }));
            },
            abandon(draftId) {
                setDraft(draftId, (draft) => {
                    draft.abandoned = true;
                });
                if (!isPersisting(draftId)) {
                    get().teardown(draftId);
                }
            },
            async persist(draftId, fn, abortSignal) {
                if (isPersisting(draftId)) {
                    return;
                }
                try {
                    setDraft(draftId, (draft) => {
                        draft.persisting = true;
                    });
                    set((store) => produce(store, ({ drafts, idMap }) => {
                        const { id } = drafts[draftId]?.entity?.cold || {};
                        if (id) {
                            idMap[id] = draftId;
                        }
                    }));
                    try {
                        const draft = get().drafts[draftId];
                        if (!draft) {
                            throw new Error(`Draft ${draftId} does not exist`);
                        }
                        await fn?.(draft, {
                            abortSignal,
                            bind: (id) => {
                                set((store) => produce(store, (d) => {
                                    d.idMap[id] = draftId;
                                }));
                            },
                            update: (updater) => {
                                update(draftId, updater);
                            },
                        });
                        await options.persist?.(draft);
                    }
                    catch (e) {
                        if (e instanceof z.ZodError) {
                            const errors = {};
                            e.issues.forEach(({ path, message }) => {
                                errors[path.join('.')] = message;
                            });
                            setDraft(draftId, (copy) => {
                                copy.errors = errors;
                            });
                            throw new ValidationError(e.issues.map(({ message }) => message));
                        }
                        if (e instanceof DraftValidationError) {
                            throw new ValidationError([e.message]);
                        }
                        throw e;
                    }
                }
                finally {
                    setDraft(draftId, (draft) => {
                        draft.persisting = false;
                    });
                    get().teardown(draftId, { onlyAbandoned: true });
                }
            },
            validate(draftId, validator) {
                const entity = get().drafts[draftId]?.entity?.hot;
                const errors = {};
                try {
                    if (entity) {
                        validator(entity);
                    }
                }
                catch (e) {
                    if (e instanceof z.ZodError) {
                        e.issues.forEach(({ path, message }) => {
                            errors[path.join('.')] = message;
                        });
                    }
                    else {
                        console.warn('Failed to validate draft', e);
                    }
                }
                return errors;
            },
        };
    });
    function useBoundDraft(entityId) {
        const { idMap, drafts } = useDraftStore();
        const draftId = entityId ? idMap[entityId] : undefined;
        const draft = draftId ? drafts[draftId] : undefined;
        return !draft || draft.abandoned ? null : draft;
    }
    function useInitDraft(entityId) {
        const { idMap, init, abandon } = useDraftStore();
        const recycledDraftId = entityId ? idMap[entityId] : undefined;
        const draftId = useId(() => recycledDraftId || DefaultIdGenerator(options.prefix), [entityId, recycledDraftId]);
        useEffect(function initDraft() {
            init(draftId);
        }, [init, draftId]);
        useEffect(function abandonDraftOnUnmount() {
            return () => {
                abandon(draftId);
            };
        }, [abandon, draftId]);
        return draftId;
    }
    const useStore = useDraftStore;
    const DraftContext = createContext(undefined);
    function useDraftId() {
        const draftId = useContext(DraftContext);
        if (!draftId) {
            throw new Error('`useDraftId` used outside of the `DraftContext`');
        }
        return draftId;
    }
    function useDraft() {
        return useDraftStore().drafts[useDraftId()];
    }
    function useEntity({ hot = false } = {}) {
        return useDraft()?.entity?.[hot ? 'hot' : 'cold'];
    }
    function useIsDraftClean() {
        return !useDraft()?.dirty;
    }
    function useUpdateEntity() {
        const draftId = useDraftId();
        const { update } = useDraftStore();
        return useCallback((updater) => {
            return update(draftId, updater);
        }, [draftId, update]);
    }
    function useValidateEntity(validator) {
        const draftId = useDraftId();
        const { validate } = useDraftStore();
        const validatorRef = useRef(validator);
        if (validatorRef.current !== validator) {
            validatorRef.current = validator;
        }
        return useCallback(() => {
            return validate(draftId, validatorRef.current);
        }, [draftId, validate]);
    }
    function useIsFetchingEntity() {
        return !!useDraft()?.fetching;
    }
    function useIsDraftBusy() {
        const { fetching = false, persisting = false } = useDraft() || {};
        return fetching || persisting;
    }
    function usePersist(fn) {
        const { persist } = useDraftStore();
        const draftId = useDraftId();
        const abortControllerRef = useRef();
        useEffect(() => {
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            return () => {
                abortController.abort();
            };
        }, []);
        const fnRef = useRef(fn);
        if (fnRef.current !== fn) {
            fnRef.current = fn;
        }
        return useCallback(async () => {
            if (!draftId) {
                return;
            }
            return persist(draftId, fnRef.current, abortControllerRef.current?.signal);
        }, [persist, draftId]);
    }
    function usePersistCallback() {
        const { persist } = useDraftStore();
        const draftId = useDraftId();
        const busy = useIsDraftBusy();
        const clean = useIsDraftClean();
        const abortControllerRef = useRef();
        useEffect(() => {
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            return () => {
                abortController.abort();
            };
        }, []);
        return useCallback((params = {}) => {
            if (!draftId || busy || clean) {
                return;
            }
            void (async () => {
                try {
                    await persist(draftId);
                    const { aborted = false } = abortControllerRef.current?.signal || {};
                    params.onDone?.(!aborted);
                }
                catch (e) {
                    if (isTransactionRejection(e)) {
                        return;
                    }
                    if (isRejectionReason(e)) {
                        return;
                    }
                    params.onError?.(e);
                }
            })();
        }, [persist, draftId, busy, clean]);
    }
    function useIsAnyDraftBeingPersisted() {
        return Object.values(useDraftStore().drafts).some((d) => d?.persisting);
    }
    function useSetDraftErrors() {
        const draftId = useDraftId();
        const { setErrors } = useDraftStore();
        return useCallback((update) => {
            if (!draftId) {
                return;
            }
            return setErrors(draftId, update);
        }, [draftId, setErrors]);
    }
    return {
        DraftContext,
        useBoundDraft,
        useDraft,
        useDraftId,
        useDraftStore,
        useEntity,
        useIsAnyDraftBeingPersisted,
        useIsDraftBusy,
        useIsDraftClean,
        useIsFetchingEntity,
        usePersist,
        usePersistCallback,
        useSetDraftErrors,
        useStore,
        useUpdateEntity,
        useValidateEntity,
        useInitDraft,
    };
}
function DefaultIdGenerator(prefix = 'EntityDraft-') {
    return uniqueId(prefix);
}
function useId(generator, deps) {
    const generatorRef = useRef(generator);
    if (generatorRef.current !== generator) {
        generatorRef.current = generator;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => generatorRef.current(), deps);
}
