import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Contract } from 'ethers';
import moment from 'moment';
import { toaster } from 'toasterhea';
import { z } from 'zod';
import { address0 } from '~/consts';
import { prehandleBehindBlockError } from '~/errors/BehindIndexError';
import { GetEnsDomainsForAccountDocument, } from '~/generated/gql/ens';
import { GetAllOperatorsDocument, GetAllSponsorshipsDocument, GetDelegatorDailyBucketsDocument, GetNetworkStatsDocument, GetOperatorByIdDocument, GetOperatorByOwnerAddressDocument, GetOperatorDailyBucketsDocument, GetOperatorsByDelegationAndIdDocument, GetOperatorsByDelegationAndMetadataDocument, GetOperatorsByDelegationDocument, GetOperatorsByOwnerOrControllerAddressDocument, GetSponsorshipByIdDocument, GetSponsorshipByStreamIdDocument, GetSponsorshipDailyBucketsDocument, GetSponsorshipsByCreatorDocument, GetStreamByIdDocument, Operator_OrderBy, OrderDirection, SearchOperatorsByMetadataDocument, Sponsorship_OrderBy, } from '~/generated/gql/network';
import { getGraphClient } from '~/getters/getGraphClient';
import { parseOperator } from '~/parsers/OperatorParser';
import { parseSponsorship } from '~/parsers/SponsorshipParser';
import Toast, { ToastType } from '~/shared/toasts/Toast';
import { ProjectType } from '~/shared/types';
import { ChartPeriod } from '~/types';
import { Layer } from '~/utils/Layer';
import { toBN, toBigInt } from '~/utils/bn';
import { getChainConfigExtension } from '~/utils/chains';
import { getContractAbi, getContractAddress } from '~/utils/contracts';
import { getPublicProvider } from '~/utils/providers';
import { errorToast } from '~/utils/toast';
const DEFAULT_OPERATOR_ORDER_BY = Operator_OrderBy.Id;
const DEFAULT_SPONSORSHIP_ORDER_BY = Sponsorship_OrderBy.Id;
const DEFAULT_ORDER_DIRECTION = OrderDirection.Asc;
export function getProjectRegistryContract({ chainId, provider, }) {
    return new Contract(getContractAddress('projectRegistry', chainId), getContractAbi('projectRegistry'), provider);
}
export function getERC20TokenContract({ tokenAddress, provider, }) {
    return new Contract(tokenAddress, getContractAbi('erc20'), provider);
}
export function getMarketplaceContract({ chainId, provider, }) {
    return new Contract(getContractAddress('marketplace', chainId), getContractAbi('marketplace'), provider);
}
export async function getAllowance(chainId, tokenAddress, account, { recover = false } = {}) {
    while (true) {
        try {
            const provider = await getPublicProvider(chainId);
            return await getERC20TokenContract({
                tokenAddress,
                provider,
            }).allowance(account, getContractAddress('marketplace', chainId));
        }
        catch (e) {
            console.warn('Allowance check failed', e);
            if (!recover) {
                throw e;
            }
            try {
                await toaster(Toast, Layer.Toast).pop({
                    title: 'Allowance check failed',
                    type: ToastType.Warning,
                    desc: 'Would you like to try again?',
                    okLabel: 'Yes',
                    cancelLabel: 'No',
                });
                continue;
            }
            catch (_) {
                throw e;
            }
        }
    }
}
export async function getProjectPermissions(chainId, projectId, account) {
    if (account === address0) {
        return {
            canBuy: false,
            canDelete: false,
            canEdit: false,
            canGrant: false,
        };
    }
    const provider = await getPublicProvider(chainId);
    const response = await getProjectRegistryContract({
        chainId,
        provider,
    }).getPermission(projectId, account);
    const [canBuy = false, canDelete = false, canEdit = false, canGrant = false] = z
        .array(z.boolean())
        .parse(response);
    return {
        canBuy,
        canDelete,
        canEdit,
        canGrant,
    };
}
export function getProjectTypeName(projectType) {
    switch (projectType) {
        case ProjectType.DataUnion:
            return 'Data Union';
        case ProjectType.OpenData:
            return 'open data project';
        case ProjectType.PaidData:
            return 'paid data project';
    }
}
export function getProjectTypeTitle(projectType) {
    switch (projectType) {
        case ProjectType.DataUnion:
            return 'Data Union';
        case ProjectType.OpenData:
            return 'Open Data';
        case ProjectType.PaidData:
            return 'Paid Data';
    }
}
export function getProjectImageUrl(chainId, { imageUrl, imageIpfsCid, }) {
    const { ipfsGatewayUrl } = getChainConfigExtension(chainId).ipfs;
    if (imageIpfsCid) {
        return `${ipfsGatewayUrl}${imageIpfsCid}`;
    }
    if (!imageUrl) {
        return;
    }
    return `${imageUrl.replace(/^https:\/\/ipfs\.io\/ipfs\//, ipfsGatewayUrl)}`;
}
export async function getAllSponsorships({ chainId, first, skip, searchQuery = '', orderBy = DEFAULT_SPONSORSHIP_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, includeInactive = true, includeWithoutFunding = true, includeExpiredFunding = true, hasOperatorId = undefined, }) {
    const whereFilters = {};
    if (!includeInactive) {
        whereFilters.isRunning = true;
    }
    if (!includeWithoutFunding) {
        whereFilters.remainingWei_gt = 0;
    }
    if (!includeExpiredFunding) {
        whereFilters.projectedInsolvency_gt = Math.floor(Date.now() / 1000);
    }
    if (hasOperatorId != null) {
        whereFilters.stakes_ = { operator_contains_nocase: hasOperatorId };
    }
    const { data: { sponsorships }, } = await getGraphClient(chainId).query({
        query: GetAllSponsorshipsDocument,
        variables: {
            first,
            skip,
            searchQuery,
            id: searchQuery.toLowerCase(),
            orderBy,
            orderDirection,
            whereFilters,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return sponsorships;
}
export async function getSponsorshipsByStreamId({ chainId, first, skip, streamId = '', orderBy = DEFAULT_SPONSORSHIP_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, includeInactive = false, includeWithoutFunding = false, includeExpiredFunding = false, hasOperatorId, }) {
    const whereFilters = {};
    if (!includeInactive) {
        whereFilters.isRunning = true;
    }
    if (!includeWithoutFunding) {
        whereFilters.remainingWei_gt = 0;
    }
    if (!includeExpiredFunding) {
        whereFilters.projectedInsolvency_gt = Math.floor(Date.now() / 1000);
    }
    if (hasOperatorId != null) {
        whereFilters.stakes_ = { operator_contains_nocase: hasOperatorId };
    }
    const { data: { sponsorships }, } = await getGraphClient(chainId).query({
        query: GetSponsorshipByStreamIdDocument,
        variables: {
            first,
            skip,
            streamId,
            orderBy,
            orderDirection,
            whereFilters,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return sponsorships;
}
export async function getParsedSponsorshipById(chainId, sponsorshipId, { force = false, minBlockNumber = 0 } = {}) {
    let rawSponsorship;
    try {
        const { data } = await getGraphClient(chainId).query({
            query: GetSponsorshipByIdDocument,
            variables: {
                sponsorshipId: sponsorshipId.toLowerCase(),
                minBlockNumber,
            },
            fetchPolicy: force ? 'network-only' : void 0,
        });
        rawSponsorship = (data.sponsorship || null);
    }
    catch (e) {
        prehandleBehindBlockError(e, minBlockNumber);
        console.warn('Failed to fetch a Sponsorship', e);
        errorToast({ title: 'Could not fetch Sponsorship details' });
    }
    if (!rawSponsorship) {
        return null;
    }
    try {
        return parseSponsorship(rawSponsorship, {
            chainId,
        });
    }
    catch (e) {
        console.warn('Failed to parse a Sponsorship', e);
        errorToast({ title: 'Could not parse Sponsorship details' });
    }
    return null;
}
export async function getSponsorshipsByCreator(chainId, creator, { first, skip, searchQuery = '', orderBy = DEFAULT_SPONSORSHIP_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, includeInactive = false, includeWithoutFunding = false, includeExpiredFunding = false, hasOperatorId, } = {}) {
    const whereFilters = {};
    if (!includeInactive) {
        whereFilters.isRunning = true;
    }
    if (!includeWithoutFunding) {
        whereFilters.remainingWei_gt = 0;
    }
    if (!includeExpiredFunding) {
        whereFilters.projectedInsolvency_gt = Math.floor(Date.now() / 1000);
    }
    if (hasOperatorId != null) {
        whereFilters.stakes_ = { operator_contains_nocase: hasOperatorId };
    }
    const { data: { sponsorships }, } = await getGraphClient(chainId).query({
        query: GetSponsorshipsByCreatorDocument,
        variables: {
            first,
            skip,
            searchQuery,
            id: searchQuery.toLowerCase(),
            creator,
            orderBy,
            orderDirection,
            whereFilters,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return sponsorships;
}
export async function getAllOperators({ chainId, first, skip, orderBy = DEFAULT_OPERATOR_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, }) {
    const { data: { operators }, } = await getGraphClient(chainId).query({
        query: GetAllOperatorsDocument,
        variables: {
            first,
            skip,
            orderBy,
            orderDirection,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return operators;
}
export async function getOperatorsByDelegation({ chainId, first, skip, address, orderBy = DEFAULT_OPERATOR_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, }) {
    const { data: { operators }, } = await getGraphClient(chainId).query({
        query: GetOperatorsByDelegationDocument,
        variables: {
            first,
            skip,
            delegator: address,
            orderBy,
            orderDirection,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return operators;
}
export async function getOperatorsByDelegationAndId({ chainId, first, skip, address, operatorId, orderBy = DEFAULT_OPERATOR_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, }) {
    const { data: { operators }, } = await getGraphClient(chainId).query({
        query: GetOperatorsByDelegationAndIdDocument,
        variables: {
            first,
            skip,
            delegator: address,
            operatorId,
            orderBy,
            orderDirection,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return operators;
}
export async function getOperatorsByDelegationAndMetadata({ chainId, first, skip, address, searchQuery, orderBy = DEFAULT_OPERATOR_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, }) {
    const { data: { operators }, } = await getGraphClient(chainId).query({
        query: GetOperatorsByDelegationAndMetadataDocument,
        variables: {
            first,
            skip,
            delegator: address,
            searchQuery,
            orderBy,
            orderDirection,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return operators;
}
export async function searchOperatorsByMetadata({ chainId, first, skip, searchQuery, orderBy = DEFAULT_OPERATOR_ORDER_BY, orderDirection = DEFAULT_ORDER_DIRECTION, force = false, }) {
    const { data: { operators }, } = await getGraphClient(chainId).query({
        query: SearchOperatorsByMetadataDocument,
        variables: {
            first,
            skip,
            searchQuery,
            id: searchQuery?.toLowerCase() || '',
            orderBy,
            orderDirection,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return operators;
}
export async function getOperatorById(chainId, operatorId, { force = false, minBlockNumber = 0 } = {}) {
    try {
        const { data: { operator }, } = await getGraphClient(chainId).query({
            query: GetOperatorByIdDocument,
            variables: {
                operatorId,
                minBlockNumber,
            },
            fetchPolicy: force ? 'network-only' : void 0,
        });
        return operator || null;
    }
    catch (e) {
        prehandleBehindBlockError(e, minBlockNumber);
        throw e;
    }
}
export async function getParsedOperatorByOwnerAddress(chainId, address, { force = false, minBlockNumber = 0 } = {}) {
    let operator = null;
    try {
        const { data: { operators }, } = await getGraphClient(chainId).query({
            query: GetOperatorByOwnerAddressDocument,
            variables: {
                owner: address.toLowerCase(),
                minBlockNumber,
            },
            fetchPolicy: force ? 'network-only' : void 0,
        });
        operator = operators?.[0] || null;
    }
    catch (e) {
        prehandleBehindBlockError(e, minBlockNumber);
        throw e;
    }
    if (operator) {
        try {
            return parseOperator(operator, { chainId });
        }
        catch (e) {
            if (!(e instanceof z.ZodError)) {
                throw e;
            }
            console.warn('Failed to parse an operator', operator, e);
        }
    }
    return null;
}
export async function getParsedOperatorsByOwnerOrControllerAddress(chainId, address, { force = false, minBlockNumber = 0 } = {}) {
    let queryResult = [];
    try {
        const { data: { operators }, } = await getGraphClient(chainId).query({
            query: GetOperatorsByOwnerOrControllerAddressDocument,
            variables: {
                owner: address.toLowerCase(),
                minBlockNumber,
            },
            fetchPolicy: force ? 'network-only' : void 0,
        });
        queryResult = operators;
    }
    catch (e) {
        prehandleBehindBlockError(e, minBlockNumber);
        throw e;
    }
    const result = [];
    queryResult.map((operator) => {
        try {
            const parsedOperator = parseOperator(operator, { chainId });
            result.push(parsedOperator);
        }
        catch (e) {
            if (!(e instanceof z.ZodError)) {
                throw e;
            }
            console.warn('Failed to parse an operator', operator, e);
        }
    });
    return result;
}
export async function getBase64ForFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => void resolve(reader.result);
        reader.onerror = reject;
    });
}
/**
 * Fetches stream descriptions from the Graph.
 * @param streamId Stream ID
 * @returns A string
 */
export async function getStreamDescription(chainId, streamId, { force = false } = {}) {
    const { data: { stream }, } = await getGraphClient(chainId).query({
        query: GetStreamByIdDocument,
        variables: {
            streamId,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return stream ? getDescription(stream) : '';
}
/**
 * Gets a collection of parsed Operators.
 * @param getter Callback that "gets" raw Operator objects.
 * @param options.mapper A mapping function that translates `ParsedOperator` instances into
 * something different.
 * @param options.onParseError Callback triggered for *each* parser failure (see `OperatorParser`).
 * @param options.onBeforeComplete Callback triggered just before returning the result. It carries
 * a total number of found operators and the number of successfully parsed operators.
 */
export async function getParsedOperators(getter, options) {
    const { chainId, mapper, onParseError, onBeforeComplete } = options;
    const rawOperators = await getter();
    const operators = [];
    const preparsedCount = rawOperators.length;
    for (let i = 0; i < preparsedCount; i++) {
        const rawOperator = rawOperators[i];
        try {
            const operator = parseOperator(rawOperator, { chainId });
            operators.push(mapper ? mapper(operator) : operator);
        }
        catch (e) {
            onParseError
                ? onParseError(rawOperator, e)
                : console.warn('Failed to parse an operator', rawOperator, e);
        }
    }
    onBeforeComplete?.(preparsedCount, operators.length);
    return operators;
}
/**
 * Compute projected yearly earnings based on the current yield.
 * @param operator.valueWithoutEarnings Total value of the pool.
 * @param operator.stakes Collection of basic stake information (amount, spot apy, projected insolvency date).
 * @returns Number representing the APY factor (0.01 is 1%).
 */
export function getSpotApy({ valueWithoutEarnings, stakes }) {
    if (valueWithoutEarnings === 0n) {
        return 0;
    }
    const now = Date.now();
    const yearlyIncome = stakes.reduce((sum, { spotAPY, projectedInsolvencyAt, amountWei, isSponsorshipPaying }) => {
        if (projectedInsolvencyAt == null ||
            projectedInsolvencyAt * 1000 < now ||
            !isSponsorshipPaying) {
            /**
             * Skip expired stakes.
             */
            return sum;
        }
        return sum.plus(toBN(amountWei).multipliedBy(spotAPY));
    }, toBN(0));
    if (yearlyIncome.isEqualTo(0)) {
        return 0;
    }
    return yearlyIncome.dividedBy(toBN(valueWithoutEarnings)).toNumber();
}
/**
 * Calculates the amount delegated to given operator by given wallet.
 */
export function getDelegatedAmountForWallet(address, { delegations }) {
    const addr = address.toLowerCase();
    return (delegations.find(({ delegator }) => delegator.toLowerCase() === addr)?.amount ||
        0n);
}
/**
 * Sums amounts delegated to given operator by its owner.
 */
export function getSelfDelegatedAmount(operator) {
    return getDelegatedAmountForWallet(operator.owner, operator);
}
/**
 * Calculates wallet's delegation's ratio out of the total optionally
 * modded with `offset`.
 */
export function getDelegationFractionForWallet(address, operator, { offset = 0n } = {}) {
    const total = operator.valueWithoutEarnings + offset;
    if (total === 0n) {
        return toBN(0);
    }
    return toBN(getDelegatedAmountForWallet(address, operator)).dividedBy(toBN(total));
}
/**
 * Calculates the amount of DATA needed to payout undelegation queue in full.
 */
export function calculateUndelegationQueueSize(operator) {
    const lookup = {};
    // Sum up queue by addresses
    for (let i = 0; i < operator.queueEntries.length; i++) {
        const element = operator.queueEntries[i];
        if (lookup[element.delegator] == null) {
            lookup[element.delegator] = 0n;
        }
        lookup[element.delegator] = lookup[element.delegator] + element.amount;
    }
    // Go through addresses and make sure we cap to max delegation
    for (const address of Object.keys(lookup)) {
        lookup[address] = ((a, b) => (a < b ? a : b))(lookup[address], getDelegatedAmountForWallet(address, operator));
    }
    // Return total sum of all addresses
    return Object.values(lookup).reduce((sum, b) => sum + b, 0n);
}
/**
 * Calculates owner's own delegation's ratio out of the total optionally
 * modded with `offset`.
 */
export function getSelfDelegationFraction(operator, { offset = 0n } = {}) {
    return getDelegationFractionForWallet(operator.owner, operator, { offset });
}
/**
 * Turns `period` into a timestamp relative to `end` moment.
 */
export function getTimestampForChartPeriod(period, end) {
    const result = (() => {
        switch (period) {
            case ChartPeriod.SevenDays:
                return end.clone().subtract(7, 'days');
            case ChartPeriod.OneMonth:
                return end.clone().subtract(30, 'days');
            case ChartPeriod.ThreeMonths:
                return end.clone().subtract(90, 'days');
            case ChartPeriod.OneYear:
                return end.clone().subtract(365, 'days');
            case ChartPeriod.YearToDate:
                return end.clone().startOf('year');
            case ChartPeriod.All:
            default:
                return moment(0).utc();
        }
    })();
    return result;
}
function parseNetworkStats(stats) {
    try {
        return z
            .object({
            totalStake: z.string().transform((v) => toBigInt(v)),
            sponsorshipsCount: z.number(),
            operatorsCount: z.number(),
        })
            .parse(stats.networks[0]);
    }
    catch (e) {
        return undefined;
    }
}
export async function getNetworkStats(chainId) {
    const { data } = await getGraphClient(chainId).query({
        query: GetNetworkStatsDocument,
    });
    return parseNetworkStats(data);
}
/**
 * Fetches a collection of daily Operator buckets.
 * @param operatorId Operator ID
 * @param options.dateLowerThan End unix timestamp
 * @param options.dateGreaterEqualThan Start unix timestamp
 * @param options.batchSize Number of buckets to scout for at once
 * @param options.skip Number of buckets to skip
 * @returns Operator buckets
 */
export async function getOperatorDailyBuckets(chainId, operatorId, options) {
    const { dateLowerThan: date_lt, dateGreaterEqualThan: date_gte, batchSize: first = 999, skip, force = false, } = options;
    const { data } = await getGraphClient(chainId).query({
        query: GetOperatorDailyBucketsDocument,
        variables: {
            first,
            skip,
            where: {
                operator_: {
                    id: operatorId,
                },
                date_lt,
                date_gte,
            },
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return data.operatorDailyBuckets;
}
/**
 * Fetches a collection of daily Sponsorship buckets.
 * @param chainId Chain ID
 * @param sponsorshipId Sponsorship ID
 * @param options.dateLowerThan End unix timestamp
 * @param options.dateGreaterEqualThan Start unix timestamp
 * @param options.batchSize Number of buckets to scout for at once
 * @param options.skip Number of buckets to skip
 * @returns Sponsorship buckets
 */
export async function getSponsorshipDailyBuckets(chainId, sponsorshipId, options) {
    const { dateLowerThan: date_lt, dateGreaterEqualThan: date_gte, batchSize: first = 999, skip, force = false, } = options;
    const { data } = await getGraphClient(chainId).query({
        query: GetSponsorshipDailyBucketsDocument,
        variables: {
            first,
            skip,
            where: {
                sponsorship_: {
                    id: sponsorshipId,
                },
                date_lt,
                date_gte,
            },
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return data.sponsorshipDailyBuckets;
}
/**
 * Fetches a collection of daily Delegator buckets.
 * @param delegatorId Delegator ID
 * @param options.dateLowerThan End unix timestamp
 * @param options.dateGreaterEqualThan Start unix timestamp
 * @param options.batchSize Number of buckets to scout for at once
 * @param options.skip Number of buckets to skip
 * @returns Delegator buckets
 */
export const getDelegatorDailyBuckets = async (chainId, delegatorId, options) => {
    const { dateLowerThan: date_lt, dateGreaterEqualThan: date_gte, batchSize: first = 999, skip, force = false, } = options;
    const { data } = await getGraphClient(chainId).query({
        query: GetDelegatorDailyBucketsDocument,
        variables: {
            first,
            skip,
            where: {
                delegator: delegatorId,
                date_lt,
                date_gte,
            },
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return data.delegatorDailyBuckets;
};
let ensApolloClient;
/**
 * Fetches the ENS subgraph for domains associated with the given `wallet` address.
 */
export async function getENSDomainsForWallet(wallet, { force = false } = {}) {
    if (!wallet) {
        return [];
    }
    if (!ensApolloClient) {
        ensApolloClient = new ApolloClient({
            uri: process.env.ENS_GRAPH_SCHEMA_PATH ||
                'https://api.thegraph.com/subgraphs/name/ensdomains/ens',
            cache: new InMemoryCache(),
        });
    }
    const { data = { domains: [], wrappedDomains: [] } } = await ensApolloClient.query({
        query: GetEnsDomainsForAccountDocument,
        variables: {
            account: wallet.toLowerCase(),
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return [...data.domains, ...data.wrappedDomains]
        .map(({ name }) => (!!name && /\.eth$/.test(name) ? name : null))
        .sort()
        .filter(Boolean);
}
/**
 * Extracts description from a metadata-having Stream-alike
 */
export function getDescription(streamalike) {
    try {
        return z
            .string()
            .transform((v) => JSON.parse(v))
            .pipe(z.object({
            description: z
                .string()
                .optional()
                .transform((v) => v || ''),
        }))
            .parse(streamalike.metadata).description;
    }
    catch (e) {
        return '';
    }
}
