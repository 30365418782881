import { format } from 'date-fns';
import { GetSponsoringEventsDocument, } from '~/generated/gql/network';
import { getGraphClient } from '~/getters/getGraphClient';
import { toBigInt } from '~/utils/bn';
export const getSponsoringEvents = async (chainId, sponsorshipId, first, skip = 0, { force = false } = {}) => {
    const { data: { sponsoringEvents }, } = await getGraphClient(chainId).query({
        query: GetSponsoringEventsDocument,
        variables: {
            sponsorshipId,
            first,
            skip,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    return sponsoringEvents.map((event) => ({
        id: event.id,
        amount: toBigInt(event.amount),
        sponsor: event.sponsor,
        date: format(new Date(Number(event.date) * 1000), 'yyyy-MM-dd HH:mm:ss'),
    }));
};
