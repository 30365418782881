import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { z } from 'zod';
import { Button } from '~/components/Button';
import { Hint } from '~/components/Hint';
import Spinner from '~/components/Spinner';
import { PathnameSchema } from '~/parsers/StreamParser';
import SvgIcon from '~/shared/components/SvgIcon';
import Errors, { MarketplaceTheme } from '~/shared/components/Ui/Errors';
import Label from '~/shared/components/Ui/Label';
import Select from '~/shared/components/Ui/Select';
import Text from '~/shared/components/Ui/Text';
import useCopy from '~/shared/hooks/useCopy';
import { useWalletAccount } from '~/shared/stores/wallet';
import { PHONE } from '~/shared/utils/styled';
import { truncate } from '~/shared/utils/text';
import { StreamDraft } from '~/stores/streamDraft';
import { Route as R } from '~/utils/routes';
import useStreamOwnerOptionGroups, { ADD_ENS_DOMAIN_VALUE, } from './useStreamOwnerOptionGroups';
export const ENS_DOMAINS_URL = 'https://ens.domains';
export function ReadonlyStreamId({ streamId }) {
    const { copy, isCopied } = useCopy();
    return (React.createElement(StreamId, null,
        React.createElement(Pathname, null,
            React.createElement(Label, null, "Stream ID"),
            React.createElement(PathnameField, null,
                React.createElement(Text, { readOnly: true, value: streamId, disabled: true }))),
        React.createElement("div", null,
            React.createElement(Label, null, "\u200C"),
            React.createElement(Button, { kind: "secondary", onClick: () => void copy(streamId || '', {
                    toastMessage: 'Stream ID copied',
                }), type: "button" }, isCopied ? 'Copied!' : 'Copy'))));
}
export function EditableStreamId({ disabled = false }) {
    const { domain = '', pathname = '' } = StreamDraft.useEntity({ hot: true }) || {};
    const ownerGroups = useStreamOwnerOptionGroups(domain);
    const update = StreamDraft.useUpdateEntity();
    const [{ pathname: validationError }, setErrors] = useState({});
    const validate = StreamDraft.useValidateEntity((entity) => {
        z.object({
            pathname: PathnameSchema,
        }).parse(entity);
    });
    const owners = useMemo(() => {
        const result = [];
        if (ownerGroups) {
            ownerGroups.forEach(({ options }) => {
                result.push(...options);
            });
        }
        return result;
    }, [ownerGroups]);
    const isOwnersLoading = typeof ownerGroups === 'undefined';
    const account = useWalletAccount();
    useEffect(function setCurrentAccountAsDomain() {
        /**
         * We may wanna skip this step of delay it while we're doing
         * something to the stream, e.g. transacting or block-awaiting.
         */
        /**
         * We set the domain for both hot and cold copies of the entity
         * here so that *just* changing the account does not cause the state
         * to be dirty. All we care about is, after all, the pathname.
         */
        update((hot, cold) => {
            if (!hot.domain) {
                hot.domain = account || '';
            }
            cold.domain = hot.domain;
        });
    }, [update, account]);
    return (React.createElement(StreamId, null,
        React.createElement(Domain, null,
            React.createElement(Label, null,
                React.createElement(LabelInner, null, "Domain")),
            disabled || isOwnersLoading || !account ? (React.createElement(DisabledDomain, null, isOwnersLoading ? (React.createElement(React.Fragment, null,
                React.createElement("span", null, "Loading domains\u2026"),
                !disabled && React.createElement(Spinner, { color: "blue" }))) : (React.createElement("span", null, truncate(domain || account || 'No account'))))) : (React.createElement(Select, { options: ownerGroups, value: owners.find(({ value }) => value.toLowerCase() === (domain || account)), onChange: (option) => {
                    const { value = '' } = option || {};
                    if (value === ADD_ENS_DOMAIN_VALUE) {
                        window.open(ENS_DOMAINS_URL, '_blank', 'noopener noreferrer');
                        return;
                    }
                    /**
                     * We set the domain for both hot and cold copies of the entity
                     * here so that *just* changing the account does not cause the state
                     * to be dirty. All we care about is, after all, the pathname.
                     */
                    update((hot, cold) => {
                        hot.domain = value;
                        cold.domain = value;
                    });
                }, disabled: disabled, name: "domain" }))),
        React.createElement("div", null,
            React.createElement(Label, null,
                React.createElement(LabelInner, null)),
            React.createElement(Separator, null)),
        React.createElement(Pathname, null,
            React.createElement(Label, null,
                React.createElement(LabelInner, null,
                    React.createElement("span", null, "Path name"),
                    React.createElement(Hint, null,
                        React.createElement("p", null, "Stream paths can be single or multi-level."),
                        React.createElement("p", null, "Single"),
                        React.createElement("pre", null, "streamr.eth/coffeemachine"),
                        React.createElement("p", null, "Multi"),
                        React.createElement("pre", null, "streamr.eth/oracles/price"),
                        React.createElement("p", null,
                            "For more information, see the ",
                            React.createElement("a", { href: R.docs() }, "docs"),
                            ".")))),
            React.createElement(PathnameField, null,
                React.createElement(Text, { disabled: disabled, invalid: !!validationError, onChange: ({ target }) => {
                        update((draft) => {
                            draft.pathname = target.value;
                        });
                        setErrors(validate());
                    }, placeholder: "Enter a unique stream path name", value: pathname }),
                pathname && !disabled && (React.createElement(ClearButton, { type: "button", onClick: () => {
                        update((draft) => {
                            draft.pathname = '';
                        });
                        setErrors(validate());
                    } },
                    React.createElement(SvgIcon, { name: "clear" })))),
            !!validationError && (React.createElement(Errors, { overlap: true, theme: MarketplaceTheme }, validationError)))));
}
const Domain = styled.div.withConfig({ displayName: "Domain", componentId: "sc-csyaj3" }) `
    flex-grow: 1;

    @media ${PHONE} {
        max-width: 222px;
    }
`;
const SeparatorAttrs = {
    children: '/',
};
const Separator = styled.div.attrs(SeparatorAttrs).withConfig({ displayName: "Separator", componentId: "sc-f990fl" }) `
    line-height: 40px;
`;
const Pathname = styled.div.withConfig({ displayName: "Pathname", componentId: "sc-8d00mo" }) `
    flex-grow: 1;

    ${Label} {
        flex-grow: 1;
    }
`;
const PathnameField = styled.div.withConfig({ displayName: "PathnameField", componentId: "sc-19ieedp" }) `
    position: relative;
`;
const DisabledDomain = styled.div.withConfig({ displayName: "DisabledDomain", componentId: "sc-klnzdr" }) `
    align-items: center;
    background-color: #efefef;
    border-radius: 4px;
    border: 1px solid #efefef;
    color: #525252;
    display: flex;
    font-size: 0.875rem;
    height: 40px;
    padding: 0 1rem;
    width: 100%;

    > span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
const ClearButton = styled.button.withConfig({ displayName: "ClearButton", componentId: "sc-1xzgy39" }) `
    width: 40px;
    height: 40px;
    color: #989898;
    position: absolute;
    top: 0;
    line-height: 16px;
    right: 0;
    border: 0;
    background: none;
    outline: none;
    appearance: none;

    :focus {
        outline: none;
    }

    svg {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    :hover circle {
        fill: #525252;
        stroke: #525252;
    }
`;
const StreamId = styled.div.withConfig({ displayName: "StreamId", componentId: "sc-1ye568m" }) `
    display: flex;

    > * + * {
        margin-left: 16px;
    }
`;
const LabelInner = styled.div.withConfig({ displayName: "LabelInner", componentId: "sc-foc145" }) `
    align-items: center;
    display: flex;
    min-height: 24px;

    span {
        flex-grow: 1;
    }
`;
