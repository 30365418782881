import React, { useState } from 'react';
import styled from 'styled-components';
import { isAddress } from 'web3-validator';
import { address0 } from '~/consts';
import PermissionEditor from '~/pages/AbstractStreamEditPage/AccessControlSection/PermissionEditor';
import { Bits, setBits, unsetBits } from '~/parsers/StreamParser';
import UnstyledErrors, { MarketplaceTheme } from '~/shared/components/Ui/Errors';
import UnstyledLabel from '~/shared/components/Ui/Label';
import Text from '~/shared/components/Ui/Text';
import { COLORS, TABLET } from '~/shared/utils/styled';
import { RejectionReason, isMessagedObject } from '~/utils/exceptions';
import FormModal from './FormModal';
const Separator = styled.div.withConfig({ displayName: "Separator", componentId: "sc-1v9whs1" }) `
    border-bottom: 1px solid ${COLORS.Border};
    margin: 24px 0;

    @media ${TABLET} {
        border: 0;
        margin: 40px 0 0;
    }
`;
const Label = styled(UnstyledLabel).withConfig({ displayName: "Label", componentId: "sc-8x7cpa" }) `
    display: flex;
`;
const Errors = styled(UnstyledErrors).withConfig({ displayName: "Errors", componentId: "sc-1keixgb" }) `
    display: flex;
`;
export default function NewStreamPermissionsModal({ onReject, onResolve, onBeforeSubmit, ...props }) {
    const [permissionBits, setPermissionBits] = useState(0);
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const cancelLabel = address || permissionBits !== 0 ? 'Cancel' : undefined;
    return (React.createElement(FormModal, { ...props, title: "Add a new account", onReject: onReject, onBeforeAbort: (reason) => {
            return ((permissionBits === 0 && address === '') ||
                reason !== RejectionReason.Backdrop);
        }, onSubmit: () => {
            const account = address.toLowerCase();
            if (account === address0) {
                return void setError('Invalid address');
            }
            if (account.length === 0) {
                return void setError('Address required');
            }
            if (!isAddress(account)) {
                return void setError('Invalid address format');
            }
            const result = {
                account,
                bits: permissionBits,
            };
            try {
                onBeforeSubmit?.(result);
            }
            catch (e) {
                if (isMessagedObject(e)) {
                    return void setError(e.message);
                }
                return void setError('Something bad happened');
            }
            onResolve?.(result);
        }, canSubmit: !!address, submitLabel: "Add new account", cancelLabel: cancelLabel },
        React.createElement("div", null,
            React.createElement(Label, null, "Wallet address"),
            React.createElement(Text, { onCommit: (value) => {
                    setAddress(value);
                    setError('');
                }, placeholder: "0x\u2026" }),
            !!error && (React.createElement(Errors, { theme: MarketplaceTheme, overlap: true }, error))),
        React.createElement(Separator, null),
        React.createElement(PermissionEditor, { address: address, permissionBits: permissionBits, onChange: (permission, enabled) => {
                setPermissionBits((enabled ? setBits : unsetBits)(permissionBits, Bits[permission]));
            } })));
}
