import React from 'react';
import styled, { css } from 'styled-components';
import { Hint } from '~/components/Hint';
import { Tick } from '~/shared/components/Checkbox';
import Spinner from '~/components/Spinner';
import { StreamDraft } from '~/stores/streamDraft';
function UnstyledStorageNodeItem({ address, className, disabled = false, children, thirdPartyUrl, }) {
    const active = !!StreamDraft.useEntity({ hot: true })?.storage[address];
    const busy = false;
    const update = StreamDraft.useUpdateEntity();
    return (React.createElement(Root, { className: className, onClick: () => {
            update((hot, cold) => {
                if (cold.storage[address] == null) {
                    cold.storage[address] = false;
                }
                const enabled = !hot.storage[address];
                hot.storage[address] = enabled;
                if (!enabled && !Object.values(hot.storage).some(Boolean)) {
                    /**
                     * If there are no other enabled storage nodes
                     * revert the storage days.
                     */
                    hot.metadata.storageDays = cold.metadata.storageDays;
                }
            });
        }, type: "button", "$active": active, disabled: disabled },
        React.createElement("div", null, children),
        thirdPartyUrl && (React.createElement(Hint, null,
            React.createElement("p", null, "Third-party Storage Provider"),
            React.createElement("p", null,
                React.createElement("a", { href: thirdPartyUrl, target: '_blank', rel: "noreferrer" }, "Learn more")))),
        !disabled && (React.createElement(Tick, { "$checked": active, "data-test-hook": active ? 'Checkbox on' : 'Checkbox off' })),
        busy && React.createElement(Spinner, { color: "gray" })));
}
const StorageNodeItem = styled(UnstyledStorageNodeItem).withConfig({ displayName: "StorageNodeItem", componentId: "sc-1vfdii7" }) `
    align-items: center;
    appearance: none;
    background: #ffffff;
    border: 1px solid #efefef;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    font-size: 16px;
    height: 40px;
    line-height: normal;
    padding: 0 16px;
    text-align: left;
    transition: color 0.1s;
    width: 100%;

    :disabled,
    &[disabled] {
        background: #efefef;
        border-color: #efefef;
    }

    :focus {
        outline: 0;
    }

    & + & {
        margin-top: 16px;
    }

    > div:first-child {
        flex-grow: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ${Tick} {
        flex-shrink: 0;
        margin-left: 12px;
    }
`;
const Root = styled.button.withConfig({ displayName: "Root", componentId: "sc-19dvrd3" }) `
    color: #cdcdcd;
    cursor: pointer;

    :disabled,
    &[disabled] {
        cursor: not-allowed;
    }

    ${({ $active }) => !!$active &&
    css `
            color: #323232;
        `}

    ${({ $active }) => typeof $active === 'undefined' &&
    css `
            cursor: default !important;
        `}
`;
export default StorageNodeItem;
