import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useIsMounted from '~/shared/hooks/useIsMounted';
const ModalContext = React.createContext({
    closeModal: () => { },
    modals: {},
    openModal: async () => { },
});
function useModalContext(path) {
    const [modals, setModals] = useState({});
    const isMounted = useIsMounted();
    const openModal = useCallback((modalId, value = true) => {
        if (!isMounted()) {
            return Promise.resolve();
        }
        if (modals[modalId] && typeof modals[modalId].reject === 'function') {
            modals[modalId].reject();
        }
        return new Promise((resolve, reject) => {
            setModals(({ modals: prevModals }) => ({
                ...prevModals,
                [modalId]: {
                    resolve,
                    reject,
                    value,
                },
            }));
        });
    }, [modals, isMounted]);
    const closeModal = useCallback((modalId, value = undefined) => {
        if (!isMounted()) {
            return;
        }
        if (modals[modalId] && typeof modals[modalId].resolve === 'function') {
            modals[modalId].resolve(value);
        }
        setModals(({ modals: prevModals }) => {
            const nextModals = { ...prevModals };
            delete nextModals[modalId];
            return nextModals;
        });
    }, [modals, isMounted]);
    // close all modals on route change
    useEffect(() => () => {
        if (!isMounted()) {
            return;
        }
        setModals((prevModals) => {
            Object.keys(prevModals).forEach((modalId) => {
                if (prevModals[modalId].reject &&
                    typeof prevModals[modalId].reject === 'function') {
                    prevModals[modalId].reject();
                }
            });
            return {};
        });
    }, [isMounted, path]);
    return useMemo(() => ({
        modals,
        openModal,
        closeModal,
    }), [modals, openModal, closeModal]);
}
const ModalContextProvider = ({ children }) => {
    const { pathname } = useLocation();
    return (React.createElement(ModalContext.Provider, { value: useModalContext(pathname) }, children || null));
};
export { ModalContextProvider as Provider, ModalContext as Context };
