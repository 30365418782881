import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '~/components/Button';
import dataUnionImage2x from '~/marketplace/assets/product_dataunion@2x.png';
import CreateProjectModal from '~/marketplace/containers/CreateProjectModal';
import { getProjects } from '~/services/projects';
import useModal from '~/shared/hooks/useModal';
import { DESKTOP, LAPTOP, TABLET } from '~/shared/utils/styled';
import { useCurrentChainId } from '~/utils/chains';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-11g3wf1" }) `
    padding: 76px 32px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: unset;
    gap: 40px;

    @media (${LAPTOP}) {
        padding: 60px 116px;
        grid-template-rows: unset;
        grid-template-columns: auto auto;
    }

    @media (${DESKTOP}) {
        padding: 165px 125px;
        grid-template-rows: unset;
        grid-template-columns: auto auto;
    }
`;
const TextContainer = styled.div.withConfig({ displayName: "TextContainer", componentId: "sc-5cbs2h" }) `
    margin-left: 0;
    display: grid;
    grid-template-rows: auto auto;
    gap: 40px;
    align-content: center;

    @media (${DESKTOP}) {
        margin-left: 112px;
    }
`;
const Text = styled.div.withConfig({ displayName: "Text", componentId: "sc-1j2d794" }) `
    display: flex;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    justify-self: center;

    @media (${TABLET}) {
        font-size: 34px;
    }

    @media (${DESKTOP}) {
        text-align: left;
        justify-self: left;
    }
`;
const Image = styled.img.withConfig({ displayName: "Image", componentId: "sc-p2i0aj" }) `
    width: 261px;
    justify-self: center;

    @media (${TABLET}) {
        width: 364px;
    }
`;
const CreateButton = styled(Button).withConfig({ displayName: "CreateButton", componentId: "sc-uxui4r" }) `
    width: fit-content;
    justify-self: center;

    @media (${DESKTOP}) {
        justify-self: left;
    }
`;
export default function CreateProjectHint({ streamId }) {
    const [projects, setProjects] = useState([]);
    const { api: createProductModal } = useModal('marketplace.createProduct');
    const chainId = useCurrentChainId();
    useEffect(() => {
        /**
         * @todo Refactor using useQuery. #refactor
         */
        let mounted = true;
        const loadProjects = async () => {
            const result = await getProjects({
                chainId,
                first: 4,
                streamId,
            });
            if (mounted) {
                setProjects(result.projects);
            }
        };
        if (streamId) {
            loadProjects();
        }
        return () => {
            mounted = false;
        };
    }, [streamId, chainId]);
    // If the stream already has a project, show nothing
    if (projects.length > 0) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(Image, { src: dataUnionImage2x, alt: "Create a project" }),
        React.createElement(TextContainer, null,
            React.createElement(Text, null, "Make your stream more discoverable by creating a project."),
            React.createElement(CreateButton, { type: "button", onClick: () => createProductModal.open() }, "Create a project")),
        React.createElement(CreateProjectModal, null)));
}
