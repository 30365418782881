import React from 'react';
import ReactIdenticon from 'react-identicons';
import styled from 'styled-components';
const UnstyledIdenticon = ({ id, size, ...props }) => (React.createElement("div", { ...props },
    React.createElement("svg", { viewBox: "0 0 1 1", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement(ReactIdenticon, { string: id, size: size })));
const Identicon = styled(UnstyledIdenticon).withConfig({ displayName: "Identicon", componentId: "sc-1o6e4uh" }) `
    position: relative;

    .identicon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50% !important;
        height: 50% !important;
    }
`;
Identicon.defaultProps = {
    size: 80,
};
export default Identicon;
