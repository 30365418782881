import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect, { components, } from 'react-select';
import { z } from 'zod';
import { StyledCaretIcon, StyledCloseIcon, StyledDropdownIndicator, StyledOption, StyledWhiteDropdownOption, getClearIndicatorStyles, getControlStyles, getMenuListStyles, getMenuStyles, getOptionStyles, getPlaceholderStyles, getSingleValueStyles, getWhiteControlStyles, getWhiteMenuListStyles, getWhiteMenuStyles, getWhiteOptionStyles, getWhitePlaceholderStyles, } from './selectField2.styles';
const DropdownIndicator = (props) => {
    return (React.createElement(StyledDropdownIndicator, { ...props },
        React.createElement(StyledCaretIcon, { name: "caretDown", className: (props.selectProps.menuIsOpen ? 'rotated ' : '') +
                (props.selectProps.isDisabled ? 'disabled ' : '') +
                (props.whiteVariant ? 'whiteVariant' : '') })));
};
const ClearIndicator = (props) => {
    return (components.ClearIndicator && (React.createElement(components.ClearIndicator, { ...props },
        React.createElement(StyledCloseIcon, { name: 'close', className: props.selectProps.menuIsOpen ? 'menu-is-open' : '' }))));
};
export function SelectField2({ placeholder, options, value, onChange, disabled = false, isClearable = true, whiteVariant = false, noShrink = false, fullWidth = false, }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState();
    const defaultStyles = useMemo(() => ({
        control: (styles, props) => getControlStyles(styles, props.isFocused, isOpen, disabled, noShrink, fullWidth),
        placeholder: (styles) => getPlaceholderStyles(styles, isOpen, disabled),
        singleValue: (styles) => getSingleValueStyles(styles, isOpen, disabled, noShrink),
        menu: (styles) => getMenuStyles(styles),
        menuList: (styles) => getMenuListStyles(styles),
        menuPortal: (styles) => ({
            ...styles,
            zIndex: 1000,
        }),
        option: (styles, props) => getOptionStyles(styles, props.isSelected),
        clearIndicator: (styles) => getClearIndicatorStyles(styles, isOpen),
    }), [disabled, fullWidth, isOpen, noShrink]);
    const whiteVariantStyles = useMemo(() => ({
        placeholder: (styles) => getWhitePlaceholderStyles(styles),
        control: (styles, props) => getWhiteControlStyles(styles, props.isFocused, disabled, noShrink, fullWidth),
        menu: (styles) => getWhiteMenuStyles(styles),
        menuList: (styles) => getWhiteMenuListStyles(styles),
        menuPortal: (styles) => ({
            ...styles,
            zIndex: 1000,
        }),
        option: (styles, props) => getWhiteOptionStyles(styles, props.isSelected),
    }), [disabled, fullWidth, noShrink]);
    useEffect(() => {
        setSelected(value);
    }, [value]);
    const handleChange = (option) => {
        if (disabled) {
            return;
        }
        if (isClearable && option === null) {
            setSelected(undefined);
            onChange(null);
            return;
        }
        if (!isOption(option)) {
            return;
        }
        setSelected(option.value);
        onChange(option.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ReactSelect, { placeholder: placeholder, options: options, isSearchable: false, isClearable: isClearable, isDisabled: disabled, menuIsOpen: isOpen, onMenuOpen: () => {
                setIsOpen(true);
            }, onMenuClose: () => {
                setIsOpen(false);
            }, components: {
                DropdownIndicator: (props) => (React.createElement(DropdownIndicator, { whiteVariant: whiteVariant, ...props })),
                ClearIndicator,
                IndicatorSeparator: () => React.createElement(React.Fragment, null),
                Option: whiteVariant ? StyledWhiteDropdownOption : StyledOption,
            }, value: selected ? options.find((option) => option.value === selected) : null, onChange: handleChange, styles: whiteVariant ? whiteVariantStyles : defaultStyles, menuPortalTarget: document.body })));
}
function isOption(arg) {
    return z.object({ label: z.string(), value: z.string() }).safeParse(arg).success;
}
