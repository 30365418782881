const mapping = {
    137: 'MATIC',
    100: 'xDai',
    8995: 'DEV',
    8997: 'DEV',
    31337: 'DEV',
    80002: 'MATIC',
};
const getNativeTokenName = (chainId) => {
    return mapping[chainId] || 'Ether';
};
export default getNativeTokenName;
