import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
const PosterUrl = 'https://streamr.network/resources/social/marketplace.png';
export default function Helmet({ description = 'Discover, create and consume data streams on the Streamr Hub', title, otherTags, ...props }) {
    return (React.createElement(ReactHelmet, { ...props, title: [title, 'Streamr Hub'].filter(Boolean).join(' | ') },
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:title", content: title }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:image", content: PosterUrl }),
        React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
        React.createElement("meta", { name: "twitter:title", content: title }),
        React.createElement("meta", { name: "twitter:description", content: description }),
        React.createElement("meta", { name: "twitter:image", content: PosterUrl }),
        otherTags));
}
export function NetworkHelmet({ description = 'Operate your Streamr nodes. Create, join and delegate to stream Sponsorships.', otherTags = React.createElement("meta", { name: "robots", content: "noindex" }), ...props }) {
    return React.createElement(Helmet, { ...props, description: description, otherTags: otherTags });
}
