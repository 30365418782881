import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Alert as PrestyledAlert } from '~/components/Alert';
import { SponsorshipDecimals } from '~/components/Decimals';
import { SponsorshipPaymentTokenName } from '~/components/SponsorshipPaymentTokenName';
import { getSelfDelegatedAmount, getSelfDelegationFraction } from '~/getters';
import { useConfigValueFromChain, useMediaQuery } from '~/hooks';
import { useSponsorshipTokenInfo } from '~/hooks/sponsorships';
import FormModal, { FieldWrap, MaxButton, Prop, PropList, PropValue, Section, SectionHeadline, TextAppendix, TextInput, } from '~/modals/FormModal';
import { delegateToOperator } from '~/services/operators';
import Label from '~/shared/components/Ui/Label';
import { useWalletAccount } from '~/shared/stores/wallet';
import { humanize } from '~/shared/utils/time';
import { waitForIndexedBlock } from '~/utils';
import { toBN, toBigInt, toFloat } from '~/utils/bn';
import { RejectionReason, isRejectionReason, isTransactionRejection, } from '~/utils/exceptions';
export default function DelegateFundsModal({ balance, chainId, delegatedTotal, onResolve, operator, ...props }) {
    const walletAddress = useWalletAccount();
    const isOwner = walletAddress?.toLowerCase() === operator.owner.toLowerCase();
    const [submitLabel, title, amountLabel, totalLabel, subtitlePartial] = isOwner
        ? [
            'Fund',
            'Fund Operator',
            'Amount to stake',
            'Your stake',
            'you wish to stake on your Operator',
        ]
        : [
            'Delegate',
            'Delegate to Operator',
            'Amount to delegate',
            'Your delegation',
            'to delegate to the selected Operator',
        ];
    const minimumDelegationWei = useConfigValueFromChain('minimumDelegationWei') || 0n;
    const minimumSelfDelegationFraction = useConfigValueFromChain('minimumSelfDelegationFraction', (value) => toFloat(value, 18n)) || toBN(0);
    const minimumDelegationSeconds = useConfigValueFromChain('minimumDelegationSeconds', (value) => !isOwner ? Number(value) : 0) || 0;
    const [rawAmount, setRawAmount] = useState('');
    const { decimals = 18n } = useSponsorshipTokenInfo() || {};
    const value = toBigInt(rawAmount || 0, decimals);
    const insufficientFunds = value > balance;
    const selfDelegationFraction = useMemo(() => getSelfDelegationFraction(operator), [operator]);
    const nextSelfDelegationFraction = useMemo(() => getSelfDelegationFraction(operator, { offset: value }), [operator, value]);
    const tooLowCurrentSelfDelegation = !isOwner &&
        selfDelegationFraction.isLessThanOrEqualTo(minimumSelfDelegationFraction);
    const tooLowNextSelfDelegation = !isOwner &&
        nextSelfDelegationFraction.isLessThanOrEqualTo(minimumSelfDelegationFraction);
    const tooLowOwnerSelfDelegation = tooLowCurrentSelfDelegation || tooLowNextSelfDelegation;
    const maxNextAmount = useMemo(() => {
        if (minimumSelfDelegationFraction.isEqualTo(0)) {
            return 0n;
        }
        const operatorSelfStake = getSelfDelegatedAmount(operator);
        return (toBigInt(toBN(operatorSelfStake).dividedBy(minimumSelfDelegationFraction)) -
            delegatedTotal -
            operatorSelfStake);
    }, [minimumSelfDelegationFraction, operator, delegatedTotal]);
    const canSubmit = value > 0n &&
        value >= minimumDelegationWei &&
        !insufficientFunds &&
        !tooLowOwnerSelfDelegation;
    const [busy, setBusy] = useState(false);
    const limitedSpace = useMediaQuery('screen and (max-width: 460px)');
    return (React.createElement(FormModal, { ...props, title: title, canSubmit: canSubmit && !busy, submitting: busy, submitLabel: submitLabel, onBeforeAbort: (reason) => !busy && (rawAmount === '' || reason !== RejectionReason.Backdrop), onSubmit: async () => {
            if (!canSubmit) {
                return;
            }
            setBusy(true);
            try {
                await delegateToOperator(chainId, operator.id, value, {
                    onReceipt: ({ blockNumber }) => waitForIndexedBlock(chainId, blockNumber),
                });
                onResolve?.();
            }
            catch (e) {
                if (isRejectionReason(e)) {
                    return;
                }
                if (isTransactionRejection(e)) {
                    return;
                }
                throw e;
            }
            finally {
                setBusy(false);
            }
        } },
        React.createElement(SectionHeadline, null,
            "Please enter the amount of ",
            React.createElement(SponsorshipPaymentTokenName, null),
            " tokens",
            ' ',
            subtitlePartial),
        React.createElement(Section, null,
            React.createElement(Label, null, amountLabel),
            React.createElement(FieldWrap, { "$invalid": insufficientFunds },
                React.createElement(TextInput, { name: "amount", autoFocus: true, onChange: ({ target }) => {
                        setRawAmount(target.value);
                    }, placeholder: "0", readOnly: busy, type: "number", min: 0, step: "any", value: rawAmount }),
                React.createElement(MaxButton, { onClick: () => {
                        setRawAmount(toFloat(balance, decimals).toString());
                    } }),
                React.createElement(TextAppendix, null,
                    React.createElement(SponsorshipPaymentTokenName, null))),
            React.createElement(PropList, null,
                React.createElement("li", null,
                    React.createElement(Prop, { "$invalid": insufficientFunds }, insufficientFunds ? (React.createElement(React.Fragment, null, "Not enough balance in your wallet")) : (React.createElement(React.Fragment, null, "Your wallet balance"))),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: balance, tooltip: limitedSpace }))),
                React.createElement("li", null,
                    React.createElement(Prop, null, "Operator"),
                    React.createElement(PropValue, null, operator.id)),
                React.createElement("li", null,
                    React.createElement(Prop, null, totalLabel),
                    React.createElement(PropValue, null,
                        React.createElement(SponsorshipDecimals, { abbr: limitedSpace, amount: delegatedTotal, tooltip: limitedSpace }))))),
        React.createElement(React.Fragment, null, tooLowCurrentSelfDelegation ? (React.createElement(Alert, { type: "error", title: "Unable to delegate" }, "This operator can not accept any further delegations at the moment, because the operator's own share of funds is below the required limit.")) : (React.createElement(React.Fragment, null, tooLowNextSelfDelegation && (React.createElement(Alert, { type: "error", title: "Amount too high" },
            "This operator can currently only accept less than",
            ' ',
            React.createElement("strong", null,
                React.createElement(SponsorshipDecimals, { amount: maxNextAmount })),
            ' ',
            "in further delegations, because operators must stay above a certain proportion of their own funds vs. delegations."))))),
        React.createElement(React.Fragment, null, value > 0n && value < minimumDelegationWei && (React.createElement(Alert, { type: "notice", title: React.createElement(React.Fragment, null,
                "Minimum delegation is",
                ' ',
                React.createElement(SponsorshipDecimals, { amount: minimumDelegationWei })) }))),
        React.createElement(React.Fragment, null, operator.contractVersion > 0 && minimumDelegationSeconds > 0 && (React.createElement(Alert, { type: "notice", title: `You will need to stay delegated for at least ${humanize(minimumDelegationSeconds)}.` }))),
        React.createElement(React.Fragment, null, operator.contractVersion < 3 && !isOwner && (React.createElement(Alert, { type: "error", title: "Slashing risk" }, "This Operator is running an older version of the Operator smart contract where Delegators are subject to slashing risk if the Operator is voted out of a Sponsorship. In newer Operator versions, Delegators are more protected from slashing.")))));
}
const Alert = styled(PrestyledAlert).withConfig({ displayName: "Alert", componentId: "sc-1hcu50q" }) `
    margin-top: 10px;
`;
