import React from 'react';
import styled from 'styled-components';
const Value = styled.div.withConfig({ displayName: "Value", componentId: "sc-1ox0qbq" }) `
    position: absolute;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
`;
const UnstyledInitials = ({ children, ...props }) => (React.createElement("div", { ...props },
    React.createElement("svg", { viewBox: "0 0 1 1", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement(Value, null, children)));
const Initials = styled(UnstyledInitials).withConfig({ displayName: "Initials", componentId: "sc-v8cmpc" }) `
    background-color: #97bac0;
    color: #ffffff;
    font-family: var(--mono);
    position: relative;
`;
export default Initials;
