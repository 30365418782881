import axios from 'axios';
import merge from 'lodash/merge';
import RequestError from '~/shared/errors/RequestError';
export default async function request({ url, options, method = 'get', data = null, }) {
    const defaultOptions = {
        headers: {},
    };
    if (data !== null) {
        defaultOptions.headers = {
            ...defaultOptions.headers,
            'Content-Type': 'application/json',
        };
    }
    const requestOptions = merge(defaultOptions, options);
    try {
        return (await axios.request({ ...requestOptions, url, method, data })).data;
    }
    catch (e) {
        throw new RequestError(e);
    }
}
